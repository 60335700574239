import { gql, useMutation } from '@apollo/client';
import { useEffect } from 'react';

import { parseNumber } from 'lib/formatters';
import { useData } from './useDonationFormData';
import { useDonationFormWidget } from './useDonationFormWidget';

const MUTATION = gql`
  mutation DonationFormFees($campaignId: String!, $amount: String!) {
    calculateDonationFees(campaignId: $campaignId, amount: $amount)
  }
`;

const useDonationFormAmounts = () => {
  const { amount, coverFee } = useData();
  const { campaign } = useDonationFormWidget();

  const [calculateFees, calculateResult] = useMutation(MUTATION, {
    variables: { campaignId: campaign?.id },
  });

  useEffect(() => {
    if (!amount) return;
    calculateFees({ variables: { amount: amount.toString() } });
  }, [amount, calculateFees]);

  return {
    donation: amount,
    fees: calculateResult.data?.calculateDonationFees ?? 0,
    get total() {
      if (!coverFee) return amount;
      return parseNumber(amount) + parseNumber(this.fees);
    },
  };
};

export default useDonationFormAmounts;
