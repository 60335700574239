import { motion } from 'framer-motion';

import Tiles from './Tiles';

const SearchSection = ({ children }: PropsWithNonEmptyChildren) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    className="p-4 border-t first:border-t-0 border-gray-400"
  >
    <Tiles columns={1} spacing="xs">
      {children}
    </Tiles>
  </motion.div>
);

export default SearchSection;
