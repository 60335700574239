import PropTypes from 'prop-types';
import { useState, useMemo, useCallback, useEffect, forwardRef } from 'react';
import { createEditor, Transforms, Node } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import { withHistory } from 'slate-history';
import isEqual from 'lodash/isEqual';
import isHotkey from 'is-hotkey';
import uniq from 'lodash/uniq';

import cx from 'lib/cx';
import Editor, { withCustomSlateEditor } from 'lib/CustomSlateEditor';
import FormTextEditorElement from './FormTextEditorElement';
import FormTextEditorMark from './FormTextEditorMark';
import FormTextEditorToolbar from './FormTextEditorToolbar';
import style from './FormInput.module.css';
import editorStyle from './FormTextEditor.module.css';

const DEFAULT_VALUE = [{ type: 'paragraph', children: [{ text: '' }] }];

const PRESETS = {
  none: [],
  inline: ['bold', 'italic', 'underline'],
  basic: ['bold', 'italic', 'underline', 'lists', 'link', 'quote', 'divider'],
  advanced: ['titles', 'bold', 'italic', 'underline', 'lists', 'link', 'button', 'divider'],
};

const FormTextEditor = forwardRef(
  (
    {
      value,
      onChange,
      status,
      disabled,
      readOnly,
      preset,
      allow,
      wrapperClassName,
      className,
      ...props
    },
    ref
  ) => {
    // When Slate moved away from being a controlled input, it introduced a
    // issue with the editor not updating from external value changes. This is a
    // workaround for that issue.
    // See: https://github.com/ianstormtaylor/slate/issues/4612
    const [key, setKey] = useState(0);

    const safeValue = useMemo(() => value || DEFAULT_VALUE, [value]);
    const [editor] = useState(() => withCustomSlateEditor(withReact(withHistory(createEditor()))));

    const capabilities = useMemo(() => uniq([...PRESETS[preset], ...allow]), [preset, allow]);

    const state = useMemo(() => {
      if (disabled) return 'disabled';
      if (readOnly) return 'readonly';
      return status;
    }, [disabled, readOnly, status]);

    const handleChange = useCallback(
      (newValue) => {
        const isAstChange = editor.operations.some(({ type }) => type !== 'set_selection');
        if (isAstChange) onChange(newValue);
      },
      [editor, onChange]
    );

    const hotkeys = useMemo(() => {
      const list = {
        'shift+return': () => editor.insertText('\n'),
        'mod+z': () => Editor.undo(editor),
        'shift+mod+z': () => Editor.redo(editor),
      };

      if (capabilities.includes('bold')) {
        list['mod+b'] = () => Editor.toggleMark(editor, 'bold');
      }

      if (capabilities.includes('italic')) {
        list['mod+i'] = () => Editor.toggleMark(editor, 'italic');
      }

      if (capabilities.includes('underline')) {
        list['mod+u'] = () => Editor.toggleMark(editor, 'underline');
      }

      return list;
    }, [editor, capabilities]);

    // Handle external changes to the editor value
    useEffect(() => {
      if (isEqual(editor.children, safeValue)) return;
      Editor.deselect(editor);
      editor.children = safeValue;
      setKey((x) => x + 1);
    }, [editor, safeValue]);

    return (
      <Slate editor={editor} initialValue={safeValue} onChange={handleChange} key={key}>
        <div
          className={cx(style.base, style[state], wrapperClassName, 'overflow-hidden')}
          ref={ref}
        >
          {capabilities.length > 0 && (
            <div className="sticky top-0 inset-x-0 z-10 bg-white border-b border-gray-400">
              <FormTextEditorToolbar capabilities={capabilities} />
            </div>
          )}
          <Editable
            renderElement={FormTextEditorElement}
            renderLeaf={FormTextEditorMark}
            className={cx('p-4 overflow-y-auto h-56 rich-text', editorStyle.content, className)}
            onKeyDown={(event) => {
              // Hotkeys
              // eslint-disable-next-line no-restricted-syntax
              for (const [hotkey, fn] of Object.entries(hotkeys)) {
                if (isHotkey(hotkey, event)) {
                  event.preventDefault();
                  fn();
                  return;
                }
              }

              // Reset node if backing out of non-paragraph at start position
              if (
                isHotkey('backspace', event) &&
                editor.selection.focus.path.every((x) => x === 0) &&
                editor.selection.focus.offset === 0 &&
                editor.children[0].type !== 'paragraph'
              ) {
                event.preventDefault();

                if (Editor.isElementActive(editor, 'list-item')) {
                  Transforms.unwrapNodes(editor, {
                    match: (n) => n.type?.endsWith('-list'),
                    split: true,
                  });
                }

                Transforms.liftNodes(editor, { match: (n, p) => p.length > 2, mode: 'all' });
                Transforms.setNodes(editor, { type: 'paragraph' });
                Transforms.unsetNodes(editor, 'url');
                return;
              }

              // Special return handling
              if (isHotkey(['enter', 'return'], event)) {
                const [currentNode] = Editor.node(editor, editor.selection);
                const currentNodeIsEmpty = Node.string(currentNode) === '';

                // From a button...
                if (Editor.isElementActive(editor, 'button')) {
                  event.preventDefault();
                  if (currentNodeIsEmpty) {
                    Transforms.setNodes(editor, { type: 'paragraph' });
                    Transforms.unsetNodes(editor, 'url');
                  } else {
                    Transforms.insertNodes(editor, { type: 'paragraph', children: [{ text: '' }] });
                  }
                }

                // From a blank list item...
                if (Editor.isElementActive(editor, 'list-item') && currentNodeIsEmpty) {
                  event.preventDefault();
                  Editor.toggleList(editor);
                }

                // From a blank line in a block-quote...
                if (Editor.isElementActive(editor, 'block-quote') && currentNodeIsEmpty) {
                  event.preventDefault();
                  Editor.toggleBlockQuote(editor);
                }
              }
            }}
            spellCheck
            {...props}
            // Placeholders never get removed on Pixel 3 (and potentially other)
            // devices. Until we can figure the underlying cause, we'll just omit
            // placeholders from this field type entirely.
            placeholder={null}
          />
        </div>
      </Slate>
    );
  }
);

FormTextEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      children: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ),
  status: PropTypes.oneOf(['default', 'error', 'warning', 'success', 'info']),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  preset: PropTypes.oneOf(Object.keys(PRESETS)),
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  allow: PropTypes.arrayOf(
    PropTypes.oneOf([
      'titles',
      'bold',
      'italic',
      'underline',
      'lists',
      'quote',
      'link',
      'button',
      'divider',
    ])
  ),
};

FormTextEditor.defaultProps = {
  value: null,
  status: 'default',
  disabled: false,
  readOnly: false,
  preset: 'basic',
  wrapperClassName: '',
  className: '',
  allow: [],
};

export default FormTextEditor;
