import { ElementType } from 'react';
import { Image } from 'cloudinary-react';
import cx from 'classnames';

type AvatarProps<C extends ElementType> = PolymorphicComponentProp<
  C,
  {
    image?: string;
    initials?: string;
    className?: string;
    themed?: boolean;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  }
>;

const Avatar = <C extends ElementType = 'div'>({
  image = null,
  initials = null,
  className = '',
  themed = false,
  size = 'sm',
  as,
}: AvatarProps<C>) => {
  const Component = as || 'div';
  return (
    <Component
      className={cx(
        'rounded-full',
        {
          'w-8 text-sm': size === 'xs',
          'w-10 text-sm': size === 'sm',
          'w-12 text-base': size === 'md',
          'w-16 text-base': size === 'lg',
          'w-24 text-lg': size === 'xl',
          'w-32 text-xl': size === '2xl',
        },
        className
      )}
    >
      <div className="relative" style={{ paddingBottom: '100%' }}>
        <div
          className={cx(
            'absolute inset-0 rounded-full flex justify-center items-center overflow-hidden font-medium border border-gray-300',
            {
              'bg-gray-300 text-gray-700': !image && !themed,
              'bg-theme-primary': !image && themed,
              'bg-white': image,
            }
          )}
        >
          {image ? (
            <Image publicId={image} alt="" transformation="avatar" fetchFormat="auto" />
          ) : (
            <span className={cx({ 'font-medium text-white': themed })}>{initials}</span>
          )}
        </div>
      </div>
    </Component>
  );
};

export default Avatar;
