import { AnimatePresence, motion } from 'framer-motion';

import { useDownloads, clearDownload } from 'context/Downloads';
import DownloadListItem from './DownloadListItem';

const ToastList = () => {
  const downloads = useDownloads();

  return (
    <div className="pointer-events-none fixed z-50 inset-0 flex flex-col items-center gap-2 p-4 md:p-6">
      <AnimatePresence>
        {downloads.map((download) => (
          <motion.div
            key={download.href}
            initial={{ opacity: 0, x: 10 }}
            animate={{ opacity: 1, x: 0, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            className="pointer-events-auto"
            layout
          >
            <DownloadListItem
              url={download.href}
              fileName={download.fileName}
              onComplete={() => clearDownload(download.href)}
            />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

ToastList.propTypes = {};

ToastList.defaultProps = {};

export default ToastList;
