import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import { IconType } from 'lib/prop-types';

const FormTextEditorToolbarButton = ({ icon, isActive, onClick }) => (
  <button
    type="button"
    onMouseDown={(e) => {
      e.preventDefault();
      onClick();
    }}
    className={cx(
      'text-sm w-6 h-6 flex justify-center items-center rounded-md hover:opacity-50 transition-opacity duration-200',
      {
        'text-gray-700': !isActive,
        'bg-gray-300 text-gray-800': isActive,
      }
    )}
  >
    <FontAwesomeIcon icon={icon} size="1x" />
  </button>
);

FormTextEditorToolbarButton.propTypes = {
  icon: IconType.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
};

FormTextEditorToolbarButton.defaultProps = {
  isActive: false,
};

export default FormTextEditorToolbarButton;
