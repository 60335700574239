import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import Link from './Link';
import { ElementType, ReactNode } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type ContextMenuLinkProps<C extends ElementType> = PolymorphicComponentProp<
  C,
  {
    as?: C;
    label?: ReactNode;
    icon?: IconProp;
    isActive?: boolean;
    theme?: 'bordered' | 'rounded';
    className?: string;
  }
>;

const ContextMenuLink = <C extends ElementType = typeof Link>({
  as,
  label = null,
  icon = null,
  isActive = false,
  className = '',
  theme = null,
  ...props
}: ContextMenuLinkProps<C>) => {
  const Component = as || Link;
  return (
    <Component
      className={cx(
        'text-left text-lg sm:text-sm flex items-center justify-between sm:flex-row-reverse sm:justify-start w-full py-3 sm:py-2 transition-colors duration-200 text-gray-800',
        {
          'px-6 border-t border-gray-300 sm:border-t-0': theme === 'bordered',
          'px-6 rounded-md': theme === 'rounded',
          'bg-gray-300': isActive,
          'hover:bg-gray-300': !isActive,
        },
        className
      )}
      {...props}
    >
      {!!label && <span className="grow ml-1">{label}</span>}
      {!!icon && (
        <span className={cx('shrink-0 text-xl', { 'pl-3 sm:pl-0 sm:pr-3': !!label })}>
          <FontAwesomeIcon icon={icon} size="1x" fixedWidth />
        </span>
      )}
    </Component>
  );
};

export default ContextMenuLink;
