import { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Tiles from 'components/common/Tiles';
import style from './FormInput.module.css';

const FormRadioGroup = forwardRef(
  (
    {
      name,
      options,
      value,
      readOnly,
      disabled,
      status,
      onChange,
      columns,
      spacing,
      hideBorder,
      isRequired,
    },
    ref
  ) => {
    const state = useMemo(() => {
      if (disabled) return 'disabled';
      if (readOnly) return 'readonly';
      return status;
    }, [disabled, readOnly, status]);

    const canInteract = !['disabled', 'readonly'].includes(state);

    return (
      <Tiles columns={columns} spacing={spacing} ref={ref}>
        {options.map((option) => {
          const isChecked = option.value === value;
          const updateValue = !isRequired && isChecked ? '' : option.value;

          return (
            <label
              key={option.value}
              className={cx(
                'flex items-center py-2 px-3',
                { 'cursor-pointer': canInteract, 'border-none': hideBorder },
                style.base,
                canInteract && isChecked && !hideBorder ? style.success : style[state]
              )}
            >
              <input
                name={name}
                id={name}
                type={isRequired ? 'radio' : 'checkbox'}
                className="hidden"
                value={value}
                onChange={() => onChange(updateValue)}
              />
              <span
                className={cx(
                  'block h-4 w-4 rounded-full shrink-0 transition-all duration-200 mr-3',
                  {
                    'border border-gray-500': !isChecked,
                    'border-5 border-green-500': isChecked,
                  }
                )}
              />
              <p>{option.label}</p>
            </label>
          );
        })}
      </Tiles>
    );
  }
);

FormRadioGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  status: PropTypes.oneOf(['default', 'error', 'warning', 'success', 'info']),
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  columns: PropTypes.number,
  spacing: PropTypes.oneOf(['none', 'xs', 'sm', 'md', 'lg']),
  hideBorder: PropTypes.bool,
  isRequired: PropTypes.bool,
};

FormRadioGroup.defaultProps = {
  name: null,
  value: '',
  status: 'default',
  readOnly: false,
  disabled: false,
  columns: 1,
  spacing: 'xs',
  hideBorder: false,
  isRequired: false,
};

export default FormRadioGroup;
