import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { gql, useQuery } from '@apollo/client';
import { motion } from 'framer-motion';
import {
  faPencil,
  faUserCog,
  faUsersCog,
  faChartMixed,
  faSignOut,
} from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import { useAuth } from 'context/Auth';
import { CampaignPageProvider, CampaignPageConsumer } from 'context/CampaignPage';
import useIsClient from 'hooks/useIsClient';
import useCustomTheme from 'hooks/useCustomTheme';
import useScreen from 'hooks/useScreen';
import NotFoundPage from 'pages/404';
import ShareModal from 'components/common/ShareModal';
import FundraiserAdminInviteModal from 'components/fundraiser-admin/FundraiserAdminInviteModal';
import CampaignPageFundraiserNav from 'components/campaign-page/CampaignPageFundraiserNav';
import CampaignPageFooter from 'components/campaign-page/CampaignPageFooter';
import CampaignPageNavbar from 'components/campaign-page/CampaignPageNavbar';

const FRAGMENTS = {
  campaign: gql`
    fragment CampaignLayoutCampaignFields on Campaign {
      id
      accentColor
      fundraisingType
      myPermissions
      primaryColor
      activeFeatures {
        event
      }
    }
  `,
};

const FROM_CAMPAIGN = gql`
  ${FRAGMENTS.campaign}

  query GetCampaignLayoutFromCampaign($id: String!) {
    findCampaigns(id: $id) {
      # Adding the following here instead of the fragment as they do extra work and we only need them for campaign pages
      supportedFeatures {
        join
      }
      fundraiserCount
      ...CampaignLayoutCampaignFields
    }
  }
`;

const FROM_TEAM = gql`
  ${FRAGMENTS.campaign}

  query getCampaignLayoutFromTeam($id: String!) {
    findTeams(id: $id) {
      id
      myPermissions

      campaign {
        ...CampaignLayoutCampaignFields
      }
    }
  }
`;

const FROM_FUNDRAISER = gql`
  ${FRAGMENTS.campaign}

  query getCampaignLayoutFromFundraiser($id: String!) {
    findFundraisers(id: $id) {
      id
      userId
      myPermissions

      campaign {
        ...CampaignLayoutCampaignFields
      }
    }
  }
`;

const CampaignPageLayout = ({ id, idType, enableCustomTheme, className, children }) => {
  const router = useRouter();
  const { isLoggedIn, user } = useAuth();
  const isClient = useIsClient();
  const screen = useScreen();

  const [isSharing, setIsSharing] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const { data, loading } = useQuery(
    {
      campaign: FROM_CAMPAIGN,
      team: FROM_TEAM,
      fundraiser: FROM_FUNDRAISER,
    }[idType],
    { variables: { id } }
  );

  const fundraiser = useMemo(() => {
    if (idType === 'team') return data?.findTeams[0];
    if (idType === 'fundraiser') return data?.findFundraisers[0];
    return null;
  }, [data, idType]);

  const campaign = useMemo(
    () => (idType === 'campaign' ? data?.findCampaigns[0] : fundraiser?.campaign),
    [idType, fundraiser, data]
  );

  const theme = useCustomTheme({
    primary: campaign?.primaryColor,
    secondary: campaign?.accentColor,
    enabled: enableCustomTheme,
  });

  const isCampaignManager = useMemo(() => {
    if (!isLoggedIn || !campaign) return false;
    return campaign.myPermissions.includes('manageCampaign');
  }, [isLoggedIn, campaign]);

  const isFundraiserManager = useMemo(() => {
    if (!isLoggedIn || !fundraiser) return false;
    const role = idType === 'team' ? 'manageTeam' : 'manageFundraiser';
    return fundraiser.myPermissions.includes(role);
  }, [isLoggedIn, fundraiser, idType]);

  const userIsFundraiser = useMemo(() => {
    if (!isLoggedIn || !fundraiser) return false;
    return idType === 'fundraiser' && fundraiser?.userId === user?.id;
  }, [isLoggedIn, fundraiser, user, idType]);

  const showSearch = useMemo(() => {
    if (
      campaign &&
      idType === 'campaign' &&
      !campaign.supportedFeatures.join &&
      campaign.fundraiserCount === 0
    ) {
      return false;
    }
    return true;
  }, [idType, campaign]);

  const actionButton = useMemo(() => {
    const isTeam = idType === 'team';

    if (!fundraiser && isCampaignManager) {
      return {
        icon: faChartMixed,
        label: 'Dashboard',
        href: `/manage/c/${campaign.id}`,
      };
    }

    if (fundraiser && isCampaignManager) {
      return {
        icon: isTeam ? faUsersCog : faUserCog,
        label: `Manage`,
        href: `/manage/c/${campaign.id}/${isTeam ? 't' : 'participant'}/${fundraiser.id}`,
      };
    }

    if (fundraiser && isFundraiserManager) {
      const isTeamPage =
        isTeam &&
        (router.pathname === '/t/[id]' ||
          router.pathname === '/[slug]/teams/[teamSlug]' ||
          router.pathname === '/c/[id]/teams/[teamSlug]');
      const isOnFundraiserPage =
        !isTeam &&
        (router.pathname === '/f/[id]' ||
          router.pathname === '/[slug]/[fundraiserSlug]' ||
          router.pathname === '/c/[id]/[fundraiserSlug]');
      return screen.lg || (!isTeamPage && !isOnFundraiserPage)
        ? null
        : {
            icon: faPencil,
            label: 'Edit',
            as: 'button',
            type: 'button',
            onClick: () => setIsEditing(true),
          };
    }

    return {
      as: 'button',
      type: 'button',
      onClick: () => setIsSharing(true),
      icon: faSignOut,
      iconProps: { rotation: 270 },
      label: 'Share',
    };
  }, [
    idType,
    isCampaignManager,
    isFundraiserManager,
    fundraiser,
    campaign,
    setIsSharing,
    setIsEditing,
    screen.lg,
    router,
  ]);

  if (!loading && !campaign) return <NotFoundPage />;
  if (!campaign) return null;

  return (
    <CampaignPageProvider
      pageType={idType}
      campaignId={campaign.id}
      teamId={idType === 'team' ? id : null}
      fundraiserId={idType === 'fundraiser' ? id : null}
      theme={theme}
      campaignFeatures={campaign.activeFeatures}
      isSharing={isSharing}
      setIsSharing={setIsSharing}
      isEditing={isEditing}
      setIsEditing={setIsEditing}
      isFundraiserManager={isFundraiserManager}
      userIsFundraiser={userIsFundraiser}
    >
      <CampaignPageConsumer>
        {({ isInviting, setIsInviting }) => (
          <motion.div
            initial={false}
            animate={{ opacity: isClient ? 1 : 0 }}
            className="bg-gray-100 min-h-screen flex flex-col"
          >
            <ShareModal show={isSharing} onHide={() => setIsSharing(false)} />

            {userIsFundraiser && isInviting && (
              <FundraiserAdminInviteModal onHide={() => setIsInviting(false)} show />
            )}

            <CampaignPageNavbar
              campaignId={campaign.id}
              showSearch={showSearch}
              actionButton={actionButton}
            />

            <main
              className={cx('shrink-0 grow overflow-x-hidden', className)}
              style={{ minHeight: 'calc(10m0vh - 5rem)' }}
            >
              {children}
            </main>

            <CampaignPageFooter campaignId={campaign.id} />

            {!screen.lg && <CampaignPageFundraiserNav campaignId={campaign.id} />}
          </motion.div>
        )}
      </CampaignPageConsumer>
    </CampaignPageProvider>
  );
};

CampaignPageLayout.propTypes = {
  id: PropTypes.string.isRequired,
  idType: PropTypes.oneOf(['campaign', 'team', 'fundraiser']).isRequired,
  enableCustomTheme: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

CampaignPageLayout.defaultProps = {
  enableCustomTheme: false,
  className: '',
};

export default CampaignPageLayout;
