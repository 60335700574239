import { ComponentPropsWithoutRef } from 'react';

type FormLabelProps = {
  isRequired?: boolean;
} & ComponentPropsWithoutRef<'label'>;

const FormLabel = ({
  isRequired = false,
  children = null,
  className = '',
  ...props
}: FormLabelProps) => (
  <label className={className} {...props}>
    {children} {isRequired && <span className="text-red-600">*</span>}
  </label>
);

FormLabel.defaultProps = {};

export default FormLabel;
