import { useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faExclamationTriangle,
  faCheck,
  faInfoCircle,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import useBreakpoint from 'hooks/useBreakpoint';

const icons = {
  error: faExclamationCircle,
  warning: faExclamationTriangle,
  success: faCheck,
  info: faInfoCircle,
};

const durations = {
  short: 4000,
  long: 8000,
};

const Toast = ({ onDismiss, duration, type, message }) => {
  const timer = useRef(null);
  const mobile = !useBreakpoint('sm');

  const setTimer = useCallback(() => {
    if (!duration) return;
    timer.current = setTimeout(onDismiss, durations[duration]);
  }, [timer, duration, onDismiss]);

  const clearTimer = useCallback(() => {
    clearTimeout(timer.current);
    timer.current = null;
  }, [timer]);

  useEffect(() => {
    setTimer();
    return () => clearTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="px-4 py-5 bg-gray-900 rounded-lg shadow-md text-gray-200"
      onMouseEnter={clearTimer}
      onMouseLeave={setTimer}
      onClick={mobile ? onDismiss : () => {}}
      role="presentation"
    >
      <div className="flex items-start">
        <div className="shrink-0">
          <FontAwesomeIcon
            icon={icons[type]}
            size="lg"
            fixedWidth
            className={cx({
              'text-green-500': type === 'success',
              'text-red-500': type === 'error',
              'text-orange-400': type === 'warning',
              'text-gray-600': type === 'info',
            })}
          />
        </div>
        <div className="grow pl-3">{message}</div>
        <div className="hidden sm:block pl-4 shrink-0">
          <button
            type="button"
            className="text-gray-600 hover:text-gray-400 transition-colors duration-200"
            onClick={onDismiss}
          >
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
        </div>
      </div>
    </div>
  );
};

Toast.propTypes = {
  onDismiss: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['error', 'warning', 'success', 'info']),
  duration: PropTypes.oneOf(['short', 'long']),
  message: PropTypes.node,
};

Toast.defaultProps = {
  type: 'info',
  duration: null,
  message: null,
};

export default Toast;
