import { makeVar, useReactiveVar } from '@apollo/client';
import { DateTime } from 'luxon';
import kebabCase from 'lodash/kebabCase';

const downloadsVar = makeVar([]);

type queueDownloadParameters = {
  href: string;
  fileName: string;
};

export const queueDownload = ({ href, fileName }: queueDownloadParameters) => {
  const active = downloadsVar();
  if (active.map((x) => x.href).includes(href)) return;
  downloadsVar([...active, { href, fileName: `${kebabCase(fileName)}.csv` }]);
};

export const clearDownload = (href) => downloadsVar(downloadsVar().filter((x) => x.href !== href));

export const useDownloads = () => useReactiveVar(downloadsVar);
