import { useState } from 'react';
import { useIsomorphicEffect } from 'rooks';

const useIsClient = () => {
  const [isClient, setIsClient] = useState(false);
  useIsomorphicEffect(() => setIsClient(true));
  return isClient;
};

export default useIsClient;
