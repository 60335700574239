import { gql, useMutation } from '@apollo/client';
import { useStripe, useElements } from '@stripe/react-stripe-js';

const CREATE_DONATION_MUTATION = gql`
  mutation CreateDonation($donation: CreateDonationInput!) {
    createDonation(donation: $donation) {
      success
      donationId
      processorData
      error
    }
  }
`;

const useCreateDonation = (mutationOptions = {}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [mutation] = useMutation(CREATE_DONATION_MUTATION, mutationOptions);

  return async (donation, card) => {
    const { error: confirmationError, confirmationToken } = await stripe.createConfirmationToken({
      elements,
      params: {
        payment_method_data: {
          billing_details: {
            email: donation.email,
            name: [donation.firstName, donation.lastName].join(' '),
            phone: donation.phone,
            ...(donation.address1 && {
              address: {
                line1: donation.address1,
                line2: donation.address2,
                city: donation.city,
                state: donation.stateProv,
                country: donation.country,
                postal_code: donation.postalCode,
              },
            }),
          },
        },
      },
    });
    if (confirmationError) {
      setSubmitError(confirmationError);
      return;
    }

    const response = await mutation({
      variables: { donation: { ...donation, confirmationTokenId: confirmationToken.id } },
    });
    const result = response.data.createDonation;

    if (result.error) throw result.error;
    return result;
  };
};

export default useCreateDonation;
