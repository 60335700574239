import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'cloudinary-react';
import cx from 'classnames';

const CampaignPageCustomBlockMedia = ({ mediaType, mediaProperties, alignment }) => {
  const videoUrl = useMemo(() => {
    if (mediaType !== 'video') return null;
    if (mediaProperties.service === 'youtube') {
      return `https://www.youtube.com/embed/${mediaProperties.id}?title=0&byline=0&portrait=0`;
    }
    if (mediaProperties.service === 'vimeo') {
      return `https://player.vimeo.com/video/${mediaProperties.id}?badge=0`;
    }
    return null;
  }, [mediaType, mediaProperties]);

  if (mediaType === 'video' && videoUrl) {
    return (
      <div className="w:full mx-auto">
        <div contentEditable={false}>
          <div style={{ padding: '56.25% 0 0' }} className="h-0 relative">
            <iframe
              title={mediaProperties.original}
              src={videoUrl}
              frameBorder="0"
              className="absolute w-full h-full top-0 left-0"
            />
          </div>
        </div>
      </div>
    );
  }

  if (mediaType === 'image') {
    return (
      <div
        className={cx('w:full', {
          'md:w-3/4': mediaProperties.options?.width === 'medium',
          'md:w-1/2': mediaProperties.options?.width === 'small',
          'mx-auto': alignment === 'center',
        })}
      >
        <Image
          publicId={mediaProperties.id}
          className={cx('block max-w-full', {
            'mx-auto': alignment === 'center',
            'w-full': mediaProperties.options?.width !== 'original',
          })}
          alt={mediaProperties.original ?? ''}
          // TODO: Get Cloudinary responsive scaling to not upscale images when original
          // and the images are smaller than parent width
          // width="auto"
          // responsive
          // dpr="auto"
          // crop="scale"
          // scale="limit"
        />
      </div>
    );
  }

  return null;
};

CampaignPageCustomBlockMedia.propTypes = {
  mediaType: PropTypes.string.isRequired,
  mediaProperties: PropTypes.shape({
    id: PropTypes.string.isRequired,
    service: PropTypes.string.isRequired,
    original: PropTypes.string,
    options: PropTypes.shape({
      width: PropTypes.oneOf(['medium', 'small', 'original']),
    }),
  }).isRequired,
  alignment: PropTypes.oneOf(['left', 'center']),
};

CampaignPageCustomBlockMedia.defaultProps = {
  alignment: 'left',
};

export default CampaignPageCustomBlockMedia;
