import Router from 'next/router';

/**
 * Redirect that can be run from server & client for use
 * in Page.getInitialProps
 */
export default function redirect(res, route, code = 303) {
  const target = route?.url ?? route;

  if (res) {
    res.writeHead(code, { Location: target }).end();
  } else {
    Router.push(target);
  }
}
