import PropTypes from 'prop-types';

import Link from './FormTextEditorElementLink';
import Button from './FormTextEditorElementButton';

const FormTextEditorElement = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'title':
      return <h2 {...attributes}>{children}</h2>;

    case 'subtitle':
      return <h3 {...attributes}>{children}</h3>;

    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;

    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;

    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;

    case 'list-item':
      return <li {...attributes}>{children}</li>;

    case 'divider':
      return (
        <div {...attributes} className="divider">
          {children}
        </div>
      );

    case 'link':
      return (
        <Link node={element} {...attributes}>
          {children}
        </Link>
      );

    case 'button':
      return (
        <Button node={element} {...attributes}>
          {children}
        </Button>
      );

    default:
      return <p {...attributes}>{children}</p>;
  }
};

FormTextEditorElement.propTypes = {
  children: PropTypes.node,
  attributes: PropTypes.shape({}),
  element: PropTypes.shape({
    type: PropTypes.string,
    url: PropTypes.string,
  }),
};

FormTextEditorElement.defaultProps = {
  children: null,
  attributes: {},
  element: {},
};

export default FormTextEditorElement;
