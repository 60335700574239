import { useState, useMemo } from 'react';

import { requiredEmail } from 'lib/validators';

const validateEmail = (str) => {
  try {
    requiredEmail.validateSync(str);
    return true;
  } catch (err) {
    return false;
  }
};

const useInviteSuggestions = ({ possibleSuggestions = [] }) => {
  const [email, setEmail] = useState('');

  const isTyping = email?.length > 0;
  const isValid = isTyping && validateEmail(email);
  const shouldDisplayError = /["(),:;<>[\]\\]/g.test(email);

  const suggestions = useMemo(() => {
    // Do not return suggestions when field is empty or
    // when we're displaying an error
    if (shouldDisplayError || email.length < 2) return [];

    // Determine the list of potential contacts that start with the
    // entered string, starting with an exact match if found
    const matches = possibleSuggestions.reduce((arr, x) => {
      const lowerEmail = x.email.toLowerCase();
      const lowerName = x.fullName?.toLowerCase() ?? '';
      const query = email.toLowerCase();
      if ([lowerEmail, lowerName].some((y) => y === query)) return [x, ...arr];
      if (lowerEmail.startsWith(query) || lowerName.includes(query)) return [...arr, x];
      return arr;
    }, []);

    // If the email is valid, and isn't already in the list, add it
    // to the top
    if (isValid && matches[0]?.email !== email) {
      matches.unshift({ email });
    }

    return matches;
  }, [shouldDisplayError, isValid, email, possibleSuggestions]);

  return {
    email,
    setEmail,
    isValid,
    shouldDisplayError,
    suggestions,
  };
};

export default useInviteSuggestions;
