import { useMemo, forwardRef, ComponentPropsWithRef, Ref } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import style from './FormInput.module.css';

type FormSearchInputProps = {
  onChange: (newValue: string) => void;
  value?: string;
  status?: 'default' | 'error' | 'warning' | 'success' | 'info';
} & Omit<ComponentPropsWithRef<'input'>, 'value' | 'onChange'>;

const FormSearchInput = forwardRef(
  (
    {
      onChange,
      type = 'text',
      placeholder = 'Search...',
      value = '',
      status = 'default',
      disabled = false,
      readOnly = false,
      ...props
    }: FormSearchInputProps,
    ref?: Ref<HTMLInputElement>
  ) => {
    const state = useMemo(() => {
      if (disabled) return 'disabled';
      if (readOnly) return 'readonly';
      return status;
    }, [disabled, readOnly, status]);

    return (
      <div className={cx('flex items-center rounded-md', style.base, style[state])}>
        <div className={cx('shrink-0 pl-3', style.statusColor)}>
          <FontAwesomeIcon icon={faSearch} className="text-lg" fixedWidth />
        </div>
        <input
          value={value}
          className={cx('placeholder-gray-500 focus:outline-none w-full py-2 px-4 bg-transparent')}
          disabled={disabled}
          readOnly={readOnly}
          onChange={(e) => onChange(e.target.value)}
          ref={ref}
          placeholder={placeholder}
          {...props}
        />
        {value && (
          <button
            type="button"
            // We use the mousedown event here to prevent changing browser
            // focus from the input to the button
            onMouseDown={(e) => {
              e.preventDefault();
              onChange('');
            }}
            className="shrink-0 pr-3 transition-colors duration-200 hover:text-gray-500"
          >
            <FontAwesomeIcon icon={faTimes} className="text-lg" fixedWidth />
          </button>
        )}
      </div>
    );
  }
);

FormSearchInput.displayName = 'FormSearchInput';

export default FormSearchInput;
