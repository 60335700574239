import cx from 'lib/cx';
import Link from 'components/common/Link';
import { useDonationFormWidget, useWidgetParams } from './useDonationFormWidget';
import LogoKey from '../../svg/logo-key.svg';

const DonationFormFooter = () => {
  const { config } = useWidgetParams();
  const { campaign } = useDonationFormWidget();

  return (
    <div className="flex flex-col sm:flex-row justify-between items-center gap-y-3 gap-x-4">
      <Link
        href="https://pledgeit.org"
        className="flex items-center gap-[0.25rem] text-gray-500 hover:text-gray-700 transition-150"
        external
      >
        <p className="text-[0.55rem] tracking-wide uppercase font-medium pt-px">Powered By</p>
        <LogoKey className="h-4 fill-current" />
      </Link>
      <nav
        className={cx('text-[0.6rem] flex items-center', {
          'gap-4': !config?.isDonationPage,
          'gap-2.5': config?.isDonationPage,
        })}
      >
        {config?.isDonationPage && (
          <Link
            href={`mailto:${campaign.contactEmail}`}
            className="transition-all duration-150 text-gray-500 hover:text-gray-700 underline hover:no-underline"
          >
            Contact
          </Link>
        )}
        <Link
          href="/tos"
          className="transition-all duration-150 text-gray-500 hover:text-gray-700 underline hover:no-underline"
          external
        >
          Terms of Service
        </Link>
        <Link
          href="/privacy"
          className="transition-all duration-150 text-gray-500 hover:text-gray-700 underline hover:no-underline"
          external
        >
          Privacy Policy
        </Link>
      </nav>
    </div>
  );
};

DonationFormFooter.propTypes = {};

DonationFormFooter.defaultProps = {};

export default DonationFormFooter;
