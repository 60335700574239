import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Image } from 'cloudinary-react';
import cx from 'classnames';

import { formatCurrency } from 'lib/formatters';
import Button from 'components/common/Button';

const CampaignPageImpactStoryCard = ({
  image,
  title,
  text,
  amount,
  buttonAction,
  activeFeatures,
  className,
  onDonate,
  onJoin,
}) => {
  const button = useMemo(() => {
    const buttons = {
      donate: (
        <Button
          as="button"
          type="button"
          onClick={onDonate}
          color="primary"
          className="font-medium text-sm"
          padding="sm"
        >
          Donate
        </Button>
      ),
      fundraiser: (
        <Button
          as="button"
          type="button"
          onClick={onJoin}
          color="primary"
          className="font-medium text-sm"
          padding="sm"
        >
          Fundraise
        </Button>
      ),
    };
    const enabled = buttonAction === 'donate' ? activeFeatures.giving : activeFeatures.join;
    return enabled ? buttons[buttonAction] : null;
  }, [buttonAction, activeFeatures, onDonate, onJoin]);

  return (
    <div className={cx('rounded-lg bg-white shadow-lg overflow-hidden flex flex-col', className)}>
      <Image
        publicId={image}
        alt=""
        width={800}
        aspectRatio="4:3"
        crop="fill"
        fetchFormat="auto"
        gravity="auto:faces"
        className="block w-full shrink-0"
      />
      <div className="p-6 grow">
        {title && <h3 className="font-medium text-xl mb-2">{title}</h3>}
        {text && <p className="text-gray-700">{text}</p>}
      </div>
      <div className="px-6 pb-6 shrink-0 flex justify-between items-center">
        <p className="text-xl font-medium text-gray-600">{formatCurrency(amount)}</p>
        {button}
      </div>
    </div>
  );
};

CampaignPageImpactStoryCard.propTypes = {
  buttonAction: PropTypes.oneOf(['donate', 'fundraiser']).isRequired,
  activeFeatures: PropTypes.shape({
    giving: PropTypes.bool.isRequired,
    join: PropTypes.bool.isRequired,
  }).isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
  amount: PropTypes.number.isRequired,
  className: PropTypes.string,
  onDonate: PropTypes.func,
  onJoin: PropTypes.func,
};

CampaignPageImpactStoryCard.defaultProps = {
  title: null,
  text: null,
  className: '',
  onDonate: () => {},
  onJoin: () => {},
};

export default CampaignPageImpactStoryCard;
