import PropTypes from 'prop-types';
import cx from 'classnames';
import { useRef, useState } from 'react';
import { useIsomorphicEffect } from 'rooks';

import Button from 'components/common/Button';

const CampaignPageFundraiserActions = ({
  primaryLabel,
  onPrimaryClick,
  secondaryLabel,
  secondaryColor,
  onSecondaryClick,
  className,
}) => {
  const [fullWidthButtons, setFullWidthButtons] = useState(!primaryLabel || !secondaryLabel);
  const primary = useRef(null);
  const secondary = useRef(null);

  useIsomorphicEffect(() => {
    if (fullWidthButtons) return;
    const checkButtons = () => {
      if (!primary.current || !secondary.current) return;
      if (primary.current.offsetHeight !== secondary.current.offsetHeight) {
        setFullWidthButtons(true);
      }
    };

    checkButtons();
    window.addEventListener('resize', checkButtons);
    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('resize', checkButtons);
  });

  return (
    <div
      className={cx(
        'grid gap-2',
        {
          'grid-cols-1 gap-y-3': fullWidthButtons,
          'grid-cols-2': !fullWidthButtons,
        },
        className
      )}
    >
      {primaryLabel && (
        <Button
          as="button"
          type="button"
          color="primary"
          className="font-medium lg:text-lg w-full"
          padding="default-even"
          onClick={onPrimaryClick}
          radius="full"
        >
          <span ref={primary}>{primaryLabel}</span>
        </Button>
      )}
      {secondaryLabel && (
        <Button
          as="button"
          type="button"
          color={primaryLabel ? secondaryColor : 'primary'}
          className="font-medium lg:text-lg w-full"
          padding="default-even"
          onClick={onSecondaryClick}
          outline={Boolean(primaryLabel)}
          radius="full"
        >
          <span ref={secondary}>{secondaryLabel}</span>
        </Button>
      )}
    </div>
  );
};

CampaignPageFundraiserActions.propTypes = {
  className: PropTypes.string,
  primaryLabel: PropTypes.node,
  onPrimaryClick: PropTypes.func,
  secondaryLabel: PropTypes.node,
  onSecondaryClick: PropTypes.func,
  secondaryColor: PropTypes.oneOf(['white', 'gray-300']),
};

CampaignPageFundraiserActions.defaultProps = {
  className: '',
  primaryLabel: null,
  onPrimaryClick: () => {},
  secondaryLabel: null,
  onSecondaryClick: () => {},
  secondaryColor: 'gray-300',
};

export default CampaignPageFundraiserActions;
