import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { makeRequired } from 'lib/validators';
import FormFieldGroup from 'components/form/FormFieldGroup';
import FormNode from 'components/form/FormNode';
import FormLabel from 'components/form/FormLabel';
import FormNote from 'components/form/FormNote';
import FormInput from 'components/form/FormInput';
import Button from 'components/common/Button';

const validationSchema = yup.object().shape({
  oldPassword: makeRequired(yup.string()),
  newPassword: makeRequired(yup.string()),
});

const AccountPasswordForm = ({ onSubmit }) => {
  const { control, formState, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      oldPassword: '',
      newPassword: '',
    },
  });

  const { errors, isSubmitting } = formState;

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="p-6">
        <FormFieldGroup>
          <FormNode>
            <FormLabel htmlFor="oldPassword" isRequired>
              Current Password
            </FormLabel>
            <Controller
              control={control}
              name="oldPassword"
              render={({ field, fieldState }) => (
                <FormInput
                  type="password"
                  status={fieldState.error ? 'error' : 'default'}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="oldPassword"
              render={({ message }) => <FormNote status="error">{message}</FormNote>}
            />
          </FormNode>
          <FormNode>
            <FormLabel htmlFor="newPassword" isRequired>
              New Password
            </FormLabel>
            <Controller
              control={control}
              name="newPassword"
              render={({ field, fieldState }) => (
                <FormInput
                  type="password"
                  status={fieldState.error ? 'error' : 'default'}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="newPassword"
              render={({ message }) => <FormNote status="error">{message}</FormNote>}
            />
          </FormNode>
        </FormFieldGroup>
      </div>
      <div className="flex p-6 pt-0">
        <Button
          as="button"
          type="submit"
          color="primary"
          className="font-medium w-full lg:w-auto lg:ml-auto"
          disabled={isSubmitting}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

AccountPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default AccountPasswordForm;
