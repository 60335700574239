import { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useQuery, gql } from '@apollo/client';

import RichText from 'components/common/RichText';
import style from './CampaignPageCustomBlock.module.css';
import CampaignPageCustomBlockMedia from './CampaignPageCustomBlockMedia';

const GET_WIDGET = gql`
  query GetCustomTextBlock($id: String!) {
    findCampaignWidgets(id: $id) {
      id
      title
      config
    }
  }
`;

const GET_BLOCK = gql`
  query GetBlock($id: String!) {
    findCustomTextBlocks(id: $id) {
      id
      title
      text
      backgroundColor
      titleColor
      textColor
      alignment
      layout
      mediaType
      mediaProperties
      isTopPaddingEnabled
      isBottomPaddingEnabled
    }
  }
`;

const CampaignPageCustomBlock = ({ id, type, className }) => {
  const widgetQuery = useQuery(GET_WIDGET, {
    variables: { id },
    skip: type !== 'campaign' || !id,
  });

  const blockQuery = useQuery(GET_BLOCK, {
    variables: { id },
    skip: !['team', 'fundraiser'].includes(type) || !id,
  });

  const block = useMemo(() => {
    if (['team', 'fundraiser'].includes(type) && blockQuery.data?.findCustomTextBlocks[0]) {
      const { title: blockTitle, ...blockConfig } = blockQuery.data.findCustomTextBlocks[0];
      return { title: blockTitle, config: blockConfig };
    }
    return widgetQuery.data?.findCampaignWidgets?.[0];
  }, [type, widgetQuery.data, blockQuery.data]);

  const hasMedia = useMemo(
    () =>
      block?.config?.mediaType &&
      (block?.config?.mediaProperties?.original || block?.config?.mediaProperties?.id),
    [block]
  );

  // Always return NULL if we can't get a block back for some reason
  if (!block) return null;

  return (
    <section
      className={cx(
        'py-0 root',
        {
          'pt-16': block.config.isTopPaddingEnabled,
          'pb-16': block.config.isBottomPaddingEnabled,
        },
        className
      )}
      style={{
        background: block.config.backgroundColor,
        color: block.config.textColor,
      }}
    >
      <div className="container max-w-7xl">
        <div className="lg:w-2/3 lg:mx-auto lg:text-xl">
          {hasMedia && block.config.layout === 'text_bottom' && (
            <div className={cx({ 'mb-6 lg:mb-10': block.title || block.config.text })}>
              <CampaignPageCustomBlockMedia {...block.config} />
            </div>
          )}

          {block.title && (
            <h2
              className={cx('text-3xl lg:text-4xl font-medium leading-tight mb-8', {
                'text-center': block.config.alignment === 'center',
              })}
              style={{ color: block.config.titleColor }}
            >
              {block.title}
            </h2>
          )}
          {block.config.text && (
            <RichText
              content={block.config.text}
              className={cx('leading-relaxed', style.content, {
                'lg:text-center': block.config.alignment === 'center',
              })}
            />
          )}

          {hasMedia && block.config.layout === 'text_top' && (
            <div className={cx({ 'mt-6 lg:mt-10': block.config.text })}>
              <CampaignPageCustomBlockMedia {...block.config} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

CampaignPageCustomBlock.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['campaign', 'team', 'fundraiser']),
  className: PropTypes.string,
};

CampaignPageCustomBlock.defaultProps = {
  type: 'campaign',
  className: '',
};

export default CampaignPageCustomBlock;
