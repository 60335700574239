import { useCallback, PropsWithChildren } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useKey } from 'rooks';
import cx from 'classnames';

import useBreakpoint from 'hooks/useBreakpoint';
import useScrollLock from 'hooks/useScrollLock';
import Portal from './Portal';
import style from './ModalPage.module.css';

type ModalPageProps = PropsWithNonEmptyChildren<{
  onHide: () => void;
  show?: boolean;
  className?: string;
  hideOnOverlayClick?: boolean;
  hideOnEscape?: boolean;
  fixedHeight?: boolean;
}>;

const ModalPage = ({
  onHide,
  children,
  show = false,
  hideOnOverlayClick = true,
  hideOnEscape = true,
  fixedHeight = false,
  className = 'max-w-4xl',
}: ModalPageProps) => {
  const largeScreen = useBreakpoint('lg');

  const handleOverlayClick = useCallback(() => {
    if (hideOnOverlayClick) onHide();
  }, [onHide, hideOnOverlayClick]);

  const handleEscape = useCallback(() => {
    if (hideOnEscape) onHide();
  }, [hideOnEscape, onHide]);

  useKey(['Escape'], handleEscape);
  useScrollLock(show);

  /* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
  return (
    <Portal id="modals">
      <AnimatePresence>
        {show && (
          <motion.div
            key="modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 w-full h-full z-40 flex justify-center items-end lg:items-center"
          >
            <div
              className="absolute inset-0 bg-gray-900 opacity-75 hidden sm:block"
              onClick={handleOverlayClick}
            />
            <motion.div
              className={cx(
                'overflow-y-auto overflow-x-hidden w-full h-full relative bg-white shadow-xl lg:rounded-2xl',
                style.page,
                fixedHeight && style.fixedHeight,
                className
              )}
              initial={{ y: largeScreen ? 100 : 0 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.25, delay: 0.25 }}
            >
              {children}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export default ModalPage;
