import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { gql, useQuery } from '@apollo/client';
import { Image } from 'cloudinary-react';
import { faCalendar, faStopwatch } from '@fortawesome/pro-regular-svg-icons';
import { useIsomorphicEffect } from 'rooks';
import cx from 'classnames';

import config from 'config';
import { useCampaignPage } from 'context/CampaignPage';
import { formatNumber, formatDate, formatCurrency } from 'lib/formatters';
import useBreakpoint from 'hooks/useBreakpoint';
import CampaignPageHeroContent from './CampaignPageHeroContent';
import style from './CampaignPageHero.module.css';

const GET_CAMPAIGN_HERO = gql`
  query GetCampaignHero($id: String!) {
    findCampaigns(id: $id) {
      id
      activeFeatures {
        giving
        join
        tickets
      }
      amountRaised
      campaignDonateButtonLabel
      campaignHeaderBackgroundImage
      campaignHeadline
      campaignJoinButtonLabel
      campaignSubHeadline
      campaignShowGoalDial
      event {
        id
        startDate
      }
      monetaryGoal
      heroShowRaised
      heroStyle
      isFundraisingDisabled
      isMetricLabelLocked
      isPerformanceEnabled
      metricLabel
      metricLabelPlural
      name
      stats {
        aggregatedPerformanceResult
      }
      supportedFeatures {
        join
        event
      }
    }
  }
`;

const CampaignPageHero = () => {
  const router = useRouter();
  const { campaignId, onJoinCampaign } = useCampaignPage();
  const mobile = !useBreakpoint('lg');

  const { data } = useQuery(GET_CAMPAIGN_HERO, { variables: { id: campaignId } });
  const campaign = useMemo(() => data?.findCampaigns[0], [data]);
  const activeFeatures = useMemo(() => campaign?.activeFeatures, [campaign]);

  const hasBackgroundImage = campaign?.campaignHeaderBackgroundImage;

  const showProgress = useMemo(
    () => mobile && campaign?.campaignShowGoalDial && campaign?.monetaryGoal,
    [mobile, campaign]
  );
  const showRaised = useMemo(() => {
    // We don't want to show amount raised if we're showing the progress bar
    // since raised is included in the bar
    if (showProgress) return false;
    return campaign?.heroShowRaised;
  }, [campaign, showProgress]);

  const canJoin = activeFeatures?.join || activeFeatures?.tickets;

  const canShowRollup =
    campaign?.isPerformanceEnabled &&
    campaign?.isMetricLabelLocked &&
    campaign?.stats.aggregatedPerformanceResult !== 0;

  const content = useMemo(() => {
    if (!campaign) return null;
    return (
      <CampaignPageHeroContent
        heroStyle={mobile ? 'fade' : campaign.heroStyle}
        title={campaign.campaignHeadline ?? campaign.name}
        subtitle={campaign.campaignSubHeadline}
        amountRaised={showRaised ? formatCurrency(campaign.amountRaised, { cents: 'never' }) : null}
        showProgress={showProgress}
        chips={[
          campaign.supportedFeatures.event
            ? {
                label: formatDate(campaign.event.startDate),
                icon: faCalendar,
              }
            : null,

          canShowRollup
            ? {
                label: `${formatNumber(campaign.stats.aggregatedPerformanceResult, '0,0')} total ${
                  campaign.stats.aggregatedPerformanceResult === 1
                    ? campaign.metricLabel
                    : campaign.metricLabelPlural
                }`,
                icon: faStopwatch,
              }
            : null,
        ].filter(Boolean)}
        actions={[
          canJoin ? { label: campaign.campaignJoinButtonLabel, onClick: onJoinCampaign } : null,
          campaign.activeFeatures.giving
            ? {
                label: campaign.campaignDonateButtonLabel,
                onClick: () =>
                  router.push(
                    `/c/${campaign.id}/contribute/${
                      campaign.supportedFeatures.join ? 'select' : 'amount'
                    }`
                  ),
              }
            : null,
        ].filter(Boolean)}
      />
    );
  }, [router, mobile, campaign, showRaised, canShowRollup, canJoin, showProgress, onJoinCampaign]);

  useIsomorphicEffect(() => {
    if (router.query.action === 'join') onJoinCampaign(true);
  }, [router.query.action, onJoinCampaign]);

  if (!campaign) return null;

  if (!mobile && campaign.heroStyle === 'basic') {
    return (
      <>
        <header className="py-8 lg:py-16 bg-white">
          <div className="container flex items-center gap-y-8 gap-x-12 max-w-7xl">
            <div className="w-[55%]">
              {campaign.campaignHeaderBackgroundImage ? (
                <Image
                  publicId={campaign.campaignHeaderBackgroundImage}
                  alt=""
                  className="block w-full rounded-2xl"
                  fetchFormat="auto"
                  gravity="faces:auto"
                  aspectRatio="4:3"
                  crop="fill"
                />
              ) : (
                <Image
                  publicId={config('/defaultCampaignHero')}
                  alt=""
                  className="block w-full rounded-2xl"
                  aspectRatio="4:3"
                  crop="fill"
                  width="1400"
                />
              )}
            </div>
            <div className="flex-1">{content}</div>
          </div>
        </header>
        <div className="w-full h-2 bg-gray-200 border-y border-gray-300" />
      </>
    );
  }

  if (!mobile && campaign.heroStyle === 'cover') {
    return (
      <div className="relative">
        <Image
          publicId={campaign.campaignHeaderBackgroundImage ?? config('/defaultCampaignHero')}
          alt=""
          className="block w-full h-[80vh] object-center object-cover"
          fetchFormat="auto"
          gravity={hasBackgroundImage ? 'faces:auto' : undefined}
          aspectRatio="4:3"
          crop="fill"
        />

        <div className="absolute inset-0 z-0 bg-black/40" />

        <div className="absolute inset-0 z-10 flex items-center justify-center">
          <div className="p-6">
            <div className="max-w-3xl">{content}</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white relative pb-8 border-b border-gray-300 lg:pb-0 lg:border-b-0">
      <div className="relative z-0 lg:px-0 lg:max-w-full lg:container">
        <div className="relative lg:mx-0 overflow-hidden lg:pb-[62%] xl:pb-[50%]">
          <div className={cx('hidden lg:block absolute inset-0 z-10', style.overlay)} />
          <div className="relative lg:absolute z-0 lg:inset-y-0 lg:right-0 lg:w-2/3">
            {campaign.campaignHeaderBackgroundImage ? (
              <Image
                publicId={campaign.campaignHeaderBackgroundImage}
                alt=""
                className="block w-full lg:h-full lg:object-center lg:object-cover"
                fetchFormat="auto"
                gravity="faces:auto"
                width={mobile ? '800' : undefined}
                aspectRatio="4:3"
                crop="fill"
              />
            ) : (
              <Image
                publicId={config('/defaultCampaignHero')}
                alt=""
                width={mobile ? '800' : '1400'}
                aspectRatio="4:3"
                fetchFormat="auto"
                crop="fill"
                className="block w-full lg:mx-0 lg:h-full lg:object-center lg:object-contain"
              />
            )}
          </div>
        </div>
      </div>
      <div className="lg:absolute lg:top-0 lg:inset-x-0 lg:h-full lg:flex lg:items-center lg:pb-40">
        <div className="w-full max-w-6xl px-6 xl:px-0 mx-auto">
          <div className="mt-8 lg:mt-0 lg:max-w-[55%]">{content}</div>
        </div>
      </div>
    </div>
  );
};

export default CampaignPageHero;
