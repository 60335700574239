import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useQuery, gql } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import Big from 'big.js';

import { formatCurrency } from 'lib/formatters';
import Modal from 'components/common/Modal';
import Button from 'components/common/Button';
import HundredPercent from '../../svg/graphic-milestone-100.svg';
import FinishLine from '../../svg/graphic-finish-line.svg';

const GET_FUNDRAISER = gql`
  query GetFundraiserFinalAchievement($id: String) {
    findFundraisers(id: $id) {
      id
      campaign {
        id
        ownerOrganization {
          id
          name
        }
      }
      performanceEstimate
      performanceSettings {
        metricLabelPlural
      }
      stats {
        actualAmountRaised
        aggregatedPerformanceResult
      }
    }
  }
`;

const FundraiserAdminFinalAchievementModal = ({ fundraiserId, onHide }) => {
  const { data } = useQuery(GET_FUNDRAISER, { variables: { id: fundraiserId } });
  const fundraiser = useMemo(() => data?.findFundraisers[0], [data]);

  const finalPercent = useMemo(() => {
    if (!fundraiser) return 0;

    const finalPerformance = Big(fundraiser.stats.aggregatedPerformanceResult);
    const initialEstimate = Big(fundraiser.performanceEstimate);
    return finalPerformance.div(initialEstimate).times(100).round();
  }, [fundraiser]);

  const header =
    finalPercent < 100
      ? { graphic: <FinishLine className="mx-auto h-32" />, title: 'Congratulations, you did it!' }
      : {
          graphic: <HundredPercent className="mx-auto h-32" />,
          title: `You hit ${finalPercent}% of your ${fundraiser.performanceSettings.metricLabelPlural} goal!`,
        };

  if (!fundraiser) return null;

  return (
    <Modal show onHide={onHide} className="md:max-w-sm">
      <div className="flex flex-col text-center justify-center px-6 py-8 md:px-8 md:pt-8 md:pb-6 relative">
        <button type="button" className="absolute top-4 left-5" onClick={onHide}>
          <FontAwesomeIcon icon={faTimes} size="2x" />
        </button>
        {header.graphic}
        <h2 className="font-medium text-3xl my-4 leading-tight">{header.title}</h2>
        <p className="mt-1 mb-5">{`Thank you for going on this journey to raise money for ${
          fundraiser.campaign.ownerOrganization.name
        }. You earned ${formatCurrency(fundraiser.stats.actualAmountRaised)} by recording ${
          fundraiser.stats.aggregatedPerformanceResult
        } ${fundraiser.performanceSettings.metricLabelPlural}.`}</p>
        <Button
          as="button"
          type="button"
          color="green"
          className="font-medium block w-full"
          onClick={onHide}
        >
          Done
        </Button>
      </div>
    </Modal>
  );
};

FundraiserAdminFinalAchievementModal.propTypes = {
  fundraiserId: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default FundraiserAdminFinalAchievementModal;
