import cx from 'lib/cx';
import { formatCurrency } from 'lib/formatters';
import Button from 'components/common/Button';
import RichText from 'components/common/RichText';
import DonationFormStep from './DonationFormStep';
import { useDonationFormWidget } from './useDonationFormWidget';
import useDonationFormAmounts from './useDonationFormAmounts';

const DonationFormConfirmation = () => {
  const { config } = useDonationFormWidget();
  const amounts = useDonationFormAmounts();

  return (
    <DonationFormStep
      action={
        config.formRedirectUrl ? (
          <Button
            type="button"
            color="primary"
            padding="sm"
            className="text-lg font-medium w-full"
            style={{ color: config.formButtonLabelColor }}
            href={config.formRedirectUrl}
          >
            {config.formRedirectLabel || 'Go to Website'}
          </Button>
        ) : null
      }
    >
      <div
        className={cx('text-center', {
          'h-full flex items-center': !config.formThankYouMessage,
        })}
      >
        <h1 className="text-3xl font-medium">
          Thank you for your {formatCurrency(amounts.total)} gift!
        </h1>
        {config.formThankYouMessage && (
          <RichText className="mt-5 text-2xl text-gray-600" content={config.formThankYouMessage} />
        )}
      </div>
    </DonationFormStep>
  );
};

export default DonationFormConfirmation;
