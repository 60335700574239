import { Children, useCallback, forwardRef, PropsWithChildren } from 'react';
import cx from 'classnames';
import findLastIndex from 'lodash/findLastIndex';
import last from 'lodash/last';

import useScreen from 'hooks/useScreen';
import style from './Tiles.module.css';

type ResponsiveValue<T extends string | number> = T | T[];

type TilesProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<
  C,
  PropsWithChildren<{
    spacing?: ResponsiveValue<'none' | '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'>;
    columns?: ResponsiveValue<number>;
    align?: ResponsiveValue<'left' | 'center' | 'right' | 'justify'>;
    inline?: boolean;
    wrap?: boolean;
    className?: string;
    itemClassName?: string;
  }>
>;

type TilesComponent = (<C extends React.ElementType = 'div'>(
  props: TilesProps<C>
) => React.ReactElement | null) & { displayName?: string };

// @ts-expect-error -- this gets handled by strictly typing the component above
const Tiles: TilesComponent = forwardRef(
  <C extends React.ElementType = 'div'>(
    {
      spacing = 'none',
      columns = null,
      align = 'left',
      inline = false,
      wrap = true,
      className = '',
      itemClassName = '',
      as,
      children = null,
    }: TilesProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Component = as || 'div';
    const items = Children.toArray(children);
    const screen = useScreen();

    const getResponsiveClassName = useCallback(
      (values, classMap) => {
        const screens = [true, screen.md, screen.lg, screen.xl];
        const valueArray = [].concat(values);

        const screenIndex = findLastIndex(screens, (x) => x);
        const matchingValue =
          valueArray.length > screenIndex ? valueArray[screenIndex] : last(valueArray);
        const matchingClass = classMap[matchingValue || 'false'];
        return style[matchingClass];
      },
      [screen]
    );

    return (
      <Component
        ref={ref}
        className={cx([
          {
            flex: !inline,
            'inline-flex': inline,
            'flex-wrap': wrap,
          },
          getResponsiveClassName(spacing, {
            '2xs': 'tiles_containerSpacing2xs',
            xs: 'tiles_containerSpacingXs',
            sm: 'tiles_containerSpacingSm',
            md: 'tiles_containerSpacingMd',
            lg: 'tiles_containerSpacingLg',
            xl: 'tiles_containerSpacingXl',
          }),
          getResponsiveClassName(align, {
            left: 'tiles_alignLeft',
            center: 'tiles_alignCenter',
            right: 'tiles_alignRight',
            justify: 'tiles_alignJustify',
          }),
          className,
        ])}
      >
        {items.map((item, i) => (
          <div
            key={i} // eslint-disable-line react/no-array-index-key
            className={cx([
              getResponsiveClassName(spacing, {
                '2xs': 'tiles_tileSpacing2xs',
                xs: 'tiles_tileSpacingXs',
                sm: 'tiles_tileSpacingSm',
                md: 'tiles_tileSpacingMd',
                lg: 'tiles_tileSpacingLg',
                xl: 'tiles_tileSpacingXl',
              }),
              getResponsiveClassName(columns, {
                false: 'tiles_tileWidthAuto',
                1: 'tiles_tileWidth1',
                2: 'tiles_tileWidth2',
                3: 'tiles_tileWidth3',
                4: 'tiles_tileWidth4',
                5: 'tiles_tileWidth5',
              }),
              itemClassName,
            ])}
          >
            {item}
          </div>
        ))}
      </Component>
    );
  }
);

Tiles.displayName = 'Tiles';

export default Tiles;
