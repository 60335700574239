import { useMemo, useEffect } from 'react';
import Color from 'color';

import config from 'config';

const defaultColors = {
  primary: config('/tw/colors/teal/500'),
  primaryLight: config('/tw/colors/teal/300'),
  secondary: config('/tw/colors/green/500'),
  secondaryLight: config('/tw/colors/green/300'),
};

const lightenColor = (color) => {
  if (color === defaultColors.primary) return defaultColors.primaryLight;
  if (color === defaultColors.secondary) return defaultColors.secondaryLight;
  return Color(color).mix(Color('white')).hex();
};

const colorToRGBValues = (color) => Color(color).array().join(' ');

const setCSSColorVariables = (colors) => {
  const root = document.documentElement;
  root.style.setProperty('--color-theme-primary', colorToRGBValues(colors.primary));
  root.style.setProperty('--color-theme-primary-light', colorToRGBValues(colors.primaryLight));
  root.style.setProperty('--color-theme-secondary', colorToRGBValues(colors.secondary));
  root.style.setProperty('--color-theme-secondary-light', colorToRGBValues(colors.secondaryLight));
};

type CustomThemeOptions = {
  primary?: string | null;
  secondary?: string | null;
  enabled?: boolean;
};

const useCustomTheme = ({ primary, secondary, enabled = true }: CustomThemeOptions = {}) => {
  const customColors = useMemo(
    () => ({
      primary: primary ?? defaultColors.primary,
      primaryLight: primary ? lightenColor(primary) : defaultColors.primaryLight,
      secondary: secondary ?? defaultColors.secondary,
      secondaryLight: secondary ? lightenColor(secondary) : defaultColors.secondaryLight,
    }),
    [primary, secondary]
  );

  const activeColors = useMemo(
    () => (enabled ? customColors : defaultColors),
    [enabled, customColors]
  );

  // Apply new colors when anything with the theme changes
  useEffect(() => {
    setCSSColorVariables(activeColors);
  }, [activeColors]);

  // Always reset to default when unmounting
  useEffect(
    () => () => {
      setCSSColorVariables(defaultColors);
    },
    []
  );

  return activeColors;
};

export default useCustomTheme;
