import { useMemo } from 'react';
import { gql } from '@apollo/client';
import { Image } from 'cloudinary-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import config from 'config';
import useScreen from 'hooks/useScreen';
import { formatCurrency, formatTimeAgo } from 'lib/formatters';
import Card from 'components/common/Card';
import Link from 'components/common/Link';

export const AUCTION_ITEM_FIELDS = gql`
  fragment AuctionItemFields on AuctionItem {
    id
    name
    sort
    status
    endDate
    startDate
    campaignId
    categoryId

    images(order: "sort") {
      id
      image
    }

    numBids
    highBid
    startingBid
    estimatedValue
    myBid {
      bid
      type
      isWinning
    }
  }
`;

type AuctionItemProps = {
  id: string;
  name: string;
  status: 'scheduled' | 'active' | 'ended' | 'paid' | 'chargeFailed' | 'fulfilled' | 'cancelled';
  images: { image?: string }[];
  highBid: string;
  startingBid: string;
  startDate: string;
  endDate: string;
  numBids: number;
  campaignId: string;
  showBidInfo?: boolean;
  myBid: { isWinning: boolean };
};

const AuctionItem = ({
  id,
  name,
  status,
  images,
  highBid,
  startingBid,
  startDate,
  endDate,
  numBids,
  campaignId,
  showBidInfo = false,
  myBid = null,
}: AuctionItemProps) => {
  const screen = useScreen();

  const primaryImage = images[0]?.image;
  const isEnded = ['ended', 'paid', 'fulfilled', 'chargeFailed'].includes(status);

  const bidInfo = useMemo(() => {
    if (status === 'active') {
      if (myBid?.isWinning) return 'Winning';
      if (myBid) return 'Outbid';
      return `${numBids} bid${numBids === 0 || numBids > 1 ? 's' : ''}`;
    }
    if (isEnded && myBid?.isWinning) return 'Won item';
    if (status === 'scheduled') {
      return 'Coming soon';
    }
    return 'Ended';
  }, [myBid, numBids, isEnded, status]);

  const timeLeft = useMemo(() => {
    if (status === 'scheduled') {
      return `Starts ${formatTimeAgo(startDate, 'short')}`;
    }
    if (isEnded) {
      return `Ended ${formatTimeAgo(endDate, 'short')}`;
    }

    return `Ends ${formatTimeAgo(endDate, 'short')}`;
  }, [startDate, endDate, status, isEnded]);

  return (
    <Card
      as={Link}
      depth="none"
      radius={screen.md ? 'lg' : 'none'}
      className="bg-white border border-gray-400 block m-px cursor-pointer hover:border-2 hover:border-gray-800 hover:m-0 overflow-hidden"
      padding="none"
      href={`/c/${campaignId}/auction/${id}`}
    >
      <div className="flex flex-col">
        <div className="relative shrink-0 h-1/2">
          <Image
            publicId={primaryImage ?? config('/defaultAuctionItemImage')}
            alt=""
            width={800}
            aspectRatio="4:3"
            crop="fill"
            fetchFormat="auto"
            gravity="auto:faces"
            className={cx('block w-full', { 'opacity-50': isEnded, 'rounded-t-xl': screen.md })}
          />
          {showBidInfo && (
            <div
              className={cx(
                'absolute bottom-5 left-4 rounded-md px-2 py-1.5 lg:px-3 lg:py-2 font-medium text-sm lg:text-base',
                {
                  'bg-green-500 text-white': ['Winning', 'Won item'].includes(bidInfo),
                  'bg-red-500 text-white': bidInfo === 'Outbid',
                  'bg-gray-800 text-white': ['Coming soon', 'Ended'].includes(bidInfo),
                  'bg-white': !['Winning', 'Won item', 'Outbid', 'Coming soon', 'Ended'].includes(
                    bidInfo
                  ),
                }
              )}
            >
              {bidInfo}
            </div>
          )}
        </div>
        <div className="shrink-0 p-4 sm:p-6">
          <h2 className="font-medium text-lg">{name}</h2>
          {numBids > 0 ? (
            <p className="text-xl mt-3 sm:mt-4">
              {formatCurrency(highBid)}
              <span className="text-base text-gray-600 ml-1">
                {isEnded ? 'winning' : 'current'} bid
              </span>
            </p>
          ) : (
            <p className="text-xl mt-3 sm:mt-4">
              {formatCurrency(startingBid)}
              <span className="text-base text-gray-600 ml-1">starting bid</span>
            </p>
          )}
          <p className="flex items-center gap-x-2 mt-4">
            <FontAwesomeIcon icon={faClock} className="mr-1" />
            {timeLeft}
          </p>
        </div>
      </div>
    </Card>
  );
};

export default AuctionItem;
