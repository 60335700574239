import { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  faExclamationTriangle,
  faInfoCircle,
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/pro-regular-svg-icons';

import cx from 'lib/cx';
import { renderAppendedInputNode } from './FormInput';
import style from './FormInput.module.css';

const FormTextarea = forwardRef(
  (
    {
      onChange,
      value,
      status,
      disabled,
      readOnly,
      prefix,
      suffix,
      className,
      wrapperClassName,
      ...props
    },
    ref
  ) => {
    const state = useMemo(() => {
      if (disabled) return 'disabled';
      if (readOnly) return 'readonly';
      return status;
    }, [disabled, readOnly, status]);

    const statusIcon = useMemo(() => {
      if (state === 'error') return faExclamationCircle;
      if (state === 'warning') return faExclamationTriangle;
      if (state === 'success') return faCheckCircle;
      if (state === 'info') return faInfoCircle;
      return null;
    }, [state]);

    return (
      <div
        className={cx(
          'flex justify-start items-center',
          style.base,
          style[state],
          wrapperClassName
        )}
      >
        {prefix && (
          <div className={cx('shrink-0 pl-3', style.statusColor)}>
            {renderAppendedInputNode(prefix)}
          </div>
        )}
        <textarea
          ref={ref}
          className={cx(
            'placeholder-gray-500 focus:outline-none w-full py-2 px-4 bg-transparent min-h-36',
            className
          )}
          disabled={disabled}
          readOnly={readOnly}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          {...props}
        />
        {(statusIcon ?? suffix) && (
          <div className={cx('shrink-0 pr-3', style.statusColor)}>
            {renderAppendedInputNode(statusIcon ?? suffix)}
          </div>
        )}
      </div>
    );
  }
);

FormTextarea.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  status: PropTypes.oneOf(['default', 'error', 'warning', 'success', 'info']),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  prefix: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({ iconName: PropTypes.string })]),
  suffix: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({ iconName: PropTypes.string })]),
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
};

FormTextarea.defaultProps = {
  status: 'default',
  value: '',
  disabled: false,
  readOnly: false,
  prefix: null,
  suffix: null,
  wrapperClassName: '',
  className: '',
};

export default FormTextarea;
