import { useCallback, PropsWithChildren } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useKey } from 'rooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

import cx from 'lib/cx';
import useScrollLock from 'hooks/useScrollLock';
import ClickStop from './ClickStop';
import Portal from './Portal';

type ModalProps = PropsWithNonEmptyChildren<{
  onHide: () => void;
  show?: boolean;
  displayCloseButton?: boolean;
  hideOnOverlayClick?: boolean;
  hideOnEscape?: boolean;
  className?: string;
}>;

const Modal = ({
  onHide,
  children,
  show = false,
  hideOnOverlayClick = true,
  hideOnEscape = true,
  displayCloseButton = false,
  className = '',
}: ModalProps) => {
  const handleOverlayClick = useCallback(() => {
    if (hideOnOverlayClick) onHide();
  }, [hideOnOverlayClick, onHide]);

  const handleEscape = useCallback(() => {
    if (hideOnEscape) onHide();
  }, [hideOnEscape, onHide]);

  useKey(['Escape'], handleEscape);
  useScrollLock(show);

  /* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
  return (
    <Portal id="modals">
      <AnimatePresence>
        {show && (
          <ClickStop
            as={motion.div}
            key="modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 w-full h-full z-40 flex justify-center items-end lg:items-center lg:p-12 overflow-y-auto"
          >
            <div className="fixed inset-0 bg-gray-900 opacity-75" onClick={handleOverlayClick} />
            <motion.div
              className={cx(
                'rounded-2xl m-4 overflow-y-auto overflow-x-hidden max-h-full relative bg-white shadow-xl w-full md:max-w-2xl',
                className
              )}
              initial={{ y: 100 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.25, delay: 0.25 }}
            >
              {children}
            </motion.div>
            {displayCloseButton && (
              <button
                type="button"
                onClick={onHide}
                className="w-12 h-12 flex lg:hidden justify-center items-center bg-white rounded-full shadow-lg fixed right-0 top-0 mt-3 mr-3 text-2xl text-gray-400"
              >
                <FontAwesomeIcon icon={faTimes} size="1x" />
              </button>
            )}
          </ClickStop>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export default Modal;
