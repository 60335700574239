import PropTypes from 'prop-types';
import cx from 'classnames';

import { useCampaignPage } from 'context/CampaignPage';
import CampaignPageSectionHeader from './CampaignPageSectionHeader';
import CampaignPageActivityFeed from './CampaignPageActivityFeed';

const CampaignPageCommunityActivity = ({ title, className }) => {
  const { campaignId } = useCampaignPage();

  return (
    <div className={cx('my-24', className)}>
      <div className="sm:container">
        <CampaignPageSectionHeader title={title} titleClassName="text-gray-800" />
        <CampaignPageActivityFeed
          className="mt-8 -mx-3 sm:mx-0"
          filter={{ campaignId }}
          allowLoadMore
          allowReply
          showFundraiserName
          showTeamName
        />
      </div>
    </div>
  );
};

CampaignPageCommunityActivity.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

CampaignPageCommunityActivity.defaultProps = {
  title: 'Donations',
  className: '',
};

export default CampaignPageCommunityActivity;
