import { useState } from 'react';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';

import FormCheckboxNoLabel from 'components/form/FormCheckboxNoLabel';
import Button from './Button';
import InviteGroupItem from './InviteGroupItem';

type InviteGroupItem = {
  avatar: string;
  email: string;
  fullName: string;
  source: string;
  isSelected: boolean;
  alreadyInvited: boolean;
};

type InviteGroupsProps = {
  groups: {
    label: string;
    items: InviteGroupItem[];
  }[];
  onSubmit: (values: InviteGroupItem[]) => void;
  primaryKey?: string;
};

const InviteGroups = ({ groups, onSubmit, primaryKey = 'email' }: InviteGroupsProps) => {
  const [selected, setSelected] = useState<Record<string, InviteGroupItem>>({});
  const selectedCount = Object.keys(selected).length;

  return (
    <>
      <div className="flex-1 space-y-4 overflow-y-auto hide-scroll">
        {groups.map(({ label, items }) => {
          if (items.length === 0) return null;

          const selectableItems = items.filter((x) => !x.alreadyInvited);

          const isDisabled =
            items.every((contact) => contact.isSelected) || selectableItems.length === 0;
          const isSelected =
            isDisabled ||
            selectableItems.every((contact) => contact.isSelected || !!selected[contact.email]);

          return (
            <section key={label}>
              <header className="bg-gray-100 py-2.5 px-6 flex justify-between items-center mb-px">
                <h3 className="leading-none font-medium">{label}</h3>
                <FormCheckboxNoLabel
                  value={isSelected}
                  onChange={() => {
                    if (isDisabled) return;
                    setSelected((prev) => {
                      if (!isSelected) return { ...prev, ...keyBy(selectableItems, primaryKey) };

                      return omit(
                        prev,
                        selectableItems.reduce(
                          (arr, contact) => (contact.isSelected ? arr : [...arr, contact.email]),
                          []
                        )
                      );
                    });
                  }}
                  disabled={isDisabled}
                  themed
                />
              </header>
              {items.map((item) => (
                <InviteGroupItem
                  key={item[primaryKey]}
                  avatar={item.avatar}
                  name={item.fullName}
                  detail={item[primaryKey]}
                  onSelect={() => {
                    if (item.isSelected) return;
                    setSelected((prev) =>
                      prev[item[primaryKey]]
                        ? omit(prev, item[primaryKey])
                        : { ...prev, [item[primaryKey]]: item }
                    );
                  }}
                  isSelected={item.isSelected || !!selected[item.email] || item.alreadyInvited}
                  isDisabled={item.isSelected || item.alreadyInvited}
                />
              ))}
            </section>
          );
        })}
      </div>
      <div className="bg-white shrink-0 p-6 border-t border-gray-400">
        <Button
          as="button"
          type="button"
          color="primary"
          className="w-full font-medium"
          disabled={selectedCount === 0}
          onClick={() => onSubmit(Object.values(selected))}
        >
          Add {selectedCount || ''} contacts
        </Button>
      </div>
    </>
  );
};

export default InviteGroups;
