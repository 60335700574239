import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faFlagCheckered } from '@fortawesome/pro-solid-svg-icons';

import config from 'config';
import { formatNumber, parseNumber } from 'lib/formatters';
import { useGlobal } from 'context/Global';
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import FundraiserAdminFinalAchievementModal from './FundraiserAdminFinalAchievementModal';
import ClockSuccessGraphic from '../../svg/graphic-clock-success.svg';

const GET_FUNDRAISER = gql`
  query GetFundraiserConfirmEventPerformance($id: String!) {
    findFundraisers(id: $id) {
      id
      performanceSettings {
        metricLabel
        metricLabelPlural
      }
      stats {
        aggregatedPerformanceResult
      }
    }
  }
`;

const UPDATE_FUNDRAISER = gql`
  mutation UpdateFundraiser($data: FundraiserInput!) {
    updateFundraiser(Fundraiser: $data) {
      id
      status
    }
  }
`;

const FundraiserAdminConfirmEventPerformance = ({ fundraiserId, performed, onHide }) => {
  const { addToast } = useGlobal();
  const [showFinished, setShowFinished] = useState(false);

  const { data } = useQuery(GET_FUNDRAISER, { variables: { id: fundraiserId } });
  const fundraiser = useMemo(() => data?.findFundraisers[0], [data]);
  const performanceTotal = fundraiser?.stats.aggregatedPerformanceResult;

  const [updateFundraiser] = useMutation(UPDATE_FUNDRAISER);

  const getMetricLabel = useCallback(
    (count) => {
      if (!fundraiser) return '';
      return fundraiser.performanceSettings[count === 1 ? 'metricLabel' : 'metricLabelPlural'];
    },
    [fundraiser]
  );

  const onFinish = useCallback(async () => {
    try {
      await updateFundraiser({
        variables: {
          data: {
            id: fundraiserId,
            status: 'confirmed',
          },
        },
      });

      setShowFinished(true);
    } catch (err) {
      if (config('/debug')) console.error(err);
      addToast({
        id: 'confirmFundraiser',
        type: 'error',
        duration: 'long',
        message: 'There was an issue confirming your fundraiser. Please try again later.',
      });
    }
  }, [updateFundraiser, fundraiserId, addToast]);

  if (!fundraiser) return null;

  if (showFinished) {
    return <FundraiserAdminFinalAchievementModal fundraiserId={fundraiserId} onHide={onHide} />;
  }

  return (
    <Modal show onHide={onHide} className="md:max-w-sm">
      <div className="flex flex-col text-center justify-center px-6 py-8 md:px-8 md:pt-8 md:pb-6 relative">
        <button type="button" className="absolute top-4 left-5" onClick={onHide}>
          <FontAwesomeIcon icon={faTimes} size="2x" />
        </button>
        <ClockSuccessGraphic className="mx-auto h-32" />
        <h2 className="font-medium text-3xl my-4 leading-tight">
          Nice job! You recorded {formatNumber(performed, '0,0.[00]')}{' '}
          {getMetricLabel(parseNumber(performed))}
        </h2>
        <p className="mt-1">
          Would you like to{' '}
          <strong className="font-medium">
            submit {formatNumber(performanceTotal, '0,0.[00]')} {getMetricLabel(performanceTotal)}{' '}
            as your final total
          </strong>{' '}
          or will you enter more activity later?
        </p>
        <div className="mt-6 grid grid-cols-1 gap-y-2">
          <Button
            as="button"
            type="button"
            color="green"
            className="font-medium block w-full"
            onClick={onHide}
          >
            I want to keep going
          </Button>
          <Button
            as="button"
            type="button"
            onClick={onFinish}
            color="gray-300"
            className="w-full font-medium"
            outline
          >
            <FontAwesomeIcon icon={faFlagCheckered} size="lg" className="mr-3" />
            I&apos;m finished
          </Button>
        </div>
      </div>
    </Modal>
  );
};

FundraiserAdminConfirmEventPerformance.propTypes = {
  fundraiserId: PropTypes.string.isRequired,
  performed: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
};

FundraiserAdminConfirmEventPerformance.defaultProps = {};

export default FundraiserAdminConfirmEventPerformance;
