import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useQuery, gql } from '@apollo/client';
import { motion } from 'framer-motion';

import clientOnly from 'components/clientOnly';
import Tiles from 'components/common/Tiles';
import Button from 'components/common/Button';
import CampaignPageDonation from './CampaignPageDonation';

const GET_DONATIONS = gql`
  query GetDonations($where: SequelizeJSON!, $limit: Int!, $skip: Int!) {
    findDonations(where: $where, limit: $limit, offset: $skip, order: "reverse:createdAt") {
      id
      amount
      comment
      createdAt
      donationTypeCode
      estimatedAmount
      isAnonymous
      fullName
      offlinePaymentDate

      fundraiser {
        id
        resolvedName
        status
        performanceEstimate
        aggregatedPerformanceResult

        performanceSettings {
          id
          metricLabel
        }
      }

      replies {
        id
        reply

        createdBy {
          id
          firstName
          lastName
        }
      }

      team {
        id
        name

        performanceSettings {
          id
          metricLabel
        }
      }

      donationMatches {
        amount
        match {
          createdAt
          name
          logo
        }
      }
    }
  }
`;

// Some pseudo-ish code for when we're ready to implement donation replies

// const CREATE_DONATION_REPLY = gql`
//   mutation CreateDonationReply($donationId: ID!, $reply: String!) {
//     createDonationReply(donationId: $donationId, reply: $reply) {
//       id
//     }
//   }
// `;

// const [createDonationReplyMutation] = useMutation(CREATE_DONATION_REPLY);

// const createDonationReply = useCallback(
//   (donationId, reply) => createDonationReplyMutation({ donationId, reply }),
//   [createDonationReplyMutation]
// );

const CampaignPageActivityFeed = ({
  allowReply,
  allowLoadMore,
  className,
  emptyLabel,
  filter,
  initialLimit,
  loadMoreLabel,
  loadMoreLimit,
  showFundraiserName,
  showTeamName,
}) => {
  const [hasMore, setHasMore] = useState(true);

  const { data, loading, fetchMore } = useQuery(GET_DONATIONS, {
    variables: { where: { status: 'active', ...filter }, limit: initialLimit, skip: 0 },
    onCompleted: ({ findDonations = [] }) => {
      if (findDonations.length < initialLimit) setHasMore(false);
    },
  });
  const donations = data?.findDonations ?? [];

  const handleLoadMore = useCallback(
    () =>
      fetchMore({
        variables: {
          limit: loadMoreLimit,
          skip: donations.length,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const newDonations = fetchMoreResult?.findDonations ?? [];

          // We've reached the end
          if (newDonations.length < loadMoreLimit) setHasMore(false);

          return { ...prev, findDonations: [...prev.findDonations, ...newDonations] };
        },
      }),
    [fetchMore, loadMoreLimit, donations.length]
  );

  // Still loading
  if (donations.length === 0 && loading) return null;

  if (donations.length === 0) {
    return (
      <div className={className}>
        <p className="text-gray-600 text-lg text-center">{emptyLabel}</p>
      </div>
    );
  }

  return (
    <div className="sm:container max-w-7xl">
      <div className={className}>
        <Tiles columns={1} spacing="xs">
          {donations.map((donation) => (
            <motion.div
              key={donation.id}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.2 }}
            >
              <CampaignPageDonation
                {...donation}
                allowReply={allowReply}
                onReply={(reply) => console.log('Reply to donation', { donation, reply })}
                showFundraiserName={showFundraiserName}
                showTeamName={showTeamName}
              />
            </motion.div>
          ))}
        </Tiles>
        {allowLoadMore && hasMore && (
          <div className="mt-8 sm:mt-12 text-center">
            <Button
              as="button"
              type="button"
              onClick={handleLoadMore}
              color="gray-300"
              outline
              className="rounded-full font-medium"
            >
              {loadMoreLabel}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

CampaignPageActivityFeed.propTypes = {
  filter: PropTypes.oneOfType([
    PropTypes.shape({ campaignId: PropTypes.string.isRequired }),
    PropTypes.shape({ teamId: PropTypes.string.isRequired }),
    PropTypes.shape({ fundraiserId: PropTypes.string.isRequired }),
  ]).isRequired,

  allowReply: PropTypes.bool,
  allowLoadMore: PropTypes.bool,
  className: PropTypes.string,
  emptyLabel: PropTypes.node,
  initialLimit: PropTypes.number,
  loadMoreLabel: PropTypes.node,
  loadMoreLimit: PropTypes.number,
  showFundraiserName: PropTypes.bool,
  showTeamName: PropTypes.bool,
};

CampaignPageActivityFeed.defaultProps = {
  allowReply: false,
  allowLoadMore: false,
  className: '',
  emptyLabel: 'Check back soon!',
  initialLimit: 5,
  loadMoreLabel: 'Show More',
  loadMoreLimit: 10,
  showFundraiserName: false,
  showTeamName: false,
};

export default clientOnly(CampaignPageActivityFeed);
