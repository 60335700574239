import { useEffect, useReducer, useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { DateTime, Info } from 'luxon';
import times from 'lodash/times';

import FormLabel from 'components/form/FormLabel';
import FormSelect from './FormSelect';

const monthNames = Info.months('long');
const monthNumbers = Info.months('2-digit');

const reducer = (state, action) => {
  switch (action.type) {
    case 'reset': {
      const [y = '', m = '', d = ''] = action.payload.split('-');
      return { ...state, year: y, month: m, day: d, dirty: false };
    }

    case 'day': {
      return { ...state, day: action.payload, dirty: true };
    }

    case 'month': {
      return { ...state, month: action.payload, dirty: true };
    }

    case 'year': {
      return { ...state, year: action.payload, dirty: true };
    }

    default: {
      throw new Error(`Unknown action: "${action.type}"`);
    }
  }
};

const FormDateSelect = forwardRef(({ value, onChange, status, disabled, readOnly }, ref) => {
  const [{ year, month, day, dirty }, dispatch] = useReducer(reducer, {
    year: '',
    month: '',
    day: '',
    dirty: false,
  });

  useEffect(() => {
    dispatch({ type: 'reset', payload: value ?? '' });
  }, [value, dispatch]);

  const days = useMemo(() => {
    const daysInMonth = month
      ? DateTime.fromObject({ month, year: year || undefined }).daysInMonth
      : 31;

    return times(daysInMonth, (i) => {
      const d = (i + 1).toString().padStart(2, '0');
      return { label: d, value: d };
    });
  }, [month, year]);

  const months = useMemo(
    () =>
      monthNumbers.map((num, i) => ({
        label: monthNames[i],
        value: num,
      })),
    []
  );

  const years = useMemo(() => {
    const currentYear = DateTime.local().year + 2;
    return times(102, (i) => {
      const x = currentYear - i;
      return { label: x.toString(), value: x.toString() };
    });
  }, []);

  // Day has become invalid -- can happen when month changes
  useEffect(() => {
    if (day && !days.find((x) => x.value === day)) {
      dispatch({ type: 'day', payload: '01' });
    }
  }, [day, days, dispatch]);

  // Handle changing actual form value
  useEffect(() => {
    if (!dirty) return;

    const newDate =
      day && month && year ? DateTime.fromObject({ day, month, year }).toISODate() : '';
    if (newDate !== value) onChange(newDate);
  }, [value, dirty, day, month, year, onChange]);

  return (
    <div className="flex flex-wrap sm:flex-nowrap -ml-2 -mt-2" ref={ref}>
      <div className="pt-2 pl-2 w-full sm:w-5/12">
        <FormLabel id="monthLabel" className="sr-only" />
        <FormSelect
          placeholder="Month"
          options={months}
          value={month}
          onChange={(x) => dispatch({ type: 'month', payload: x })}
          isClearable={false}
          showSearchIcon={false}
          status={status}
          disabled={disabled}
          readOnly={readOnly}
          aria-labelledby="monthLabel"
        />
      </div>
      <div className="pt-2 pl-2 w-1/2 sm:w-1/4">
        <FormLabel id="dayLabel" className="sr-only" />
        <FormSelect
          placeholder="Day"
          options={days}
          value={day}
          onChange={(x) => dispatch({ type: 'day', payload: x })}
          isClearable={false}
          showSearchIcon={false}
          status={status}
          disabled={disabled}
          readOnly={readOnly}
          aria-labelledby="dayLabel"
        />
      </div>
      <div className="pt-2 pl-2 w-1/2 sm:w-1/3">
        <FormLabel id="yearLabel" className="sr-only" />
        <FormSelect
          placeholder="Year"
          options={years}
          value={year}
          onChange={(x) => dispatch({ type: 'year', payload: x })}
          isClearable={false}
          showSearchIcon={false}
          status={status}
          disabled={disabled}
          readOnly={readOnly}
          aria-labelledby="yearLabel"
        />
      </div>
    </div>
  );
});

FormDateSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  status: PropTypes.oneOf(['default', 'error', 'warning', 'success', 'info']),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
};

FormDateSelect.defaultProps = {
  value: '',
  status: 'default',
  disabled: false,
  readOnly: false,
};

export default FormDateSelect;
