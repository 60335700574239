import { PropsWithChildren, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDidMount } from 'rooks';

type PortalProps = PropsWithChildren<{ id: string }>;

const Portal = ({ id, children = null }: PortalProps) => {
  const [container, setContainer] = useState(null);

  useDidMount(() => {
    const existing = document.getElementById(id);

    // Re-use existing container if possible
    if (existing) {
      setContainer(existing);
      return;
    }

    // Render a new container
    const newEl = document.createElement('div');
    newEl.setAttribute('id', id);
    document.body.appendChild(newEl);
    setContainer(newEl);
  });

  if (!container) return null;
  return createPortal(children, container);
};

export default Portal;
