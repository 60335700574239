const mergeRefs =
  (...refs) =>
  (node) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const ref of refs) {
      if (ref) ref.current = node;
    }
  };

export default mergeRefs;
