import { ComponentPropsWithoutRef, forwardRef, PropsWithChildren } from 'react';
import NextLink from 'next/link';

type LinkProps = PropsWithChildren<{
  external?: boolean;
}> &
  ComponentPropsWithoutRef<'a'>;

const Link = forwardRef<typeof Link | HTMLAnchorElement, LinkProps>(
  ({ href = '', external = false, children, ...props }, ref) => {
    const isExternal = external || !href.startsWith('/');

    return isExternal ? (
      <a href={href} rel="noopener noreferrer" target="_blank" ref={ref} {...props}>
        {children}
      </a>
    ) : (
      <NextLink ref={ref} href={href} {...props}>
        {children}
      </NextLink>
    );
  }
);

export default Link;
