import { PropsWithChildren } from 'react';
import cx from 'classnames';

import AppBar from 'components/common/AppBar';
import Link from 'components/common/Link';
import LogoColor from '../../svg/logo-color.svg';
import LogoKey from '../../svg/logo-key.svg';

type DefaultLayoutProps = PropsWithChildren<{
  showHeader?: boolean;
  className?: string;
}>;

const DefaultLayout = ({
  showHeader = false,
  children = null,
  className = '',
}: DefaultLayoutProps) => (
  <div className="bg-gray-100 min-h-screen flex flex-col">
    {showHeader && (
      <AppBar className="shrink-0 z-40 px-4 md:px-8 flex justify-center items-center">
        <Link href="/">
          <LogoColor className="h-[1.625rem] block" />
        </Link>
      </AppBar>
    )}
    <main className={cx('shrink-0 grow pt-8 md:pt-12', className)}>{children}</main>

    <footer className="bg-white md:bg-gray-100 shrink-0 py-8 md:py-12 text-center">
      <div className="container">
        <LogoKey className="h-[1.625rem] opacity-50 block mx-auto fill-gray-800" />
        <div className="text-center pt-4 md:pt-8">
          <p className="text-sm text-gray-500 max-w-sm mx-auto">
            Copyright &copy;{new Date().getFullYear()} • All rights reserved{' '}
          </p>
          <p className="text-sm text-gray-500 max-w-sm mx-auto">
            <Link
              href="/privacy"
              external
              className="underline hover:no-underline hover:text-gray-600"
            >
              Privacy Policy
            </Link>{' '}
            •{' '}
            <Link href="/tos" external className="underline hover:no-underline hover:text-gray-600">
              Terms of Service
            </Link>
          </p>
        </div>
      </div>
    </footer>
  </div>
);

export default DefaultLayout;
