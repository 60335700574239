// Convenience component that can be passed directly to @hookform/error-message's
// render function:
// <ErrorMessage errors={errors} name="field-name" render={FormValidationError} />
import PropTypes from 'prop-types';

import FormNote from './FormNote';

const FormValidationError = ({ message }) => <FormNote status="error">{message}</FormNote>;

FormValidationError.propTypes = { message: PropTypes.string.isRequired };

FormValidationError.defaultProps = {};

export default FormValidationError;
