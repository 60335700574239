import { forwardRef, useMemo, useCallback, Ref } from 'react';
import CurrencyInput from 'react-currency-input-field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faInfoCircle,
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { faDollarSign } from '@fortawesome/pro-solid-svg-icons';
import cx from 'classnames';

import { renderAppendedInputNode } from './FormInput';
import style from './FormInput.module.css';

type CurrencyInputValue = string | number;

type FormCurrencyInputProps = {
  onChange: (value: CurrencyInputValue) => void;
  status?: 'default' | 'error' | 'warning' | 'success' | 'info';
  disabled?: boolean;
  readOnly?: boolean;
  suffix?: NonEmptyReactNode | { iconName: string };
  allowDecimals?: boolean;
  decimalsLimit?: number;
  allowNegativeValue?: boolean;
  iconClassName?: string;
  className?: string;
  placeholder?: string;
};

const FormCurrencyInput = forwardRef(
  (
    {
      onChange,
      status = 'default',
      disabled = false,
      readOnly = false,
      suffix = null,
      allowDecimals = false,
      decimalsLimit = 2,
      allowNegativeValue = false,
      iconClassName = '',
      className = '',
      placeholder = '',
      ...props
    }: FormCurrencyInputProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const state = useMemo(() => {
      if (disabled) return 'disabled';
      if (readOnly) return 'readonly';
      return status;
    }, [disabled, readOnly, status]);

    const statusIcon = useMemo(() => {
      if (state === 'error') return faExclamationCircle;
      if (state === 'warning') return faExclamationTriangle;
      if (state === 'success') return faCheckCircle;
      if (state === 'info') return faInfoCircle;
      return null;
    }, [state]);

    const handleChange = useCallback(
      (newValue) => {
        if (!newValue) return onChange('');
        return onChange(newValue);
      },
      [onChange]
    );

    return (
      <div className={cx('flex items-center', style.base, style[state])}>
        <div
          className={cx(
            'shrink-0 px-4 border-r border-gray-400 flex justify-center items-center',
            style.statusColor,
            iconClassName
          )}
        >
          <FontAwesomeIcon icon={faDollarSign} size="1x" />
        </div>
        <CurrencyInput
          className={cx(
            'placeholder-gray-500 focus:outline-none w-full py-2 px-4 bg-transparent',
            className
          )}
          disabled={disabled}
          readOnly={readOnly}
          allowDecimals={allowDecimals}
          decimalsLimit={decimalsLimit}
          allowNegativeValue={allowNegativeValue}
          onValueChange={handleChange}
          ref={ref}
          {...props}
        />
        {(statusIcon ?? suffix) && (
          <div className={cx('shrink-0 pr-3 transition-colors duration-100', style.statusColor)}>
            {renderAppendedInputNode(statusIcon ?? suffix)}
          </div>
        )}
      </div>
    );
  }
);

FormCurrencyInput.displayName = 'FormCurrencyInput';

export default FormCurrencyInput;
