import PropTypes from 'prop-types';
import Big from 'big.js';

import { formatCurrency } from 'lib/formatters';
import { useCampaignPage } from 'context/CampaignPage';
import DonutChart from 'components/common/DonutChart';

const CampaignPageProgressDial = ({ goal, raised, pending }) => {
  const { theme } = useCampaignPage();

  const nGoal = Big(goal);
  const nRaised = Big(raised);
  const nPending = Big(pending ?? '0');
  const nTotal = nRaised.plus(nPending);

  return (
    <DonutChart
      primaryValue={Number(nRaised)}
      primaryColor={theme.secondary}
      secondaryValue={Number(nPending)}
      secondaryColor={theme.secondaryLight}
      targetValue={Number(nGoal)}
      statLabel={`Raised of ${formatCurrency(nGoal, { cents: 'never', abbreviateAfter: 1000000 })}`}
      statValue={formatCurrency(nTotal, { cents: 'never', abbreviateAfter: 1000000 })}
    />
  );
};

CampaignPageProgressDial.propTypes = {
  goal: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  raised: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pending: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

CampaignPageProgressDial.defaultProps = {
  raised: '0',
  pending: null,
};

export default CampaignPageProgressDial;
