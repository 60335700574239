import { createContext, useContext, useMemo, ElementType } from 'react';
import cx from 'classnames';

type AppBarContextType = {
  theme: 'light' | 'dark';
};

const AppBarContext = createContext<AppBarContextType | null>(null);

type AppBarProps<C extends ElementType> = PolymorphicComponentProp<
  C,
  {
    as?: C;
    className?: string;
    theme?: AppBarContextType['theme'];
  }
>;

const AppBar = <C extends ElementType = 'header'>({
  as,
  theme = 'light',
  className = '',
  children = null,
}: AppBarProps<C>) => {
  const Component = as || 'header';
  const ctx = useMemo(() => ({ theme }), [theme]);

  return (
    <AppBarContext.Provider value={ctx}>
      <Component
        className={cx(
          'shadow sticky top-0 h-20',
          {
            'bg-white': theme === 'light',
            'bg-gray-800': theme === 'dark',
          },
          className
        )}
      >
        {children}
      </Component>
    </AppBarContext.Provider>
  );
};

export default AppBar;

export const useAppBar = () => {
  const context = useContext(AppBarContext);
  if (context === null) throw new Error('useAppBar must be used within an AppBar');
  return context;
};
