/* eslint-disable no-param-reassign */
import transform from 'lodash/transform';
import camelCase from 'lodash/camelCase';
import isPlainObject from 'lodash/isPlainObject';

const camelCaseKeys = (obj) =>
  transform(obj, (result, value, key) => {
    const camelCasedKey = camelCase(key);

    if (isPlainObject(value)) {
      result[camelCasedKey] = camelCaseKeys(value);
    } else {
      result[camelCasedKey] = value;
    }
  });

export default camelCaseKeys;
