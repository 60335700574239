/* eslint-disable import/no-extraneous-dependencies */
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { from, split } from '@apollo/client/link/core';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { setContext } from '@apollo/client/link/context';
import { offsetLimitPagination } from '@apollo/client/utilities';
import { parseCookies } from 'nookies';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from 'apollo-utilities';

import config from 'config';

const createApolloClient = ({ initialState, ctx }) => {
  const httpLink = new BatchHttpLink({
    uri: config('/apollo/http/uri'),
    credentials: 'same-origin',
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: config('/apollo/ws/uri'),
      connectionParams: { 'Access-Control-Allow-Origin': '*' },
      keepAlive: 15000,
    })
  );

  const authLink = setContext((_, previousContext) => {
    const { token, tracking } = parseCookies(ctx);

    return {
      ...previousContext,
      headers: {
        ...previousContext.headers,
        ...(token ? { authorization: token } : {}),
        ...(tracking ? { 'x-tracking': tracking } : {}),
      },
    };
  });

  const terminatingLink = split(
    ({ query }) => {
      const mainDefinition = getMainDefinition(query);
      return (
        mainDefinition.kind === 'OperationDefinition' && mainDefinition.operation === 'subscription'
      );
    },
    wsLink,
    from([authLink, httpLink])
  );

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    ssrForceFetchDelay: 250,
    link: terminatingLink,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            messageService: { merge: true },
          },
        },

        AwardedAchievement: { keyFields: ['fundraiserAchievementId'] },

        Campaign: {
          fields: {
            activeFeatures: { merge: true },
            discountCodes: { merge: false },
            registrationTypes: { merge: false },
            stats: { merge: true },
            supportedFeatures: { merge: true },
            updates: {
              keyArgs: ['where', 'order'],
              merge(existing, incoming, { args }) {
                // Assume an offset of 0 if args.offset omitted.
                const offset = args?.offset ?? 0;

                const merged = existing && offset ? existing.slice(0) : [];
                if (args) {
                  // eslint-disable-next-line no-plusplus
                  for (let i = 0; i < incoming.length; i++) {
                    merged[offset + i] = incoming[i];
                  }
                } else {
                  // It's unusual (probably a mistake) for a paginated field not
                  // to receive any arguments, so you might prefer to throw an
                  // exception here, instead of recovering by appending incoming
                  // onto the existing array.
                  merged.push(...incoming);
                }
                return merged;
              },
            },
          },
        },

        // Prevents campaign pages from being cached on their own, and will
        // always be cached under their parent campaign entity. Helps prevent
        // cache issues between campaign & program index pages
        CampaignPage: { keyFields: false },

        Fundraiser: {
          fields: {
            donations: {
              keyArgs: ['order', 'where'],
              // This is copied from the utility described in the docs at the url
              // below. At our current Apollo version, it was not working during
              // the SSR pass, but we should be able to switch to it once the
              // library is upgraded.
              // https://apollographql.com/docs/react/pagination/offset-based
              merge(existing, incoming, { args }) {
                const merged = existing ? existing.slice(0) : [];
                if (args) {
                  const { offset = 0 } = args;
                  // eslint-disable-next-line no-plusplus
                  for (let i = 0; i < incoming.length; ++i) {
                    merged[offset + i] = incoming[i];
                  }
                } else {
                  // eslint-disable-next-line prefer-spread
                  merged.push.apply(merged, incoming);
                }
                return merged;
              },
            },
            performanceSettings: { merge: true },
            stats: { merge: true },
          },
        },

        Question: {
          fields: {
            options: { merge: false },
            assigningRegistrationTypes: { merge: false },
          },
        },

        Team: {
          fields: {
            donations: offsetLimitPagination(['order', 'where']),
            updates: offsetLimitPagination(['order', 'where']),
            performanceSettings: { merge: true },
            stats: { merge: true },
          },
        },
      },
    }).restore(initialState || {}),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });
};

export default createApolloClient;
