import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDidMount } from 'rooks';
import ReactGA from 'react-ga4';

import config from 'config';

const GoogleAnalytics = () => {
  const router = useRouter();
  const [initialized, setInitialized] = useState(false);

  useDidMount(() => {
    ReactGA.initialize(config('/google/analyticsTrackingId'));
    setInitialized(true);
  });

  useEffect(() => {
    if (initialized) ReactGA.send({ hitType: 'pageview', page: router.asPath });
  }, [initialized, router.asPath]);

  return null;
};

export default GoogleAnalytics;
