/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import { ReactNode, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import useBreakpoint from 'hooks/useBreakpoint';
import Popover from './Popover';
import Modal from './Modal';

type PopMenuProps = PropsWithNonEmptyChildren<{
  trigger: NonEmptyReactNode;
  title?: ReactNode;
  closeOnClick?: boolean;
  className?: string;
  disabled?: boolean;
  menuClassName?: string;
  positions?: ('top' | 'right' | 'bottom' | 'left')[];
  align?: 'start' | 'center' | 'end';
}>;

/**
 * Menu that displays as a modal on mobile devices and a
 * popover everywhere else
 */
const PopMenu = ({
  trigger,
  children,
  title = null,
  closeOnClick = false,
  className = '',
  disabled = false,
  menuClassName = 'min-w-3xs',
  positions = ['bottom'],
  align = 'end',
  ...popoverProps
}: PopMenuProps) => {
  const [show, setShow] = useState(false);
  const mobile = !useBreakpoint('sm');

  if (mobile) {
    return (
      <>
        <button
          type="button"
          className={cx('block', className)}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (disabled) return;
            setShow((prev) => !prev);
          }}
        >
          {trigger}
        </button>
        <Modal show={show} onHide={() => setShow(false)}>
          {title && (
            <header className="p-3 pb-0 lg:pb-3 flex items-center">
              <button
                type="button"
                className="flex w-10 h-10 justify-center items-center"
                onClick={() => setShow(false)}
              >
                <FontAwesomeIcon icon={faTimes} size="lg" fixedWidth />
              </button>
              <h2 className="grow text-center pr-10 text-lg font-medium">{title}</h2>
            </header>
          )}
          <div
            className="py-4"
            onClick={() => {
              if (closeOnClick) setShow(false);
            }}
          >
            {children}
          </div>
        </Modal>
      </>
    );
  }

  return (
    <Popover
      disabled={disabled}
      trigger={trigger}
      closeOnClick={closeOnClick}
      className={className}
      positions={positions}
      align={align}
      {...popoverProps}
    >
      <div
        className={cx(
          'border border-gray-200 py-3 shadow-xl rounded-lg bg-white mt-1',
          menuClassName
        )}
      >
        {children}
      </div>
    </Popover>
  );
};

export default PopMenu;
