import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import { gql, useQuery } from '@apollo/client';
import times from 'lodash/times';
import chunk from 'lodash/chunk';
import capitalize from 'lodash/capitalize';
import findIndex from 'lodash/findIndex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faArrowLeft,
  faArrowRight,
  faMoneyBill,
  faRunning,
} from '@fortawesome/pro-regular-svg-icons';
import SwipeableViews from 'react-swipeable-views';
import { motion } from 'framer-motion';
import orderBy from 'lodash/orderBy';
import cx from 'classnames';

import config from 'config';
import useBreakpoint from 'hooks/useBreakpoint';
import { useCampaignPage } from 'context/CampaignPage';
import clientOnly from 'components/clientOnly';
import { parseNumber, formatNumber, formatCurrency } from 'lib/formatters';
import Button from 'components/common/Button';
import PopMenu from 'components/common/PopMenu';
import ContextMenu from 'components/common/ContextMenu';
import ContextMenuLink from 'components/common/ContextMenuLink';
import CampaignLeaderboard from 'components/common/CampaignLeaderboard';
import Card from 'components/common/Card';
import LazyView from 'components/common/LazyView';
import * as Skeleton from 'components/common/Skeleton';
import Tiles from 'components/common/Tiles';
import EmptyLeaderboard from '../../svg/empty-leaderboard.svg';

export const GET_LEADERBOARD_GROUPS = gql`
  query GetLeaderboardGroups($id: String!, $where: SequelizeJSON) {
    findCampaigns(id: $id) {
      id
      fundraiserDefaultHeaderAvatarImage
      isMetricLabelLocked
      isPerformanceEnabled
      metricLabelPlural

      widgets(where: $where) {
        id
        title
        config
        ... on GroupLeaderboardCampaignWidget {
          groups(order: "reverse:estimatedAmountRaised") {
            id
            name
            performanceUnits
            estimatedAmountRaised
          }
        }
      }
    }
  }
`;

const GET_FUNDRAISERS = gql`
  query GetGroupLeaderboard(
    $id: String!
    $groupId: String!
    $fundraiserSort: String!
    $limit: Int
  ) {
    findCampaignWidgets(id: $id) {
      id
      ... on GroupLeaderboardCampaignWidget {
        groups(id: $groupId) {
          fundraisers(order: $fundraiserSort, limit: $limit) {
            id
            type
            resolvedName
            avatarImage
            estimatedAmountRaised
            aggregatedPerformanceResult

            team {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const CompetitiveGroup = ({
  group,
  index,
  showRank,
  viewMetric,
  isSelected,
  select,
  className,
}) => {
  const metric = useMemo(() => {
    if (viewMetric === 'raised') {
      if (group.estimatedAmountRaised > 0) {
        return formatCurrency(group.estimatedAmountRaised, { cents: 'never' });
      }
      return <span>&mdash;</span>;
    }
    if (group.performanceUnits > 0) {
      return formatNumber(group.performanceUnits, '0,0.[00]');
    }
    return <span>&mdash;</span>;
  }, [viewMetric, group.estimatedAmountRaised, group.performanceUnits]);

  return (
    <motion.div key={group.id} onClick={() => select(group.id)}>
      <button type="button" className={className}>
        {showRank && (
          <div
            className={cx(
              {
                'bg-gray-300 text-gray-600': !isSelected,
                'bg-theme-primary-light text-white': isSelected,
              },
              'rounded-full h-6 w-6 flex items-center justify-center font-medium'
            )}
          >
            {index + 1}
          </div>
        )}
        <div
          className={cx(
            {
              'text-gray-600': !isSelected,
              'text-white opacity-80': isSelected,
            },
            'font-medium mt-2 w-11/12 truncate'
          )}
        >
          <span className="truncate">{group.name}</span>
        </div>
        <div
          className={cx({ 'text-white': isSelected }, 'font-medium leading-3 mt-2 mb-3 text-2xl')}
        >
          {metric}
        </div>
      </button>
    </motion.div>
  );
};

CompetitiveGroup.propTypes = {
  group: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    estimatedAmountRaised: PropTypes.string,
    performanceUnits: PropTypes.number,
  }).isRequired,
  index: PropTypes.number.isRequired,
  showRank: PropTypes.bool.isRequired,
  viewMetric: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  select: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

const CampaignPageGroupLeaderboards = ({
  id,
  limit,
  initialMetric,
  initialSelectedGroupId,
  showViewAll,
  showFundraiserRank,
  className,
}) => {
  const { campaignId } = useCampaignPage();
  const mdScreen = useBreakpoint('md');
  const lgScreen = useBreakpoint('lg');

  let screenSize = 'sm';
  if (mdScreen) screenSize = 'md';
  if (lgScreen) screenSize = 'lg';

  const groupsInCollection = {
    sm: 1,
    md: 3,
    lg: 5,
  }[screenSize];

  const [index, setIndex] = useState(0);
  const [viewMetric, setViewMetric] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(initialSelectedGroupId);

  const { data } = useQuery(GET_LEADERBOARD_GROUPS, {
    variables: { id: campaignId, where: { id } },
  });
  const campaign = useMemo(() => data?.findCampaigns?.[0], [data]);
  const leaderboard = useMemo(() => campaign?.widgets?.[0], [campaign]);
  const groups = useMemo(() => leaderboard?.groups, [leaderboard]);

  const sortedGroups = useMemo(() => {
    if (!viewMetric) return null;
    if (groups.length === 0) return null;
    return viewMetric === 'performance'
      ? orderBy(groups, [(x) => x.performanceUnits ?? 0], ['desc'])
      : groups;
  }, [groups, viewMetric]);

  const { data: fundraiserData, loading } = useQuery(GET_FUNDRAISERS, {
    variables: {
      id,
      groupId: selectedGroup,
      fundraiserSort: `reverse:${
        viewMetric === 'raised' ? 'estimatedAmountRaised' : 'aggregatedPerformanceResult'
      }`,
      limit: limit ?? undefined,
    },
    skip: !selectedGroup,
  });
  const fundraisers = useMemo(
    () => fundraiserData?.findCampaignWidgets?.[0]?.groups?.[0]?.fundraisers ?? [],
    [fundraiserData]
  );

  const groupCollections = useMemo(
    () => chunk(sortedGroups, groupsInCollection),
    [groupsInCollection, sortedGroups]
  );

  // Set the view metric once the leaderboard data has loaded
  useEffect(() => {
    if (!leaderboard || !campaign) return;
    setViewMetric(
      !campaign.isMetricLabelLocked ? 'raised' : (initialMetric ?? leaderboard?.config.defaultView)
    );
  }, [leaderboard, campaign, initialMetric, setViewMetric]);

  // Auto-select the first group if none were previously selected
  useEffect(() => {
    if (!sortedGroups || selectedGroup) return;
    setSelectedGroup(sortedGroups[0].id);
  }, [sortedGroups, selectedGroup]);

  // Ensure the collection index includes the selectedGroup
  useEffect(() => {
    if (!sortedGroups || !selectedGroup) return;
    const activeGroupIndex = findIndex(sortedGroups, (x) => x.id === selectedGroup);
    setIndex(Math.floor(activeGroupIndex / groupsInCollection));
  }, [selectedGroup, groupsInCollection, sortedGroups]);

  const leaderboardData = useMemo(
    () =>
      fundraisers.map((x) => ({
        id: x.id,
        image:
          x.avatarImage ??
          campaign?.fundraiserDefaultHeaderAvatarImage ??
          config('/defaultFundraiserAvatar'),
        name: x.resolvedName,
        description: x.team?.name,
        raised: parseNumber(x.estimatedAmountRaised) ?? 0,
        performance: parseNumber(x.aggregatedPerformanceResult) ?? 0,
        isFundraiser: x.type === 'fundraiser',
      })),
    [campaign?.fundraiserDefaultHeaderAvatarImage, fundraisers]
  );

  const performanceLabel = capitalize(campaign?.metricLabelPlural);
  if (!leaderboard || !sortedGroups) return null;

  return (
    <section className={cx('py-12 xl:py-16 bg-white', className)}>
      <div className="container max-w-7xl">
        <div className="flex flex-col md:flex-row gap-x-8 gap-y-4 justify-center md:justify-between items-center">
          <h2 className="text-center md:text-left text-3xl font-medium leading-tight">
            {leaderboard.title}
          </h2>
          {campaign.isPerformanceEnabled && campaign.isMetricLabelLocked && (
            <PopMenu
              title="View Metric"
              closeOnClick
              trigger={
                <Button
                  as="span"
                  color={null}
                  className="bg-gray-200 hover:bg-gray-300 active:bg-gray-300"
                >
                  <div className="flex gap-x-4 items-center">
                    <p className="font-medium text-left whitespace-nowrap">
                      {viewMetric === 'raised' ? 'Amount Raised' : performanceLabel}
                    </p>
                    {campaign.isPerformanceEnabled && campaign.isMetricLabelLocked && (
                      <FontAwesomeIcon icon={faAngleDown} size="1x" />
                    )}
                  </div>
                </Button>
              }
            >
              <ContextMenu>
                <ContextMenuLink
                  as="span"
                  label="Amount Raised"
                  theme="bordered"
                  onClick={() => setViewMetric('raised')}
                  icon={faMoneyBill}
                  isActive={viewMetric === 'raised'}
                  className="cursor-pointer"
                />
                <ContextMenuLink
                  as="span"
                  label={performanceLabel}
                  theme="bordered"
                  onClick={() => setViewMetric('performance')}
                  icon={faRunning}
                  isActive={viewMetric === 'performance'}
                  className="cursor-pointer"
                />
              </ContextMenu>
            </PopMenu>
          )}
        </div>
        <div className="my-6 h-px bg-gray-300" />
      </div>
      <div className="md:container pb-6 md:pb-12 xxl:max-w-7xl">
        <div className="relative">
          <SwipeableViews
            key={screenSize}
            index={index}
            className={cx(!mdScreen && 'pl-3 pr-36')}
            slideClassName={cx(!mdScreen && 'pl-3')}
          >
            {groupCollections.map((collection, x) => (
              <div
                key={`collection-${collection[0].id}`}
                className={cx({
                  'grid gap-x-3': mdScreen,
                  'grid-cols-3': screenSize === 'md',
                  'grid-cols-5': screenSize === 'lg',
                })}
              >
                {collection.map((group, y) => (
                  <CompetitiveGroup
                    key={group.id}
                    index={x * groupsInCollection + y}
                    showRank={screenSize !== 'sm'}
                    group={group}
                    viewMetric={viewMetric}
                    isSelected={selectedGroup === group.id}
                    select={setSelectedGroup}
                    className={cx(
                      'flex flex-col w-full py-4 px-5 rounded-xl transition-color duration-200 font-medium justify-start items-left text-left border',
                      {
                        'bg-white border-gray-300  text-gray-800 hover:border-gray-400 hover:shadow-md':
                          selectedGroup !== group.id,
                        'border-theme-primary bg-theme-primary text-white':
                          selectedGroup === group.id,
                      }
                    )}
                  />
                ))}
              </div>
            ))}
          </SwipeableViews>
          {mdScreen && groupCollections.length > 0 && (
            <>
              {index > 0 && (
                <button
                  type="button"
                  onClick={() => setIndex(index - 1)}
                  className="flex justify-center items-center absolute top-1/2 -left-4 rounded-full border border-gray-400 bg-white hover:bg-gray-300 h-8 w-8 -mt-4"
                >
                  <FontAwesomeIcon icon={faArrowLeft} size="1x" className="text-gray-800" />
                </button>
              )}
              {index < groupCollections.length - 1 && (
                <button
                  type="button"
                  onClick={() => setIndex(index + 1)}
                  className="flex justify-center items-center absolute top-1/2 -right-4 rounded-full border border-gray-400 bg-white hover:bg-gray-300 h-8 w-8 -mt-4"
                >
                  <FontAwesomeIcon icon={faArrowRight} size="1x" className="text-gray-800" />
                </button>
              )}
            </>
          )}
        </div>
      </div>

      <motion.div
        key={loading ? 'loading' : selectedGroup}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2 }}
        className="px-6 md:container max-w-7xl"
      >
        <LazyView
          fallback={
            <Skeleton.Wrapper className="container">
              <Tiles columns={1} spacing="md">
                <div>
                  <Skeleton.Text className="w-16 mt-6 mb-4" />
                  <Tiles columns={1} spacing="sm">
                    {times(5).map((y) => (
                      <Card
                        key={y}
                        className="border border-gray-400 px-4 py-3"
                        depth="none"
                        padding="none"
                      >
                        <Tiles spacing="md">
                          <Skeleton.Circle />
                          <Tiles columns={1} spacing="xs">
                            <Skeleton.Text className="w-24" />
                            <Skeleton.Text className="w-40" />
                          </Tiles>
                        </Tiles>
                      </Card>
                    ))}
                  </Tiles>
                </div>
              </Tiles>
            </Skeleton.Wrapper>
          }
        >
          <div className="container max-w-7xl">
            {selectedGroup &&
              !loading &&
              (fundraisers.length === 0 ? (
                <div className="flex flex-col justify-start items-center w-full border border-gray-300 rounded-lg py-12">
                  <EmptyLeaderboard className="block mx-auto w-36 max-w-36" />
                  <p className="font-medium text-center my-6">No fundraisers yet.</p>
                </div>
              ) : (
                <div className="mx-auto">
                  <CampaignLeaderboard
                    type="individual"
                    display={viewMetric}
                    performanceLabel={performanceLabel}
                    data={leaderboardData}
                    onClick={(x) => {
                      Router.push(`/f/${x.id}`);
                    }}
                    limit={limit}
                    showRank={showFundraiserRank}
                  />
                  {showViewAll && (
                    <div className="mt-8 text-center">
                      <Button
                        href={`/c/${campaign.id}/fundraiser-groups?sortBy=${viewMetric}&leaderboardId=${id}&groupId=${selectedGroup}`}
                        color="gray-300"
                        className="font-medium rounded-full"
                        outline
                      >
                        View All
                      </Button>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </LazyView>
      </motion.div>
    </section>
  );
};

CampaignPageGroupLeaderboards.propTypes = {
  id: PropTypes.string.isRequired,
  limit: PropTypes.number,
  initialMetric: PropTypes.string,
  initialSelectedGroupId: PropTypes.string,
  showViewAll: PropTypes.bool,
  showFundraiserRank: PropTypes.bool,
  className: PropTypes.string,
};

CampaignPageGroupLeaderboards.defaultProps = {
  limit: null,
  initialMetric: null,
  initialSelectedGroupId: null,
  showViewAll: false,
  showFundraiserRank: false,
  className: '',
};

export default clientOnly(CampaignPageGroupLeaderboards);
