import PropTypes from 'prop-types';
import { useState, useCallback, useMemo } from 'react';
import { useSlate } from 'slate-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faQuoteRight } from '@fortawesome/pro-solid-svg-icons';
import {
  faBold,
  faItalic,
  faUnderline,
  faListUl,
  faListOl,
  faLink,
} from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import Editor from 'lib/CustomSlateEditor';
import HorizontalScrollContainer from 'components/common/HorizontalScrollContainer';
import PopMenu from 'components/common/PopMenu';
import ContextMenu from 'components/common/ContextMenu';
import ContextMenuLink from 'components/common/ContextMenuLink';
import ToolbarButton from './FormTextEditorToolbarButton';
import LinkModal from './FormTextEditorLinkModal';
import ButtonModal from './FormTextEditorButtonModal';

const ELEMENT_TYPES = {
  title: 'Title',
  subtitle: 'Subtitle',
  paragraph: 'Normal',
};

const LIST_TYPES = {
  'bulleted-list': faListUl,
  'numbered-list': faListOl,
};

const FormTextEditorToolbar = ({ capabilities }) => {
  const editor = useSlate();
  const [showModal, setShowModal] = useState(null);

  let activeElement = 'paragraph';
  if (Editor.isElementActive(editor, 'title')) activeElement = 'title';
  if (Editor.isElementActive(editor, 'subtitle')) activeElement = 'subtitle';

  const MARK_TYPES = useMemo(() => {
    const obj = {};
    if (capabilities.includes('bold')) obj.bold = faBold;
    if (capabilities.includes('italic')) obj.italic = faItalic;
    if (capabilities.includes('underline')) obj.underline = faUnderline;
    return obj;
  }, [capabilities]);

  const show = useMemo(() => {
    const hasMarks = Object.keys(MARK_TYPES).length > 0;
    const hasLists = capabilities.includes('lists');
    const hasBasicElements = ['quote', 'link'].some((x) => capabilities.includes(x));
    const hasInsertables = ['button', 'divider'].some((x) => capabilities.includes(x));

    return {
      insertMenu: hasInsertables,
      firstDivider: hasMarks && (hasLists || hasBasicElements || hasInsertables),
      secondDivider: hasBasicElements && hasInsertables,
    };
  }, [capabilities, MARK_TYPES]);

  const hideModal = useCallback(() => {
    Editor.focus(editor);
    setShowModal(null);
  }, [editor]);

  return (
    <>
      {showModal === 'link' && <LinkModal onHide={hideModal} />}
      {showModal === 'button' && <ButtonModal onHide={hideModal} />}

      <HorizontalScrollContainer
        className={cx('py-2 px-3 flex items-center', {
          'opacity-50 pointer-events-none': Editor.isElementActive(editor, 'button'),
        })}
      >
        <div className="flex items-center gap-x-3">
          {capabilities.includes('titles') && (
            <PopMenu
              positions={['bottom']}
              align="start"
              menuClassName="w-40"
              trigger={
                <div className="px-3 py-1 w-32 border border-gray-400 hover:border-gray-500 rounded-md flex items-center justify-between">
                  <p className="text-sm">{ELEMENT_TYPES[activeElement]}</p>
                  <FontAwesomeIcon icon={faCaretDown} size="sm" />
                </div>
              }
              closeOnClick
            >
              <ContextMenu>
                {Object.entries(ELEMENT_TYPES).map(([type, label]) => (
                  <ContextMenuLink
                    key={type}
                    as="button"
                    type="button"
                    onClick={() => {
                      Editor.setElementType(editor, type);
                      Editor.focus(editor);
                    }}
                    label={label}
                    theme="bordered"
                  />
                ))}
              </ContextMenu>
            </PopMenu>
          )}
          <div className="flex items-center gap-x-1">
            {Object.entries(MARK_TYPES).map(([type, icon]) => (
              <ToolbarButton
                key={type}
                icon={icon}
                isActive={Editor.isMarkActive(editor, type)}
                onClick={() => {
                  Editor.toggleMark(editor, type);
                  Editor.focus(editor);
                }}
              />
            ))}

            {show.firstDivider && <div className="w-px h-6 bg-gray-400 mx-1" />}

            {capabilities.includes('lists') &&
              Object.entries(LIST_TYPES).map(([type, icon]) => (
                <ToolbarButton
                  key={type}
                  icon={icon}
                  isActive={Editor.isElementActive(editor, type)}
                  onClick={() => {
                    Editor.toggleList(editor, type);
                    Editor.focus(editor);
                  }}
                />
              ))}

            {show.secondDivider && <div className="w-px h-6 bg-gray-400 mx-1" />}

            {capabilities.includes('quote') && (
              <ToolbarButton
                icon={faQuoteRight}
                isActive={Editor.isElementActive(editor, 'block-quote')}
                onClick={() => {
                  Editor.toggleBlockQuote(editor);
                  Editor.focus(editor);
                }}
              />
            )}
            {capabilities.includes('link') && (
              <ToolbarButton
                icon={faLink}
                isActive={Editor.isElementActive(editor, 'link')}
                onClick={() => setShowModal('link')}
              />
            )}
            {show.insertMenu && (
              <PopMenu
                positions={['bottom']}
                align="end"
                menuClassName="w-40"
                trigger={
                  <div className="group font-medium text-gray-700 flex items-center gap-x-2 px-2 py-1 hover:text-gray-800 hover:bg-gray-300 transition-colors duration-200 rounded-md">
                    <p className="text-sm">Insert</p>
                    <FontAwesomeIcon
                      icon={faCaretDown}
                      size="sm"
                      className="text-gray-500 group-hover:text-gray-600 transition-colors duration-200"
                    />
                  </div>
                }
                closeOnClick
              >
                <ContextMenu>
                  {capabilities.includes('button') && (
                    <ContextMenuLink
                      as="button"
                      type="button"
                      onClick={() => setShowModal('button')}
                      label="Button"
                      theme="bordered"
                    />
                  )}
                  {capabilities.includes('divider') && (
                    <ContextMenuLink
                      as="button"
                      type="button"
                      onClick={() => {
                        Editor.insertHorizontalRule(editor);
                        Editor.focus(editor);
                      }}
                      label="Horizontal line"
                      theme="bordered"
                    />
                  )}
                </ContextMenu>
              </PopMenu>
            )}
          </div>
        </div>
      </HorizontalScrollContainer>
    </>
  );
};

FormTextEditorToolbar.propTypes = {
  capabilities: PropTypes.arrayOf(PropTypes.string).isRequired,
};

FormTextEditorToolbar.defaultProps = {};

export default FormTextEditorToolbar;
