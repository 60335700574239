import { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUpload, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { faCamera } from '@fortawesome/pro-solid-svg-icons';
import { useDidMount } from 'rooks';
import cx from 'classnames';

import config from 'config';
import useCloudinaryUploader from 'hooks/useCloudinaryUploader';
import useToasts from 'hooks/useToasts';
import PopMenu from 'components/common/PopMenu';
import ContextMenu from 'components/common/ContextMenu';
import ContextMenuLink from 'components/common/ContextMenuLink';
import style from './FormImageUploader.module.css';

const FormImageUploader = forwardRef(
  (
    {
      value,
      onChange,
      placeholder,
      preview: Preview,
      editLabel,
      editIcon,
      editIconSize,
      tags,
      openOnMount,
      allowCrop,
      aspectRatio,
      maxFileSize,
      maxHeight,
      maxWidth,
      minHeight,
      minWidth,
      returnValue,
      showEditIndicator,
      onAbort,
      showRemove,
      align,
      positions,
      className,
    },
    ref
  ) => {
    const toast = useToasts('image:upload');

    const showUploader = useCloudinaryUploader({
      uploaderOptions: {
        croppingDefaultSelectionRatio: 0.75,
        croppingCoordinatesMode: 'custom',
        tags,
        cropping: allowCrop,
        showSkipCropButton: !aspectRatio,
        croppingAspectRatio: aspectRatio,
        maxFileSize,
        maxImageHeight: maxHeight,
        maxImageWidth: maxWidth,
        minImageHeight: minHeight,
        minImageWidth: minWidth,
      },
      onUpload: (result) => onChange(returnValue === 'id' ? result.public_id : result),
      onError: (error) => {
        toast.error('There was an issue uploading your image. Please try again.');
        if (config('/debug')) console.error(error);
      },
      onAbort,
    });

    // Open the widget on mount if configured
    useDidMount(() => {
      if (openOnMount) showUploader();
    });

    const image = value || placeholder;

    const editNode = useMemo(
      () => (
        <span
          className={cx(
            'absolute bottom-0 right-0 font-medium bg-white flex items-center shadow-lg transition-colors duration-200',
            {
              'rounded-full ': !editLabel,
              'rounded-lg px-2 mb-6 mr-6': editLabel,
            },
            style.editButton
          )}
        >
          <span
            className={cx('flex justify-center items-center', {
              'w-8 h-8': editIconSize !== 'sm',
              'w-6 h-6': editIconSize === 'sm',
            })}
          >
            <FontAwesomeIcon icon={editIcon} size={editIconSize} />
          </span>
          {editLabel && <span className="block ml-1">Edit</span>}
        </span>
      ),
      [editLabel, editIcon, editIconSize]
    );

    return (
      <div className="flex justify-center" ref={ref}>
        <div className={cx('relative', className, style.wrapper)}>
          {value ? (
            <PopMenu
              title="Edit Image"
              trigger={
                <>
                  <Preview image={image} />
                  {editNode}
                </>
              }
              align={align}
              positions={positions}
              closeOnClick
            >
              <ContextMenu className="px-3">
                <ContextMenuLink
                  as="button"
                  type="button"
                  label="Upload Photo"
                  icon={faCloudUpload}
                  theme="rounded"
                  onClick={showUploader}
                />
                {showRemove && (
                  <ContextMenuLink
                    as="button"
                    type="button"
                    label="Remove"
                    icon={faTrash}
                    theme="rounded"
                    onClick={() => onChange(null)}
                  />
                )}
              </ContextMenu>
            </PopMenu>
          ) : (
            <button
              type="button"
              onClick={showUploader}
              className={cx('group block relative', className, style.wrapper)}
            >
              <Preview image={image} />
              {showEditIndicator && editNode}
            </button>
          )}
        </div>
      </div>
    );
  }
);

FormImageUploader.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  preview: PropTypes.func.isRequired,
  showEditIndicator: PropTypes.bool,
  editLabel: PropTypes.bool,
  editIcon: PropTypes.shape({ iconName: PropTypes.string.isRequired }),
  editIconSize: PropTypes.oneOf(['sm', '1x', 'lg']),
  value: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  openOnMount: PropTypes.bool,
  allowCrop: PropTypes.bool,
  aspectRatio: PropTypes.number,
  maxFileSize: PropTypes.number,
  maxHeight: PropTypes.number,
  maxWidth: PropTypes.number,
  minHeight: PropTypes.number,
  minWidth: PropTypes.number,
  returnValue: PropTypes.oneOf(['id', 'file']),
  onAbort: PropTypes.func,
  showRemove: PropTypes.bool,
  positions: PropTypes.arrayOf(PropTypes.string),
  align: PropTypes.string,
  className: PropTypes.string,
};

FormImageUploader.defaultProps = {
  placeholder: config('/defaultPlaceholderImage'),
  showEditIndicator: true,
  editLabel: false,
  editIcon: faCamera,
  editIconSize: '1x',
  value: '',
  tags: null,
  openOnMount: false,
  allowCrop: false,
  aspectRatio: null,
  maxFileSize: null,
  maxHeight: null,
  maxWidth: null,
  minHeight: null,
  minWidth: null,
  returnValue: 'id',
  onAbort: () => {},
  showRemove: true,
  positions: ['bottom', 'top'],
  align: 'start',
  className: '',
};

export default FormImageUploader;
