import { useRef, useState, useCallback, PropsWithChildren } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { useDidMount } from 'rooks';
import cx from 'classnames';

type HorizontalScrollContainerProps = PropsWithNonEmptyChildren<{
  theme?: 'light' | 'dark';
  withArrows?: boolean;
  className?: string;
}>;

const HorizontalScrollContainer = ({
  children,
  theme = 'light',
  withArrows = false,
  className = '',
}: HorizontalScrollContainerProps) => {
  const scrollElement = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  const checkScroll = useCallback(() => {
    const { scrollWidth, scrollLeft, offsetWidth } = scrollElement.current;

    if (scrollWidth <= offsetWidth) return;

    const leftCheck = scrollLeft > 0;
    if (leftCheck !== canScrollLeft) setCanScrollLeft(leftCheck);

    const rightCheck = scrollWidth - scrollLeft - 12 > offsetWidth;
    if (rightCheck !== canScrollRight) setCanScrollRight(rightCheck);
  }, [canScrollLeft, canScrollRight]);

  useDidMount(checkScroll);

  return (
    <div className="relative">
      <div
        className={cx(
          'absolute inset-y-0 left-0 w-16 pointer-events-none transition-opacity duration-200 bg-gradient-to-l from-transparent',
          {
            'opacity-0': !canScrollLeft,
            'opacity-100': canScrollLeft,
            'to-white': theme === 'light',
            'to-gray-800': theme === 'dark',
          }
        )}
      />
      {withArrows && (
        <div
          className={cx('absolute inset-y-1 left-0 transition-opacity duration-200', {
            'opacity-0': !canScrollLeft,
            'opacity-100': canScrollLeft,
          })}
          role="presentation"
          onClick={() => {
            scrollElement.current.scrollLeft -= scrollElement.current.clientWidth - 100;
            checkScroll();
          }}
        >
          <div className="flex items-center justify-center h-8 w-8 rounded-full border border-gray-500 bg-white shadow-sm cursor-pointer">
            <FontAwesomeIcon icon={faChevronLeft} size="sm" />
          </div>
        </div>
      )}
      <div
        className={cx(
          'absolute inset-y-0 right-0 w-16 pointer-events-none transition-opacity duration-200 bg-gradient-to-r from-transparent',
          {
            'opacity-0': !canScrollRight,
            'opacity-100': canScrollRight,
            'to-white': theme === 'light',
            'to-gray-800': theme === 'dark',
          }
        )}
      />
      {withArrows && (
        <div
          className={cx('absolute inset-y-1 right-0 transition-opacity duration-200', {
            'opacity-0': !canScrollRight,
            'opacity-100': canScrollRight,
          })}
          role="presentation"
          onClick={() => {
            scrollElement.current.scrollLeft += scrollElement.current.clientWidth - 100;
            checkScroll();
          }}
        >
          <div className="flex items-center justify-center h-8 w-8 rounded-full border border-gray-500 bg-white shadow-sm cursor-pointer">
            <FontAwesomeIcon icon={faChevronRight} size="sm" />
          </div>
        </div>
      )}
      <div
        ref={scrollElement}
        onScroll={checkScroll}
        className={cx('overflow-auto hide-scroll scroll-smooth', className)}
      >
        {children}
      </div>
    </div>
  );
};

export default HorizontalScrollContainer;
