import { Children, ElementType } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import style from './ToggleMenu.module.css';

const ToggleMenu = <C extends ElementType = 'nav'>({
  as,
  className,
  children,
}: PolymorphicComponentProp<C, {}>) => {
  const Component = as || 'nav';
  return (
    <Component
      className={cx(
        'flex rounded-xl p-1 bg-gray-200 gap-x-1',
        style[`items-${Children.toArray(children).length}`],
        className
      )}
    >
      {children}
    </Component>
  );
};

export default ToggleMenu;
