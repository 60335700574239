import { useState, useMemo, useEffect, ComponentProps } from 'react';
import { useRouter } from 'next/router';
import { gql, useQuery } from '@apollo/client';

import config from 'config';
import useBreakpoint from 'hooks/useBreakpoint';
import useScrollLock from 'hooks/useScrollLock';
import AppBarSearch from 'components/common/AppBarSearch';
import SearchEmptyState from 'components/common/SearchEmptyState';
import SearchSection from 'components/common/SearchSection';
import SearchTitle from 'components/common/SearchTitle';
import SearchResultList from 'components/common/SearchResultList';
import SearchResult from 'components/common/SearchResult';

const GET_RESULTS = gql`
  query GetResults($id: String!, $query: String!) {
    findCampaigns(id: $id) {
      id
      fundraiserDefaultHeaderAvatarImage
      teamDefaultHeaderAvatarImage

      searchTeams(query: $query, limit: 7) {
        id
        avatarImage
        name
      }

      searchFundraisers(query: $query, limit: 7) {
        id
        resolvedName
        avatarImage
        teamName
      }
    }
  }
`;

type CampaignSearchProps = {
  id: string;
  theme?: ComponentProps<typeof AppBarSearch>['buttonTheme'];
  onFocusChange?: (isFocused: boolean) => void;
} & Pick<ComponentProps<typeof AppBarSearch>, 'buttonClassName' | 'largeScreenDefaultDisplay'>;

const CampaignSearch = ({
  id,
  buttonClassName = '',
  theme = 'light',
  onFocusChange = () => {},
  largeScreenDefaultDisplay,
}: CampaignSearchProps) => {
  const mobile = !useBreakpoint('lg');
  const router = useRouter();

  const [isFocused, setIsFocused] = useState(false);
  const [expanded, setExpanded] = useState([]);
  const [query, setQuery] = useState('');

  useScrollLock(isFocused && mobile);

  const { data, loading } = useQuery(GET_RESULTS, {
    variables: { id, query },
    skip: !query,
  });

  const campaign = useMemo(() => data?.findCampaigns?.[0], [data]);
  const results = useMemo(
    () => ({
      teams: campaign?.searchTeams ?? [],
      fundraisers: campaign?.searchFundraisers ?? [],
    }),
    [campaign]
  );

  const hasTeams = useMemo(() => results.teams.length > 0, [results]);
  const hasFundraisers = useMemo(() => results.fundraisers.length > 0, [results]);
  const hasResults = useMemo(() => hasTeams || hasFundraisers, [hasTeams, hasFundraisers]);
  const showResults = useMemo(() => isFocused && query && !loading, [isFocused, query, loading]);

  const showTeams = useMemo(() => {
    if (!hasTeams) return false;
    return mobile ? true : expanded.length === 0 || expanded.includes('teams');
  }, [hasTeams, expanded, mobile]);

  const showFundraisers = useMemo(() => {
    if (!hasFundraisers) return false;
    return mobile ? true : expanded.length === 0 || expanded.includes('fundraisers');
  }, [hasFundraisers, expanded, mobile]);

  useEffect(() => onFocusChange(isFocused), [isFocused, onFocusChange]);

  // Close the search bar whenever navigation occurs
  useEffect(() => setIsFocused(false), [router.asPath]);

  return (
    <AppBarSearch
      value={query}
      isFocused={isFocused}
      onChange={setQuery}
      buttonTheme={theme}
      buttonClassName={buttonClassName}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      largeScreenDefaultDisplay={largeScreenDefaultDisplay}
    >
      {showResults && (
        <div>
          {!hasResults && (
            <SearchSection>
              <SearchEmptyState
                message={
                  <>
                    We couldn&apos;t find any teams or fundraisers related to{' '}
                    <strong className="font-medium">&ldquo;{query}&rdquo;</strong>
                  </>
                }
              />
            </SearchSection>
          )}
          {showTeams && (
            <SearchSection>
              <SearchTitle>Teams</SearchTitle>
              <SearchResultList
                limit={expanded.includes('teams') ? null : 3}
                showMoreLabel="View more teams"
                showLessLabel={mobile ? null : 'View fewer teams'}
                onShowMore={() => setExpanded([...expanded, 'teams'])}
                onShowLess={() => setExpanded(expanded.filter((x) => x !== 'teams'))}
              >
                {results.teams.map((team) => (
                  <SearchResult
                    key={team.id}
                    image={
                      team.avatarImage ??
                      campaign.teamDefaultHeaderAvatarImage ??
                      config('/defaultTeamAvatar')
                    }
                    title={team.name}
                    highlight={query}
                    href={`/t/${team.id}`}
                  />
                ))}
              </SearchResultList>
            </SearchSection>
          )}
          {showFundraisers && (
            <SearchSection>
              <SearchTitle>Fundraisers</SearchTitle>
              <SearchResultList
                limit={expanded.includes('fundraisers') ? null : 3}
                showMoreLabel="View more fundraisers"
                showLessLabel={mobile ? null : 'View fewer fundraisers'}
                onShowMore={() => setExpanded([...expanded, 'fundraisers'])}
                onShowLess={() => setExpanded(expanded.filter((x) => x !== 'fundraisers'))}
              >
                {results.fundraisers.map((fundraiser) => (
                  <SearchResult
                    key={fundraiser.id}
                    image={
                      fundraiser.avatarImage ??
                      campaign.fundraiserDefaultHeaderAvatarImage ??
                      config('/defaultFundraiserAvatar')
                    }
                    title={fundraiser.resolvedName}
                    description={fundraiser.teamName}
                    highlight={query}
                    href={`/f/${fundraiser.id}`}
                  />
                ))}
              </SearchResultList>
            </SearchSection>
          )}
        </div>
      )}
    </AppBarSearch>
  );
};

export default CampaignSearch;
