import { createContext, useContext, Children, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import config from 'config';

const TabBarContext = createContext(null);

const TabBar = ({ children, className, inactiveColor, activeColor }) => {
  const items = Children.toArray(children);
  const ctx = useMemo(() => ({ inactiveColor, activeColor }), [inactiveColor, activeColor]);

  return (
    <TabBarContext.Provider value={ctx}>
      <div className={cx('border-t border-gray-300 shadow-2xl bg-white', className)}>
        <div className="max-w-full flex justify-center items-center h-24 pb-4">
          {items.map((item, i) => (
            <div
              key={`tabbaritem-${i}`}
              className="px-2 flex-1"
              style={{ flexBasis: `${100 / items.length}%` }}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
    </TabBarContext.Provider>
  );
};

TabBar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  inactiveColor: PropTypes.string,
  activeColor: PropTypes.string,
};

TabBar.defaultProps = {
  className: '',
  inactiveColor: config('/tw/colors/gray/500'),
  activeColor: config('/tw/colors/gray/800'),
};

export default TabBar;
export const useTabBar = () => useContext(TabBarContext);
