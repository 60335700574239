import { ElementType, ReactNode } from 'react';
import cx from 'classnames';

type NavbarTitleProps<C extends ElementType> = PolymorphicComponentProp<
  C,
  {
    title?: ReactNode;
    className?: string;
    theme?: 'light' | 'dark';
  }
>;

const NavbarTitle = <C extends ElementType = 'h2'>({
  as,
  title,
  className = '',
  theme = 'light',
  ...props
}: NavbarTitleProps<C>) => {
  const Component = as || 'h2';
  return (
    <Component
      className={cx(
        'text-lg font-medium truncate',
        {
          'text-gray-800': theme === 'light',
          'text-gray-300': theme === 'dark',
        },
        className
      )}
      {...props}
    >
      {title}
    </Component>
  );
};

export default NavbarTitle;
