import useIsClient from 'hooks/useIsClient';

const clientOnly = (OriginalComponent) => {
  const WrappedComponent = (props) => {
    const isClient = useIsClient();
    return isClient ? <OriginalComponent {...props} /> : null;
  };

  const displayName = OriginalComponent.displayName || OriginalComponent.name || 'Component';
  WrappedComponent.displayName = `clientOnly(${displayName})`;

  return WrappedComponent;
};

export default clientOnly;
