import PropTypes from 'prop-types';
import { useState } from 'react';

import useScreen from 'hooks/useScreen';
import useScrollLock from 'hooks/useScrollLock';
import { useCampaignPage } from 'context/CampaignPage';
import FundraiserAdminInviteSelection from './FundraiserAdminInviteSelection';
import FundraiserAdminInviteViaEmail from './FundraiserAdminInviteViaEmail';

const FundraiserAdminInviteModal = ({ show, onHide }) => {
  const screen = useScreen();
  const [view, setView] = useState('selection'); // selection, invite:email
  const { fundraiserId } = useCampaignPage();

  useScrollLock(!screen.md && view === 'invite:email');

  if (view === 'selection') {
    return (
      <FundraiserAdminInviteSelection
        fundraiserId={fundraiserId}
        onSelectEmail={() => setView('invite:email')}
        onHide={onHide}
        show={show}
      />
    );
  }

  if (view === 'invite:email') {
    return (
      <FundraiserAdminInviteViaEmail fundraiserId={fundraiserId} onHide={onHide} show={show} />
    );
  }

  return null;
};

FundraiserAdminInviteModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default FundraiserAdminInviteModal;
