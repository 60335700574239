import PropTypes from 'prop-types';
import { useMutation, gql } from '@apollo/client';
import { useDidMount } from 'rooks';
import { Image } from 'cloudinary-react';

import { formatNumber } from 'lib/formatters';
import Button from 'components/common/Button';

const MILESTONES = {
  25: {
    author: 'Amelia Earhart',
    quote: 'The most difficult thing is the decision to act, the rest is merely tenacity.',
  },
  50: {
    author: 'Theodore Roosevelt',
    quote: "Believe you can and you're halfway there.",
  },
  75: {
    author: 'Michael Jordan',
    quote: 'You have to expect great things of yourself before you can do them.',
  },
};

const SEE_ACHIEVEMENT = gql`
  mutation SeeFundraiserAchievement($fundraiserAchievementId: String!) {
    seeFundraiserAchievement(fundraiserAchievementId: $fundraiserAchievementId)
  }
`;

const FundraiserAdminMilestoneAchievementModal = ({
  achievementId,
  milestone,
  image,
  performanceEstimate,
  aggregatedPerformance,
  metricLabelPlural,
  onHide,
}) => {
  const [seeAchievement] = useMutation(SEE_ACHIEVEMENT);

  useDidMount(() => {
    seeAchievement({
      variables: {
        fundraiserAchievementId: achievementId,
      },
      update: async (cache) => {
        await cache.writeFragment({
          id: cache.identify({
            __typename: 'AwardedAchievement',
            fundraiserAchievementId: achievementId,
          }),
          fragment: gql`
            fragment seeAchievement on AwardedAchievement {
              seenAt
            }
          `,
          data: {
            __typename: 'AwardedAchievement',
            fundraiserAchievementId: achievementId,
            seenAt: new Date(),
          },
        });
      },
    });
  });

  return (
    <div className="flex flex-col text-center justify-center">
      <Image publicId={image} alt="" width={85} className="block mx-auto w-32" />
      <h2 className="font-medium text-3xl my-4 leading-tight">
        You reached {milestone}% of your {metricLabelPlural} goal!
      </h2>
      <p className="text-lg font-medium text-gray-500 mb-3">
        You recorded {formatNumber(aggregatedPerformance, '0,0')} of{' '}
        {formatNumber(performanceEstimate, '0,0')} {metricLabelPlural}{' '}
      </p>
      {milestone === 100 ? (
        <p className="mt-1 mb-5">
          There is still time left, go be an overachiever and record some more!
        </p>
      ) : (
        <>
          <p className="italic">{MILESTONES[milestone].quote}</p>
          <p className="italic mt-1 mb-5">- {MILESTONES[milestone].author}</p>
        </>
      )}
      <Button
        as="button"
        type="button"
        color="green"
        className="font-medium block w-full"
        onClick={onHide}
      >
        Done
      </Button>
    </div>
  );
};

FundraiserAdminMilestoneAchievementModal.propTypes = {
  achievementId: PropTypes.string.isRequired,
  milestone: PropTypes.number.isRequired,
  performanceEstimate: PropTypes.number.isRequired,
  aggregatedPerformance: PropTypes.number.isRequired,
  metricLabelPlural: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
};

FundraiserAdminMilestoneAchievementModal.defaultProps = {};

export default FundraiserAdminMilestoneAchievementModal;
