/* eslint-disable react/prop-types */
import cx from 'classnames';

export const Wrapper = ({ className, children }) => (
  <div className={cx('animate-pulse', className)}>{children}</div>
);

export const Circle = ({ className, size = 48 }) => (
  <div
    style={{ width: size, height: size }}
    className={cx('rounded-full bg-gray-300', className)}
  />
);

export const Text = ({ className }) => (
  <div className={cx('rounded bg-gray-300 min-h-3', className)} />
);

export const Headline = ({ className }) => (
  <div className={cx('rounded bg-gray-300 h-7', className)} />
);
