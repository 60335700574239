import { useRef, useCallback } from 'react';
import { makeVar, useReactiveVar } from '@apollo/client';
import { motion } from 'framer-motion';
import { Popover as TinyPopover } from 'react-tiny-popover';
import uniqueId from 'lodash/uniqueId';

import cx from '@/lib/cx';

const activePopoverVar = makeVar(null);

type PopoverProps = PropsWithNonEmptyChildren<{
  trigger: NonEmptyReactNode;
  positions?: ('top' | 'right' | 'bottom' | 'left')[];
  align?: 'start' | 'center' | 'end';
  closeOnClick?: boolean;
  className?: string;
  disabled?: boolean;
}>;

const Popover = ({
  disabled = false,
  trigger,
  children,
  positions = ['bottom', 'top', 'right', 'left'],
  align = 'start',
  closeOnClick = false,
  className = '',
}: PopoverProps) => {
  const id = useRef(uniqueId('popover'));
  const activePopover = useReactiveVar(activePopoverVar);

  const toggle = useCallback(
    (shouldShow?: boolean) => {
      if (disabled) return;
      const willShow = typeof shouldShow === 'boolean' ? shouldShow : activePopover !== id.current;
      activePopoverVar(willShow ? id.current : null);
    },
    [disabled, activePopover]
  );
  const onTriggerClick = (e) => {
    e.preventDefault();
    if (!disabled) toggle();
  };

  const onContentClick = (e) => {
    e.stopPropagation();
    if (!disabled && closeOnClick) toggle(false);
  };

  const onClickOutside = () => {
    if (!disabled) toggle(false);
  };

  return (
    <TinyPopover
      isOpen={activePopover === id.current}
      onClickOutside={onClickOutside}
      clickOutsideCapture
      positions={positions}
      align={align}
      padding={4}
      containerClassName="z-40"
      content={({ position }) => {
        const nudgeStart = {
          top: { y: 20 },
          bottom: { y: -20 },
          left: { x: 20 },
          right: { x: -20 },
        }[position];

        return (
          <motion.div
            initial={{ ...nudgeStart, opacity: 0 }}
            animate={{ opacity: 1, x: 0, y: 0 }}
            transition={{ duration: 0.2 }}
            onClick={onContentClick}
          >
            {children}
          </motion.div>
        );
      }}
    >
      <button
        type="button"
        onClick={onTriggerClick}
        className={cx('block', { 'pointer-events-none': disabled }, className)}
      >
        {trigger}
      </button>
    </TinyPopover>
  );
};

export default Popover;
