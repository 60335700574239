import { useState, useMemo, useCallback } from 'react';
import keyBy from 'lodash/keyBy';
import pick from 'lodash/pick';

const useInviteList = ({ primaryItemKey, itemKeys } = {}) => {
  if (!primaryItemKey) throw new Error('Invite list requires a primaryItemKey');

  if (!Array.isArray(itemKeys) || itemKeys.length === 0) {
    throw new Error('Invite list requires itemKeys');
  }

  const [list, setList] = useState([]);

  const itemMap = useMemo(() => keyBy(list, primaryItemKey), [list, primaryItemKey]);

  const getItem = useCallback((value) => itemMap[value], [itemMap]);

  const add = useCallback(
    (toAdd) => {
      const additions = (Array.isArray(toAdd) ? toAdd : [toAdd])
        .filter((x) => !itemMap[x[primaryItemKey]])
        .map((item) => pick(item, itemKeys));
      if (additions.length === 0) return false;
      setList((prev) => [...additions, ...prev]);
      return true;
    },
    [itemMap, primaryItemKey, itemKeys]
  );

  const remove = useCallback(
    (item) => {
      if (!itemMap[item[primaryItemKey]]) return false;
      setList((prev) => prev.filter((x) => x[primaryItemKey] !== item[primaryItemKey]));
      return true;
    },
    [itemMap, primaryItemKey]
  );

  return {
    list,
    getItem,
    add,
    remove,
  };
};

export default useInviteList;
