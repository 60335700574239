export const getFundraiserUrl = ({ fundraiserId, fundraiserSlug, campaignId, campaignSlug }) => {
  if (!fundraiserSlug) {
    return `/f/${fundraiserId}`;
  }

  return `/${campaignSlug ?? `c/${campaignId}`}/@${fundraiserSlug}`;
};

export const getTeamUrl = ({ teamId, teamSlug, campaignId, campaignSlug }) => {
  if (!teamSlug) {
    return `/t/${teamId}`;
  }

  return `/${campaignSlug ?? `c/${campaignId}`}/teams/@${teamSlug}`;
};
