import { Children } from 'react';
import cx from 'classnames';

const AppBarToolbar = ({ children = null }: PropsWithNonEmptyChildren) => {
  const items = Children.toArray(children);

  return (
    <div className="h-full px-3 lg:px-4 flex items-center justify-between">
      {items.map((item, i) => {
        const isCenterItem = items.length === 3 && i === 1;
        const isEdgeItem = items.length === 3 && i !== 1;

        return (
          <div
            key={i}
            className={cx('overflow-hidden', {
              'text-center': isCenterItem,
              'shrink-0': isEdgeItem,
            })}
            style={{ minWidth: 85 }}
          >
            {item}
          </div>
        );
      })}
    </div>
  );
};

export default AppBarToolbar;
