import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Image } from 'cloudinary-react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import config from 'config';
import { useAuth } from 'context/Auth';
import { makeRequired, makeNullable } from 'lib/validators';
import FormFieldGroup from 'components/form/FormFieldGroup';
import FormNode from 'components/form/FormNode';
import FormImageUploader from 'components/form/FormImageUploader';
import FormLabel from 'components/form/FormLabel';
import FormNote from 'components/form/FormNote';
import FormInput from 'components/form/FormInput';
import CampaignAdminAPIKey from 'components/campaign-admin/CampaignAdminAPIKey';
import Button from 'components/common/Button';

const validationSchema = yup.object().shape({
  firstName: makeRequired(yup.string()),
  lastName: makeRequired(yup.string()),
  email: makeRequired(yup.string().email(config('/validationMessages/email'))),
  avatarImage: makeNullable(yup.string()),
});

const AccountSettingsForm = ({ onClickPassword, onSubmit }) => {
  const { user } = useAuth();
  const { control, formState, reset, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const { errors, isSubmitting } = formState;

  useEffect(() => {
    reset({
      firstName: user.firstName ?? '',
      lastName: user.lastName ?? '',
      email: user.email ?? '',
      avatarImage: user.avatarImage ?? '',
    });
  }, [user, reset]);

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <div className="p-6">
        <FormFieldGroup>
          <div className="flex justify-center sm:justify-start mb-3">
            <FormLabel className="hidden" htmlFor="avatarImage">
              Profile Photo
            </FormLabel>
            <Controller
              control={control}
              name="avatarImage"
              render={({ field }) => (
                <FormImageUploader
                  placeholder={config('/defaultFundraiserAvatar')}
                  preview={({ image }) => (
                    <Image
                      publicId={image}
                      alt=""
                      transformation="avatar"
                      className="block w-32 sm:ml-0 rounded-full border-4 border-white shadow-lg"
                    />
                  )}
                  aspectRatio={1 / 1}
                  allowCrop
                  editIconSize="1x"
                  {...field}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="avatarImage"
              render={({ message }) => <FormNote status="error">{message}</FormNote>}
            />
          </div>
          <FormNode>
            <FormLabel htmlFor="firstName" isRequired>
              First Name
            </FormLabel>
            <Controller
              control={control}
              name="firstName"
              render={({ field, fieldState }) => (
                <FormInput type="text" status={fieldState.error ? 'error' : 'default'} {...field} />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="firstName"
              render={({ message }) => <FormNote status="error">{message}</FormNote>}
            />
          </FormNode>
          <FormNode>
            <FormLabel htmlFor="lastName" isRequired>
              Last Name
            </FormLabel>
            <Controller
              control={control}
              name="lastName"
              render={({ field, fieldState }) => (
                <FormInput type="text" status={fieldState.error ? 'error' : 'default'} {...field} />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="lastName"
              render={({ message }) => <FormNote status="error">{message}</FormNote>}
            />
          </FormNode>
          <FormNode>
            <FormLabel htmlFor="email" isRequired>
              Email Address
            </FormLabel>
            <Controller
              control={control}
              name="email"
              render={({ field, fieldState }) => (
                <FormInput
                  type="email"
                  status={fieldState.error ? 'error' : 'default'}
                  {...field}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="email"
              render={({ message }) => <FormNote status="error">{message}</FormNote>}
            />
          </FormNode>
          {user.apiKey && <CampaignAdminAPIKey apiKey={user.apiKey} />}
        </FormFieldGroup>
      </div>
      <div className="p-6 pt-0 flex flex-col-reverse">
        <Button
          as="button"
          type="button"
          onClick={onClickPassword}
          color="gray-800"
          outline
          className="font-medium w-full lg:hidden mb-4"
        >
          Change password
        </Button>
        <Button
          as="button"
          type="submit"
          color="primary"
          className="font-medium w-full lg:w-28 lg:ml-auto mb-4"
          disabled={isSubmitting}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

AccountSettingsForm.propTypes = {
  onClickPassword: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AccountSettingsForm;
