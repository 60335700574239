import { useState, useEffect, useCallback } from 'react';
import { useScript } from '@jeetiss/react-use-script';

import config from 'config';

const useIframely = (url) => {
  const key = config('/iframely/key');
  const encodedUrl = encodeURIComponent(url);
  const apiUrl = `https://iframe.ly/api/oembed?url=${encodedUrl}&key=${key}&iframe=1&omit_script=1`;

  const [loaded] = useScript('https://cdn.iframe.ly/embed.js');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const onLoad = useCallback(async () => {
    setLoading(true);
    await window.iframely.load();

    const response = await fetch(apiUrl);

    if (!response.ok) {
      setError(response.statusText);
      setLoading(false);
      return;
    }

    const body = await response.json();
    if (body.error) setError(body.error);
    if (!body.html) setError('No media found at given URL.');
    setData(body);
    setLoading(false);
  }, [apiUrl, setData, setError]);

  useEffect(() => {
    if (loaded && url) onLoad();
  }, [loaded, url, onLoad]);

  return { loading, error, data };
};

export default useIframely;
