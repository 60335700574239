import { CardElement, useElements } from '@stripe/react-stripe-js';
import pick from 'lodash/pick';

import { formatNumber } from 'lib/formatters';
import useCreateDonation from 'hooks/useCreateDonation';
import useGivingVoucher from 'hooks/useGivingVoucher';
import { dataVar } from './useDonationFormData';
import { useDonationFormWidget } from './useDonationFormWidget';

const useDonationFormCreateDonation = () => {
  const elements = useElements();

  const createDonation = useCreateDonation();

  const { campaign } = useDonationFormWidget();

  const generateGivingVoucher = useGivingVoucher(campaign.id);

  return async () => {
    const data = dataVar();

    // Trigger form validation and wallet collection
    const { error: paymentElementError } = await elements.submit();
    if (paymentElementError) {
      throw new Error('PaymentElementValidationError');
    }

    return createDonation(
      {
        ...pick(data, [
          'type',
          'firstName',
          'lastName',
          'email',
          'coverFee',
          'phone',
          'address1',
          'address2',
          'city',
          'stateProv',
          'postalCode',
          'country',
        ]),
        recurringFrequency: data.type === 'recurring_flat' ? data.recurringFrequency : undefined,
        amount: formatNumber(data.amount, '0.00'),
        campaignId: campaign.id,
        estimatedAmount: formatNumber(data.amount, '0.00'),
        givingVoucher: await generateGivingVoucher(),
        tip: '0.00',
      },
      elements.getElement(CardElement)
    );
  };
};

export default useDonationFormCreateDonation;
