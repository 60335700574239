import PropTypes from 'prop-types';
import { useState, useMemo, useCallback } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faPaperPlane,
  faPlusCircle,
  faArrowLeft,
} from '@fortawesome/pro-regular-svg-icons';
import { faPaperPlaneTop } from '@fortawesome/pro-solid-svg-icons';
import { motion } from 'framer-motion';
import groupBy from 'lodash/groupBy';
import orderBy from 'lodash/orderBy';
import uniqBy from 'lodash/uniqBy';

import config from 'config';
import cx from 'lib/cx';
import useFundraiserInviteMessages from 'hooks/useFundraiserInviteMessages';
import useInviteList from 'hooks/useInviteList';
import useInviteSuggestions from 'hooks/useInviteSuggestions';
import useToasts from 'hooks/useToasts';
import Button from 'components/common/Button';
import ConfirmDialog from 'components/common/ConfirmDialog';
import InviteGroups from 'components/common/InviteGroups';
import InviteGroupItem from 'components/common/InviteGroupItem';
import InviteRecipientList from 'components/common/InviteRecipientList';
import ModalPage from 'components/common/ModalPage';
import Navbar from 'components/common/Navbar';
import NavbarIconButton from 'components/common/NavbarIconButton';
import NavbarTitle from 'components/common/NavbarTitle';
import Shepherd from 'components/common/Shepherd';
import FormTextarea from 'components/form/FormTextarea';
import FormInput from 'components/form/FormInput';
import FormLabel from 'components/form/FormLabel';
import FormNode from 'components/form/FormNode';
import FormNote from 'components/form/FormNote';

const GET_POTENTIAL_CONTACTS = gql`
  query GetPotentialContacts($fundraiserId: String!) {
    findParticipants(id: $fundraiserId) {
      potentialContacts {
        email
        fullName
        source
      }
      contacts {
        id
        email
        fullName
      }
    }
  }
`;

const SEND_INVITES = gql`
  mutation SendInvites($fundraiserId: String!, $message: String, $recipients: [ContactInfo]) {
    createContactsAndSend(fundraiserId: $fundraiserId, contacts: $recipients, message: $message) {
      id
      contactCount
      contacts(order: "reverse:createdAt") {
        id
        email
        fullName
        inviteStatus
        createdAt
        remindersRemaining
        maxReminders
        prevReminderDate
        nextReminderDate
      }
    }
  }
`;

const FundraiserAdminInviteViaEmail = ({ fundraiserId, onHide, show }) => {
  const toast = useToasts('fundraiser:invite');
  const [hasSeenShepherd, setHasSeenShepherd] = useState(false);
  const [view, setView] = useState('compose'); // compose, bulk-add, confirm-quit

  const { data } = useQuery(GET_POTENTIAL_CONTACTS, { variables: { fundraiserId } });
  const potentialContacts = useMemo(() => {
    const participant = data?.findParticipants[0];
    const sentContacts = participant?.contacts ?? [];
    const priorContacts = participant?.potentialContacts ?? [];

    return uniqBy([...priorContacts, ...sentContacts], 'email').map((x) => {
      const alreadyInvited = sentContacts.some((y) => y.email === x.email);
      return {
        ...x,
        alreadyInvited,
        source: alreadyInvited ? 'contact' : x.source,
      };
    });
  }, [data]);

  const potentialContactsBySource = useMemo(
    () => groupBy(orderBy(potentialContacts, ['fullName', 'email'], ['asc', 'asc']), 'source'),
    [potentialContacts]
  );

  const {
    list: inviteList,
    getItem: getInvite,
    add: addInvite,
    remove: removeInvite,
  } = useInviteList({
    primaryItemKey: 'email',
    itemKeys: ['email', 'fullName', 'source'],
  });

  const { email, setEmail, shouldDisplayError, suggestions } = useInviteSuggestions({
    possibleSuggestions: uniqBy([...potentialContacts, ...inviteList], 'email'),
  });

  // const hasBulkOptions = potentialContacts.length > 0;
  const hasBulkOptions = potentialContacts.some((x) => !x.alreadyInvited);

  const canSend = inviteList.length > 0;

  const [message, setMessage] = useState('');
  const defaultMessages = useFundraiserInviteMessages({ fundraiserId });
  const messageValue = message || defaultMessages.email;

  const renderEmailSuggestions = useCallback(
    () => (
      <>
        {suggestions.map((contact) => {
          const isInvited = !!getInvite(contact.email) || contact.alreadyInvited;

          return (
            <InviteGroupItem
              key={contact.email}
              name={contact.fullName}
              detail={contact.email}
              onSelect={() => {
                const didAdd = addInvite(contact);
                if (didAdd) setEmail('');
              }}
              isSelected={isInvited}
              isDisabled={isInvited}
              highlight={email}
              hideDeselectedBox
              onHideDropdown={() => setEmail('')}
            />
          );
        })}
      </>
    ),
    [addInvite, email, setEmail, suggestions, getInvite]
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      // First make sure we have a valid email
      const [topSuggestion] = suggestions;
      if (!topSuggestion) return;

      // Make sure recipient hasn't already been invited
      if (topSuggestion.alreadyInvited) return;

      // Add them to the list
      const didAdd = addInvite(topSuggestion);
      if (didAdd) setEmail('');
    },
    [suggestions, setEmail, addInvite]
  );

  const [sendInvites] = useMutation(SEND_INVITES, {
    variables: { fundraiserId, message: messageValue, recipients: inviteList },
    onCompleted: () => {
      toast.success('Your invites have been sent!');
      onHide();
    },
    onError: (err) => {
      if (config('/debug')) console.error(err);
      toast.error(
        'We were unable to send one or more of your invites. This can happen if you try to send an invite to an email you have already invited.'
      );
    },
  });

  const onSafeHide = () => {
    if (canSend) {
      setView('confirm-quit');
      return;
    }

    onHide();
  };

  if (view === 'confirm-quit') {
    return (
      <ConfirmDialog
        title="Quit adding contacts?"
        confirmLabel="Yes, quit"
        declineLabel="No, go back"
        onConfirm={onHide}
        onDecline={() => setView('compose')}
        className="md:max-w-md"
        show
      >
        Are you sure you want to close and quit? The contacts you entered will not be invited.
      </ConfirmDialog>
    );
  }

  return (
    <ModalPage
      onHide={onSafeHide}
      show={show}
      hideOnOverlayClick={false}
      hideOnEscape={false}
      className="lg:max-w-md flex flex-col"
      fixedHeight
    >
      {view === 'compose' ? (
        <div className="flex flex-col flex-1">
          <Navbar
            className="sticky z-40 top-0 inset-x-0 h-16 shrink-0 bg-white border-b border-gray-400 px-4 md:px-4"
            left={
              <NavbarIconButton
                as="button"
                type="button"
                icon={faTimes}
                onClick={onSafeHide}
                iconProps={{ size: 'lg' }}
              />
            }
            center={<NavbarTitle title="Invite via email" />}
            right={
              <NavbarIconButton
                as="button"
                type="button"
                onClick={sendInvites}
                icon={faPaperPlaneTop}
                disabled={!canSend}
                className="lg:hidden"
                theme="primary"
              />
            }
          />

          <div
            className={cx('p-6 flex flex-col', {
              'flex-1': suggestions.length === 0,
              'shrink-0 lg:flex-1': suggestions.length > 0,
            })}
          >
            <form onSubmit={onSubmit} className="shrink-0 relative" autoComplete="off" noValidate>
              <input type="submit" className="hidden" />
              <FormNode>
                <FormLabel className="hidden" htmlFor="email">
                  Add recipients
                </FormLabel>
                <FormInput
                  type="email"
                  placeholder="Add recipients"
                  name="email"
                  value={email ?? ''}
                  status={shouldDisplayError ? 'error' : 'default'}
                  onChange={setEmail}
                  autoFocus={!hasBulkOptions}
                  autoComplete="new-password"
                  suffix={
                    hasBulkOptions && (
                      <Shepherd
                        content="Quickly add past donors or previously invited contacts."
                        placement="bottom"
                        className="w-56"
                        show={!email && !hasSeenShepherd}
                        onHide={() => setHasSeenShepherd(true)}
                      >
                        <button
                          type="button"
                          className="transition-colors duration-200 hover:text-gray-600"
                          onClick={() => {
                            if (email) {
                              setEmail('');
                              return;
                            }

                            if (!hasSeenShepherd) setHasSeenShepherd(true);
                            setView('bulk-add');
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faPlusCircle}
                            size="lg"
                            className={cx('transition-all duration-200', {
                              'rotate-[225deg]': !!email,
                            })}
                          />
                        </button>
                      </Shepherd>
                    )
                  }
                />
                {shouldDisplayError && (
                  <FormNote status="error">Please enter a valid email address</FormNote>
                )}
              </FormNode>
              {suggestions.length > 0 && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="bg-white border border-gray-400 rounded-lg shadow-lg overflow-hidden hidden lg:block w-full absolute top-[100%] inset-x-0 z-50 mt-2"
                >
                  <div className="py-2 w-full max-h-48 overflow-y-auto hide-scroll">
                    {renderEmailSuggestions()}
                  </div>
                </motion.div>
              )}
            </form>
            <div
              className={cx('flex-1 flex flex-col', {
                'hidden lg:flex': suggestions.length > 0,
              })}
            >
              {inviteList.length > 0 && (
                <InviteRecipientList
                  recipients={inviteList}
                  onRemove={removeInvite}
                  className="mt-4 -mb-1 -mx-6 lg:mx-0 shrink-0"
                />
              )}
              <FormTextarea
                value={messageValue}
                onChange={setMessage}
                wrapperClassName="mt-6 flex-1"
                className="h-full"
              />
            </div>
          </div>

          {suggestions.length > 0 && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="flex-1 oveflow-y-auto lg:hidden -mt-6 py-3"
            >
              {renderEmailSuggestions()}
            </motion.div>
          )}

          <div className="p-6 border-t border-gray-400 shrink-0 hidden lg:block">
            <Button
              as="button"
              type="button"
              onClick={sendInvites}
              theme="primary"
              disabled={!canSend}
              className="font-medium block w-full"
            >
              <div className="flex items-center gap-3">
                <FontAwesomeIcon icon={faPaperPlane} size="lg" />
                Send message
              </div>
            </Button>
          </div>
        </div>
      ) : (
        <motion.div
          initial={{ opacity: 0, x: 25 }}
          animate={{ opacity: 1, x: 0 }}
          className="h-full flex-1 flex flex-col overflow-hidden"
        >
          <Navbar
            className="sticky z-40 top-0 inset-x-0 h-16 shrink-0 bg-white border-b border-gray-400 px-4 md:px-4"
            left={
              <NavbarIconButton
                as="button"
                type="button"
                icon={faArrowLeft}
                onClick={() => setView('compose')}
                iconProps={{ size: 'lg' }}
              />
            }
            center={<NavbarTitle title="Contacts" />}
          />
          <InviteGroups
            onSubmit={(selected) => {
              addInvite(selected);
              setView('compose');
            }}
            groups={[
              {
                label: 'Past donors',
                items: (potentialContactsBySource.donation ?? []).map((x) => ({
                  ...x,
                  isSelected: !!getInvite(x.email),
                })),
              },
              {
                label: 'Previously invited',
                items: (potentialContactsBySource.contact ?? []).map((x) => ({
                  ...x,
                  isSelected: !!getInvite(x.email),
                })),
              },
            ]}
          />
        </motion.div>
      )}
    </ModalPage>
  );
};

FundraiserAdminInviteViaEmail.propTypes = {
  fundraiserId: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

FundraiserAdminInviteViaEmail.defaultProps = {};

export default FundraiserAdminInviteViaEmail;
