import PropTypes from 'prop-types';
import cx from 'classnames';

const FormNote = ({ status, children, className }) => (
  <p
    className={cx(
      'text-sm',
      {
        'text-gray-600': status === 'default',
        'text-red-600': status === 'error',
        'text-yellow-600': status === 'warning',
        'text-green-500': status === 'success',
        'text-theme-primary': status === 'info',
        'text-gray-400': status === 'disabled',
      },
      className
    )}
    data-formnote-status={status}
  >
    {children}
  </p>
);

FormNote.propTypes = {
  status: PropTypes.oneOf(['default', 'error', 'warning', 'success', 'info', 'disabled']),
  children: PropTypes.node,
  className: PropTypes.string,
};

FormNote.defaultProps = {
  status: 'default',
  children: null,
  className: '',
};

export default FormNote;
