import { useRef, useEffect } from 'react';
import { makeVar, useReactiveVar } from '@apollo/client';

export const STEPS = ['amount', 'contact', 'payment', 'confirmation'];

const stepVar = makeVar(STEPS[0]);

export const setStep = (step) => {
  if (!STEPS.includes(step)) throw new Error(`Invalid step: ${step}`);
  stepVar(step);
};

export const useStep = (step) => {
  const shouldSetStep = useRef(!!step);

  useEffect(() => {
    if (!shouldSetStep.current) return;
    shouldSetStep.current = false;
    setStep(step);
  }, [step]);

  return useReactiveVar(stepVar);
};

export const getPreviousStep = () => STEPS[STEPS.indexOf(stepVar()) - 1] ?? null;

export const getNextStep = () => STEPS[STEPS.indexOf(stepVar()) + 1] ?? null;
