import { ReactNode } from 'react';
import { ResponsiveContainer, RadialBarChart, PolarAngleAxis, RadialBar } from 'recharts';
import cx from 'classnames';

import config from 'config';

type ChartData = { primary: number; secondary?: number };

type DonutChartProps = {
  primaryValue: number;
  secondaryValue?: number;
  targetValue: number;
  primaryColor?: string;
  secondaryColor?: string;
  targetColor?: string;
  statLabel?: ReactNode;
  statValue?: ReactNode;
  size?: 'sm' | 'lg';
};

const DonutChart = ({
  targetValue,
  primaryValue,
  secondaryValue = null,
  primaryColor = config('/tw/colors/green/500'),
  secondaryColor = config('/tw/colors/green/300'),
  targetColor = config('/tw/colors/gray/300'),
  statLabel = null,
  statValue = null,
  size = 'lg',
}: DonutChartProps) => {
  const hasStat = Boolean(statLabel || statValue);
  const hasSecondary = secondaryValue != null;

  const chartData: ChartData[] = [{ primary: primaryValue }];
  if (hasSecondary) chartData[0].secondary = secondaryValue;

  return (
    <div className="relative">
      <ResponsiveContainer aspect={1} width="100%">
        <RadialBarChart
          innerRadius="90%"
          outerRadius="100%"
          data={chartData}
          startAngle={90}
          endAngle={-270}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          barCategoryGap={-1}
        >
          <PolarAngleAxis
            type="number"
            angleAxisId={0}
            dataKey="value"
            domain={[0, targetValue]}
            tick={false}
          />
          <RadialBar
            dataKey="primary"
            stackId="progress"
            angleAxisId={0}
            fill={primaryColor}
            background={{ fill: targetColor }}
            cornerRadius={hasSecondary ? 0 : 100}
          />
          {hasSecondary && (
            <RadialBar
              dataKey="secondary"
              stackId="progress"
              angleAxisId={0}
              fill={secondaryColor}
            />
          )}
        </RadialBarChart>
      </ResponsiveContainer>
      {hasStat && (
        <div className="absolute inset-0 flex flex-col justify-center items-center p-8 text-center">
          {statValue != null && (
            <p
              className={cx('font-medium', {
                'text-2xl': size === 'sm',
                'text-3xl': size === 'lg',
              })}
            >
              {statValue}
            </p>
          )}
          {statLabel && (
            <p
              className={cx('uppercase text-gray-500 leading-snug', {
                'text-xs lg:text-sm': size === 'sm',
                'text-sm sm:text-base': size === 'lg',
              })}
            >
              {statLabel}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default DonutChart;
