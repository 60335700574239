import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faComments, faLevelUp, faGift } from '@fortawesome/pro-regular-svg-icons';
import { motion } from 'framer-motion';
import Big from 'big.js';
import orderBy from 'lodash/orderBy';
import cx from 'classnames';
import Link from 'components/common/Link';

import { formatFullName, formatInitials, formatTimeAgo, formatCurrency } from 'lib/formatters';
import useBreakpoint from 'hooks/useBreakpoint';
import Avatar from 'components/common/Avatar';
import Tiles from 'components/common/Tiles';
import Card from 'components/common/Card';
import FormTextarea from 'components/form/FormTextarea';
import Button from 'components/common/Button';

const CampaignPageDonation = ({
  allowReply,
  amount,
  comment,
  createdAt,
  donationTypeCode,
  fundraiser,
  isAnonymous,
  fullName,
  offlinePaymentDate,
  onReply,
  replies,
  showFundraiserName,
  showTeamName,
  team,
  snapshotLink,
  donationMatches,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isReplying, setIsReplying] = useState(false);
  const [newReply, setNewReply] = useState('');

  const mobile = !useBreakpoint('md');
  const displayName = useMemo(
    () => (isAnonymous ? 'Anonymous' : fullName),
    [isAnonymous, fullName]
  );

  const displayNameInitials = useMemo(() => formatInitials(displayName), [displayName]);

  const displayFundraiserName = useMemo(() => {
    if (showFundraiserName && fundraiser?.resolvedName) return fundraiser.resolvedName;
    if (showTeamName && team?.name) return team.name;
    return '';
  }, [showTeamName, showFundraiserName, team?.name, fundraiser]);

  // Always show expanded replies on mobile
  useEffect(() => {
    setIsExpanded(mobile);
  }, [mobile, setIsExpanded]);

  // If user can no longer reply, cancel current reply
  useEffect(() => {
    if (!allowReply && isReplying) setIsReplying(false);
  }, [allowReply, isReplying, setIsReplying]);

  const isPledge = donationTypeCode.includes('pledge');
  const displayAction = isPledge ? 'pledged' : 'donated';
  const displayDate = formatTimeAgo(offlinePaymentDate || createdAt);
  const isPerformanceClosed = ['confirmed', 'invoiced'].includes(fundraiser?.status);
  const totalPledgeAmount = isPerformanceClosed
    ? Big(amount).times(fundraiser?.aggregatedPerformanceResult ?? 0)
    : Big(amount).times(fundraiser?.performanceEstimate ?? 0);

  const metric = fundraiser?.performanceSettings?.metricLabel
    ? fundraiser?.performanceSettings?.metricLabel
    : team?.performanceSettings?.metricLabel;
  const displayAmount = isPledge
    ? `${formatCurrency(amount)} per ${metric} (${
        isPerformanceClosed ? '' : 'est '
      }${formatCurrency(totalPledgeAmount)})`
    : formatCurrency(amount);
  const handleReply = async (e) => {
    e.preventDefault();
    await onReply(newReply);
    setIsReplying(false);
    setNewReply('');
  };

  return (
    <Card
      radius={mobile ? 'none' : 'md'}
      as={snapshotLink ? Link : 'div'}
      depth="none"
      padding={mobile ? '2xs' : 'min'}
      border
      href={snapshotLink && snapshotLink.href}
    >
      <div className="relative overflow-hidden -my-3 sm:my-px -mx-1 sm:mx-px">
        <div className="w-20 flex justify-center absolute inset-y-10 left-0 z-0">
          <div
            className={cx('bg-gray-300 h-full', {
              hidden: donationMatches.length === 0,
            })}
            style={{ width: '2px' }}
          />
        </div>
        <div className="relative z-10 flex flex-col items-stretch gap-y-4">
          <div className={cx('flex -ml-4 pt-5 px-4', { 'py-5': donationMatches.length === 0 })}>
            <div className="pl-4 w-16 shrink-0">
              {isAnonymous ? (
                <div className="flex justify-center items-center w-12 h-12 border-2 border-gray-800 rounded-full box-border">
                  <FontAwesomeIcon icon={faHeart} className="text-2xl" />
                </div>
              ) : (
                <Avatar size="md" initials={displayNameInitials} themed />
              )}
            </div>
            <div className="pl-4 grow">
              <p className="leading-snug">
                <strong className="font-medium">{displayName}</strong> {displayAction}{' '}
                <strong className="font-medium">{displayAmount}</strong>
                {displayFundraiserName && (
                  <>
                    {' '}
                    to <strong className="font-medium">{displayFundraiserName}</strong>
                  </>
                )}
              </p>
              {comment && <p className="leading-relaxed">{comment}</p>}
              <Tiles className="py-1 leading-tight text-sm text-gray-600" spacing="sm">
                <p>{displayDate}</p>
                {/*
            {allowReply && (
              <button
                type="button"
                className="text-gray-600 hover:text-theme-primary-light transition-colors duration-200"
                onClick={() => setIsReplying(true)}
              >
                Reply
              </button>
            )}
            */}
              </Tiles>
            </div>
            <div className="hidden md:block w-24 pl-4 shrink-0 text-gray-600">
              {replies.length > 0 && (
                <button
                  type="button"
                  className="text-center rounded text-gray-600 hover:text-gray-500 transition-colors duration-200 p-1 leading-none group"
                  onClick={() => setIsExpanded((prev) => !prev)}
                >
                  <FontAwesomeIcon icon={faComments} className="text-2xl mb-1" />
                  <span className="text-sm font-medium inline-flex items-center text-gray-800 group-hover:text-gray-600 transition-colors duration-200">
                    {isExpanded ? 'Hide' : 'Show'}{' '}
                    <span className="w-4 h-4 bg-theme-secondary group-hover:bg-theme-secondary-light flex justify-center items-center text-white rounded-full inline-block text-2xs font-medium ml-2 transition-colors duration-200">
                      {replies.length}
                    </span>
                  </span>
                </button>
              )}
            </div>
          </div>
        </div>

        {donationMatches.length > 0 && (
          <div className="px-4 pb-5">
            {orderBy(donationMatches, (donationMatch) => donationMatch.match.createdAt).map(
              (match) => (
                <div className="my-3">
                  <div className="flex">
                    {match.match.logo ? (
                      <Avatar
                        size="md"
                        themed
                        image={match.match.logo}
                        className="shrink-0 self-end"
                      />
                    ) : (
                      <div className="relative shrink-0 self-end">
                        <div className="flex justify-center items-center w-12 h-12 rounded-full bg-white absolute" />
                        <div className="flex justify-center items-center w-12 h-12 border-2 border-gray-800 rounded-full box-border bg-theme-primary opacity-10" />
                        <FontAwesomeIcon
                          icon={faGift}
                          className="absolute text-2xl text-theme-primary bottom-3 left-3"
                        />
                      </div>
                    )}
                    <div className="bg-gray-100 border border-gray-400 text-gray-700 rounded-xl flex text-left items-center px-3 py-2 ml-3 ">
                      <p>
                        <span className="font-medium">{match.match.name}</span> matched{' '}
                        {formatCurrency(match.amount ?? 0)}
                      </p>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </div>

      <motion.div transition={{ staggerChildren: 0.1 }}>
        {isExpanded &&
          replies.map((reply) => (
            <motion.div
              key={reply.id}
              className="py-5 mx-6 border-t border-gray-200"
              initial={{ opacity: 0, x: 25 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.2 }}
            >
              <div className="flex -ml-4">
                <div className="shrink-0 pl-4 w-16">
                  <FontAwesomeIcon
                    icon={faLevelUp}
                    size="2x"
                    className="text-theme-secondary mx-auto ml-4"
                    rotation={90}
                  />
                </div>
                <div className="grow pl-4">
                  <p className="font-medium leading-snug">
                    {formatFullName(reply.createdBy.firstName, reply.createdBy.lastName)}
                  </p>
                  <p className="leading-relaxed">{reply.reply}</p>
                </div>
              </div>
            </motion.div>
          ))}

        {isReplying && (
          <motion.div
            initial={{ opacity: 0, y: -25 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2 }}
            className="bg-gray-200 py-5 px-6"
          >
            <form className="md:pl-16" onSubmit={handleReply}>
              <FormTextarea value={newReply} onChange={setNewReply} autoFocus />

              <Tiles spacing="md" className="pt-4 items-center">
                <Button
                  color="primary"
                  type="submit"
                  as="button"
                  className="text-sm font-medium"
                  padding="sm"
                >
                  Submit reply
                </Button>

                <button
                  type="button"
                  onClick={() => setIsReplying(false)}
                  className="text-gray-600 hover:text-gray-500 transition-colors duration-200"
                >
                  Cancel
                </button>
              </Tiles>
            </form>
          </motion.div>
        )}
      </motion.div>
    </Card>
  );
};

CampaignPageDonation.propTypes = {
  amount: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  donationTypeCode: PropTypes.oneOf([
    'pledge',
    'flat',
    'offline_pledge',
    'offline_flat',
    'order_flat',
    'commitment_flat',
  ]).isRequired,

  allowReply: PropTypes.bool,
  comment: PropTypes.string,
  fullName: PropTypes.string,
  fundraiser: PropTypes.shape({
    status: PropTypes.string,
    resolvedName: PropTypes.string,
    performanceSettings: PropTypes.shape({
      metricLabel: PropTypes.string,
    }),
    performanceEstimate: PropTypes.number,
    aggregatedPerformanceResult: PropTypes.string,
  }),
  isAnonymous: PropTypes.bool,
  offlinePaymentDate: PropTypes.string,
  onReply: PropTypes.func,
  replies: PropTypes.arrayOf(PropTypes.shape({})),
  showFundraiserName: PropTypes.bool,
  showTeamName: PropTypes.bool,
  team: PropTypes.shape({
    name: PropTypes.string,
    performanceSettings: PropTypes.shape({
      metricLabel: PropTypes.string,
    }),
  }),
  snapshotLink: PropTypes.shape({ href: PropTypes.string }),
  donationMatches: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number.isRequired,
      match: PropTypes.shape({
        createAt: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        logo: PropTypes.string,
      }),
    })
  ),
};

CampaignPageDonation.defaultProps = {
  allowReply: false,
  comment: null,
  fundraiser: null,
  isAnonymous: false,
  offlinePaymentDate: null,
  onReply: () => {},
  replies: [],
  showFundraiserName: false,
  showTeamName: false,
  team: null,
  fullName: '',
  snapshotLink: null,
  donationMatches: [],
};

export default CampaignPageDonation;
