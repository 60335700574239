import { PropsWithChildren, ReactNode } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

type LazyViewProps = PropsWithChildren<{ fallback?: ReactNode }>;

const LazyView = ({ fallback = null, children = null }: LazyViewProps) => (
  <AnimatePresence>
    <motion.div
      key={children ? 'loaded' : 'loading'}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      {children || fallback}
    </motion.div>
  </AnimatePresence>
);

export default LazyView;
