import React from 'react';
import PropTypes from 'prop-types';

import DonationFormFooter from './DonationFormFooter';

const DonationFormStep = ({ action, children }) => (
  <>
    <div className="flex-1 overflow-y-auto hide-scroll p-8">{children}</div>
    <div className="shrink-0 border-t border-gray-400 p-8 space-y-5">
      {action}
      <DonationFormFooter />
    </div>
  </>
);

DonationFormStep.propTypes = {
  action: PropTypes.node,
  children: PropTypes.node,
};

DonationFormStep.defaultProps = {
  action: null,
  children: null,
};

export default DonationFormStep;
