import PropTypes from 'prop-types';
import { forwardRef, useState } from 'react';
import { Transforms } from 'slate';
import { useSlate } from 'slate-react';
import { Popover } from 'react-tiny-popover';
import { motion } from 'framer-motion';
import { faPencil, faTrash, faExternalLink } from '@fortawesome/pro-regular-svg-icons';
import { useInViewRef, useMergeRefs } from 'rooks';

import Editor from 'lib/CustomSlateEditor';
import Card from 'components/common/Card';
import ButtonModal from './FormTextEditorButtonModal';
import ToolbarButton from './FormTextEditorToolbarButton';

const FormTextEditorElementButton = forwardRef(({ node, children, ...props }, ref) => {
  const editor = useSlate();

  const [inViewRef, isVisible] = useInViewRef();
  const refs = useMergeRefs(ref, inViewRef);

  const [isEditing, setIsEditing] = useState(false);

  const showOptions = Editor.findPath(editor, node).every(
    (nodePath, i) =>
      editor.selection?.anchor.path[i] === nodePath && editor.selection?.focus.path[i] === nodePath
  );

  return (
    <p ref={refs} className="relative" {...props}>
      <Popover
        isOpen={showOptions && isVisible}
        positions={['bottom', 'top']}
        align="start"
        padding={4}
        containerClassName="z-40"
        content={
          <motion.span initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="block">
            <Card
              as="span"
              padding="none"
              radius="sm"
              depth="sm"
              className="p-2 flex items-center gap-x-1"
              border
            >
              <ToolbarButton icon={faPencil} onClick={() => setIsEditing(true)} />
              <ToolbarButton
                icon={faTrash}
                onClick={() => {
                  Transforms.setNodes(
                    editor,
                    { type: 'paragraph' },
                    { match: (n) => n.type === 'button' }
                  );
                  Transforms.unsetNodes(editor, 'url', { match: (n) => n.type === 'paragraph' });
                }}
              />
              <ToolbarButton icon={faExternalLink} onClick={() => window.open(node.url)} />
            </Card>
          </motion.span>
        }
      >
        <span className="inline-block py-3 px-6 font-medium bg-teal-500 text-white rounded-md">
          {children}
        </span>
      </Popover>

      {isEditing && (
        <ButtonModal
          onHide={() => {
            Editor.focus(editor);
            setIsEditing(false);
          }}
        />
      )}
    </p>
  );
});

FormTextEditorElementButton.propTypes = {
  node: PropTypes.shape({
    url: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string })).isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

FormTextEditorElementButton.defaultProps = {};

export default FormTextEditorElementButton;
