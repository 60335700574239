import type { PropsWithChildren } from 'react';

import cx from '@/lib/cx';

type ToggleMenuItemProps = PropsWithChildren<{
  onClick: () => void;
  isActive?: boolean;
  className?: string;
  isDisabled?: boolean;
}>;

const ToggleMenuItem = ({
  onClick,
  isActive,
  isDisabled = false,
  children = null,
  className = '',
}: ToggleMenuItemProps) => (
  <button
    type="button"
    onClick={onClick}
    disabled={isDisabled}
    className={cx(
      'p-2.5 transition-all duration-200 rounded-xl font-medium',
      {
        'text-gray-700 hover:text-gray-600': !isActive && !isDisabled,
        'bg-white shadow-sm text-gray-800': isActive && !isDisabled,
        'pointer-events-none text-gray-500': isDisabled,
      },
      className
    )}
  >
    {children}
  </button>
);

export default ToggleMenuItem;
