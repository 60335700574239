import { useMemo, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import { gql, useQuery } from '@apollo/client';
import times from 'lodash/times';
import { motion } from 'framer-motion';
import cx from 'classnames';

import config from 'config';
import useBreakpoint from 'hooks/useBreakpoint';
import { useCampaignPage } from 'context/CampaignPage';
import clientOnly from 'components/clientOnly';
import { parseNumber } from 'lib/formatters';
import Button from 'components/common/Button';
import CampaignLeaderboard from 'components/common/CampaignLeaderboard';
import Card from 'components/common/Card';
import LazyView from 'components/common/LazyView';
import * as Skeleton from 'components/common/Skeleton';
import Tiles from 'components/common/Tiles';
import ToggleMenu from 'components/common/ToggleMenu';
import ToggleMenuItem from 'components/common/ToggleMenuItem';
import CampaignPageSectionHeader from './CampaignPageSectionHeader';

export const CAMPAIGN_LEADERBOARD_FRAGMENT = gql`
  fragment CampaignLeaderboardFields on Campaign {
    id
    fundraiserDefaultHeaderAvatarImage
    fundraisingType
    isPerformanceEnabled
    isMetricLabelLocked
    teamDefaultHeaderAvatarImage

    fundraisers1: fundraisers(limit: $limit, order: $order1) {
      id
      type
      avatarImage
      resolvedName
      performanceSettings {
        id
        metricLabelPlural
      }

      amountRaised
      aggregatedPerformanceResult

      team {
        id
        name
      }
    }

    fundraisers2: fundraisers(limit: $limit, order: $order2) @include(if: $queryBoth) {
      id
      type
      avatarImage
      resolvedName
      performanceSettings {
        id
        metricLabelPlural
      }

      amountRaised
      aggregatedPerformanceResult

      team {
        id
        name
      }
    }

    teams1: teams(limit: $limit, order: $order1) {
      id
      avatarImage
      name

      amountRaised
      aggregatedPerformanceResult
    }

    teams2: teams(limit: $limit, order: $order2) @include(if: $queryBoth) {
      id
      avatarImage
      name

      amountRaised
      aggregatedPerformanceResult
    }
  }
`;

const GET_LEADERBOARD_CONFIG = gql`
  query GetLeaderboardConfig($id: String!, $where: SequelizeJSON!) {
    findCampaigns(id: $id) {
      id
      fundraiserDefaultHeaderAvatarImage
      fundraisingType
      isPerformanceEnabled
      isMetricLabelLocked
      teamDefaultHeaderAvatarImage

      widgets(where: $where) {
        id
        title
        config
      }
    }
  }
`;

const GET_LEADERBOARD_DATA = gql`
  ${CAMPAIGN_LEADERBOARD_FRAGMENT}
  query GetLeaderboard(
    $id: String!
    $limit: Int!
    $order1: String!
    $order2: String
    $queryBoth: Boolean!
  ) {
    findCampaigns(id: $id) {
      ...CampaignLeaderboardFields
    }
  }
`;

const LeaderboardToggle = ({ value, onChange }) => (
  <ToggleMenu className="max-w-sm mx-auto mb-6 md:mb-12">
    <ToggleMenuItem isActive={value === 'individual'} onClick={() => onChange('individual')}>
      Individuals
    </ToggleMenuItem>
    <ToggleMenuItem isActive={value === 'team'} onClick={() => onChange('team')}>
      Teams
    </ToggleMenuItem>
  </ToggleMenu>
);

LeaderboardToggle.propTypes = {
  value: PropTypes.oneOf(['individual', 'team']).isRequired,
  onChange: PropTypes.func.isRequired,
};

export const LEADERBOARD_TITLES = {
  raised: 'Top Funds Raised',
  performance: 'Top Performers',
  individual: 'Top Fundraisers',
  team: 'Top Teams',
};

const CampaignPageLeaderboards = ({ id, className }) => {
  const mobile = !useBreakpoint('md');
  const { campaignId } = useCampaignPage();

  const { data: configData } = useQuery(GET_LEADERBOARD_CONFIG, {
    variables: { id: campaignId, where: { id } },
  });
  const campaign = useMemo(() => configData?.findCampaigns?.[0], [configData]);
  const leaderboard = useMemo(() => campaign?.widgets?.[0], [campaign]);

  const filterBy =
    leaderboard?.config.filterBy === 'both' && campaign?.fundraisingType === 'individual'
      ? 'individual'
      : leaderboard?.config.filterBy;

  const rankBy =
    leaderboard?.config.rankBy === 'both' &&
    (!campaign?.isPerformanceEnabled || !campaign?.isMetricLabelLocked)
      ? 'raised'
      : leaderboard?.config.rankBy;

  const sort1 = useMemo(() => {
    if (rankBy === 'both') {
      return 'amountRaised';
    }
    return rankBy === 'raised' ? 'amountRaised' : 'aggregatedPerformanceResult';
  }, [rankBy]);

  const sort2 = useMemo(() => {
    if (rankBy === 'both') {
      return 'aggregatedPerformanceResult';
    }
    return rankBy === 'raised' ? 'amountRaised' : 'aggregatedPerformanceResult';
  }, [rankBy]);

  const { data } = useQuery(GET_LEADERBOARD_DATA, {
    variables: {
      id: campaignId,
      limit: 5,
      order1: `reverse:${sort1}`,
      order2: `reverse:${sort2}`,
      queryBoth: (() => {
        if (!filterBy) return false;
        if (filterBy !== 'both' && rankBy !== 'both') return false;
        if (rankBy === 'both' && !campaign.isMetricLabelLocked) return false;
        return true;
      })(),
    },
    skip: !leaderboard,
  });

  const fundraisers1 = useMemo(() => data?.findCampaigns?.[0]?.fundraisers1 ?? [], [data]);
  const fundraisers2 = useMemo(() => data?.findCampaigns?.[0]?.fundraisers2 ?? [], [data]);
  const teams1 = useMemo(() => data?.findCampaigns?.[0]?.teams1 ?? [], [data]);
  const teams2 = useMemo(() => data?.findCampaigns?.[0]?.teams2 ?? [], [data]);

  const getFrData = useCallback(
    (frs, isTeam = false) => {
      const defaultAvatar = isTeam
        ? (campaign?.teamDefaultHeaderAvatarImage ?? config('/defaultTeamAvatar'))
        : (campaign?.fundraiserDefaultHeaderAvatarImage ?? config('/defaultFundraiserAvatar'));
      return frs.map((x) => ({
        id: x.id,
        image: x.avatarImage ?? defaultAvatar,
        name: x.name ?? x.resolvedName,
        description: x.team?.name,
        raised: parseNumber(x.amountRaised) ?? 0,
        performance: parseNumber(x.aggregatedPerformanceResult) ?? 0,
        isFundraiser: x.type === 'fundraiser',
      }));
    },
    [campaign]
  );

  const fundraiserData1 = useMemo(() => getFrData(fundraisers1), [getFrData, fundraisers1]);
  const fundraiserData2 = useMemo(() => getFrData(fundraisers2), [getFrData, fundraisers2]);
  const teamData1 = useMemo(() => getFrData(teams1, true), [getFrData, teams1]);
  const teamData2 = useMemo(() => getFrData(teams2, true), [getFrData, teams2]);

  const [toggleA, setToggleA] = useState('individual');
  const [toggleB, setToggleB] = useState('individual');
  const canToggle = filterBy === 'both' && rankBy === 'both';

  const leaderboardA = useMemo(() => {
    if (!filterBy) return null;
    return {
      type: canToggle ? toggleA : filterBy === 'both' ? 'individual' : filterBy,
      display: rankBy === 'both' ? 'raised' : rankBy,
    };
  }, [filterBy, rankBy, canToggle, toggleA]);

  const leaderboardB = useMemo(() => {
    if (!filterBy) return null;
    if (filterBy !== 'both' && rankBy !== 'both') return null;
    if (rankBy === 'both' && !campaign.isMetricLabelLocked) return null;

    const toggle = mobile ? toggleB : toggleA;
    return {
      type: canToggle ? toggle : filterBy === 'both' ? 'team' : filterBy,
      display: rankBy === 'both' ? 'performance' : rankBy,
    };
  }, [filterBy, rankBy, canToggle, toggleA, toggleB, mobile, campaign]);

  const columns = mobile || !leaderboardB ? 1 : 2;

  const titleProp = filterBy === 'both' && rankBy !== 'both' ? 'type' : 'display';
  const performanceLabel = fundraisers1?.[0]?.performanceSettings?.metricLabelPlural;

  // Leaderboards have nothing to display
  if (leaderboard && fundraisers1.length === 0 && teams1.length === 0) return null;

  // Invalid leaderboard configs for campaign type
  if (filterBy === 'team' && campaign?.fundraisingType === 'individual') return null;
  if (
    rankBy === 'performance' &&
    (!campaign?.isPerformanceEnabled || !campaign?.isMetricLabelLocked)
  ) {
    return null;
  }

  return (
    <section className={cx('py-16 xl:py-24 bg-white border-t border-b border-gray-300', className)}>
      <div className="container max-w-7xl">
        <LazyView
          fallback={
            <Skeleton.Wrapper className="container">
              <Tiles columns={[1, 2]} spacing="md">
                {times(mobile ? 1 : 2).map((x) => (
                  <div key={x}>
                    <Skeleton.Headline className="w-32" />
                    <Skeleton.Text className="w-16 mt-6 mb-4" />
                    <Tiles columns={1} spacing="sm">
                      {times(5).map((y) => (
                        <Card
                          key={y}
                          className="border border-gray-400 px-4 py-3"
                          depth="none"
                          padding="none"
                        >
                          <Tiles spacing="md">
                            <Skeleton.Circle />
                            <Tiles columns={1} spacing="xs">
                              <Skeleton.Text className="w-24" />
                              <Skeleton.Text className="w-40" />
                            </Tiles>
                          </Tiles>
                        </Card>
                      ))}
                    </Tiles>
                  </div>
                ))}
              </Tiles>
            </Skeleton.Wrapper>
          }
        >
          {leaderboard && (
            <>
              <CampaignPageSectionHeader
                title={leaderboard.title}
                titleClassName="text-theme-primary"
                className="mb-12"
              />

              {!mobile && canToggle && <LeaderboardToggle value={toggleA} onChange={setToggleA} />}

              <div className={cx({ 'md:max-w-3xl mx-auto': columns === 1 })}>
                <Tiles columns={columns} spacing="md">
                  <>
                    {leaderboardB && (
                      <h2 className="text-center md:text-left text-2xl font-medium mb-6">
                        {LEADERBOARD_TITLES[leaderboardA[titleProp]]}
                      </h2>
                    )}
                    {mobile && canToggle && (
                      <LeaderboardToggle value={toggleA} onChange={setToggleA} />
                    )}
                    <motion.div
                      key={`leaderboardA:${toggleA}`}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                    >
                      <CampaignLeaderboard
                        type={leaderboardA.type}
                        display={leaderboardA.display}
                        performanceLabel={performanceLabel}
                        data={leaderboardA.type === 'individual' ? fundraiserData1 : teamData1}
                        onClick={(x) => {
                          Router.push(`/${leaderboardA.type === 'individual' ? 'f' : 't'}/${x.id}`);
                        }}
                        limit={5}
                        showBadges
                      />
                      <div className="mt-8 text-center">
                        <Button
                          href={`/c/${campaign.id}/${
                            leaderboardA.type === 'team' ? 'teams' : 'fundraisers'
                          }?sortBy=${leaderboardA.display}`}
                          color="gray-300"
                          className="font-medium rounded-full"
                          outline
                        >
                          View All
                        </Button>
                      </div>
                    </motion.div>
                  </>
                  {leaderboardB && (
                    <>
                      <h2 className="text-center md:text-left text-2xl font-medium mb-6">
                        {LEADERBOARD_TITLES[leaderboardB[titleProp]]}
                      </h2>
                      {mobile && canToggle && (
                        <LeaderboardToggle value={toggleB} onChange={setToggleB} />
                      )}
                      <motion.div
                        key={`leaderboardB:${mobile ? toggleB : toggleA}`}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                      >
                        <CampaignLeaderboard
                          type={leaderboardB.type}
                          display={leaderboardB.display}
                          performanceLabel={performanceLabel}
                          data={leaderboardB.type === 'individual' ? fundraiserData2 : teamData2}
                          onClick={(x) => {
                            Router.push(
                              `/${leaderboardB.type === 'individual' ? 'f' : 't'}/${x.id}`
                            );
                          }}
                          limit={5}
                          showBadges
                        />
                        <div className="mt-8 text-center">
                          <Button
                            href={`/c/${campaign.id}/${
                              leaderboardB.type === 'team' ? 'teams' : 'fundraisers'
                            }?sortBy=${leaderboardB.display}`}
                            color="gray-300"
                            className="font-medium rounded-full"
                            outline
                          >
                            View All
                          </Button>
                        </div>
                      </motion.div>
                    </>
                  )}
                </Tiles>
              </div>
            </>
          )}
        </LazyView>
      </div>
    </section>
  );
};

CampaignPageLeaderboards.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

CampaignPageLeaderboards.defaultProps = {
  className: '',
};

export default clientOnly(CampaignPageLeaderboards);
