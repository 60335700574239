import { useMemo, useState, useRef } from 'react';
import { gql, useQuery } from '@apollo/client';
import cx from 'classnames';

import useBreakpoint from 'hooks/useBreakpoint';
import clientOnly from 'components/clientOnly';
import { useCampaignPage } from 'context/CampaignPage';
import Button from 'components/common/Button';
import FundraisingUpdate, {
  FUNDRAISING_UPDATE_FIELDS,
  FUNDRAISING_UPDATE_CAMPAIGN_FIELDS,
  FUNDRAISING_UPDATE_FUNDRAISER_FIELDS,
} from 'components/common/FundraisingUpdate';
import MasonryTiles from 'components/common/MasonryTiles';
import Tiles from 'components/common/Tiles';

const GET_FEATURED_UPDATES = gql`
  ${FUNDRAISING_UPDATE_FIELDS}
  ${FUNDRAISING_UPDATE_CAMPAIGN_FIELDS}
  ${FUNDRAISING_UPDATE_FUNDRAISER_FIELDS}

  query GetFeaturedUpdates($id: String!, $where: SequelizeJSON, $limit: Int, $offset: Int) {
    findCampaigns(id: $id) {
      ...FundraisingUpdateCampaignFields

      widgets(where: $where) {
        id
        type
        title
      }

      updates(
        where: { isFeatured: true }
        order: "reverse:createdAt"
        limit: $limit
        offset: $offset
      ) {
        ...FundraisingUpdateFields

        fundraiser {
          ...FundraisingUpdateFundraiserFields
        }
      }
    }
  }
`;

const FETCH_LIMIT = 50;

const CampaignPageFeaturedUpdates = () => {
  const mobile = !useBreakpoint('sm');
  const mdScreen = !useBreakpoint('md');
  const xlScreen = useBreakpoint('xl');
  const { campaignId } = useCampaignPage();
  const [canLoadMore, setCanLoadMore] = useState(false);
  const lastFetchCount = useRef(0);

  const stravaEmbedWidth = !mobile && !mdScreen ? 'xl' : 'sm';

  const { data, loading, fetchMore } = useQuery(GET_FEATURED_UPDATES, {
    variables: {
      id: campaignId,
      where: { type: 'communityActivity' },
      limit: FETCH_LIMIT,
      offset: 0,
    },
    onCompleted: ({ findCampaigns }) => {
      const newCount = findCampaigns?.[0]?.updates.length;
      const hasReachedEnd = newCount % FETCH_LIMIT !== 0 || lastFetchCount.current === newCount;
      setCanLoadMore(!hasReachedEnd);
      lastFetchCount.current = newCount;
    },
  });

  const campaign = useMemo(() => data?.findCampaigns?.[0], [data]);
  const updates = useMemo(() => campaign?.updates ?? [], [campaign]);

  if (!campaign || updates.length === 0) return null;

  return (
    <div className="my-24">
      <div className={cx({ container: !mobile })}>
        <h2 className="text-3xl lg:text-4xl font-medium leading-tight mb-8 text-center text-theme-primary">
          {data.findCampaigns[0].widgets?.[0]?.title}
        </h2>

        {updates.length < 3 ? (
          <Tiles columns={xlScreen ? [1, 2, 3] : [1, 2]} spacing="sm" align="center">
            {updates.map((update) => (
              <FundraisingUpdate
                key={update.id}
                update={update}
                campaign={campaign}
                fundraiser={update.fundraiser}
                size="sm"
                rounded={!mobile}
                showViewPage
                stravaEmbedWidth={stravaEmbedWidth}
              />
            ))}
          </Tiles>
        ) : (
          <MasonryTiles columns={xlScreen ? [1, 2, 3] : [1, 2]} spacing="sm">
            {updates.map((update) => (
              <FundraisingUpdate
                key={update.id}
                update={update}
                campaign={campaign}
                fundraiser={update.fundraiser}
                size="sm"
                rounded={!mobile}
                showViewPage
                stravaEmbedWidth={stravaEmbedWidth}
              />
            ))}
          </MasonryTiles>
        )}

        {canLoadMore && (
          <div className="mt-12 text-center">
            <Button
              as="button"
              type="button"
              onClick={() => fetchMore({ variables: { offset: updates.length } })}
              color="gray-300"
              className="font-medium rounded-full"
              disabled={loading}
              outline
            >
              {loading ? 'Loading...' : 'Load more'}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

CampaignPageFeaturedUpdates.propTypes = {};

CampaignPageFeaturedUpdates.defaultProps = {};

export default clientOnly(CampaignPageFeaturedUpdates);
