import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { motion } from 'framer-motion';

import RichText from 'components/common/RichText';
import FormNode from './FormNode';
import FormLabel from './FormLabel';
import FormNote from './FormNote';
import FormInput from './FormInput';
import FormAddressFields from './FormAddressFields';
import FormDateSelect from './FormDateSelect';
import FormRadioGroup from './FormRadioGroup';
import FormTextarea from './FormTextarea';
import FormPhoneInput from './FormPhoneInput';
import FormNumberInput from './FormNumberInput';
import FormCurrencyInput from './FormCurrencyInput';
import FormSelect from './FormSelect';
import FormCheckbox from './FormCheckbox';

/* eslint-disable react/prop-types */
export const AddressQuestion = ({ name, label, helpText, isRequired }) => (
  <FormAddressFields
    label={label}
    helpText={helpText}
    isRequired={isRequired}
    getFieldName={(prop) => `${name}.${prop}`}
  />
);

export const ChoiceQuestion = ({ name, label, helpText, isRequired, options }) => {
  const { control, formState } = useFormContext();
  const { errors } = formState;

  return (
    <FormNode>
      <div>
        <FormLabel htmlFor={name} isRequired={isRequired}>
          {label}
        </FormLabel>
        {helpText && <FormNote>{helpText}</FormNote>}
      </div>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => {
          const Component = options.length > 5 ? FormSelect : FormRadioGroup;
          return (
            <Component
              options={options.map((option) => ({
                label: option.label,
                value: option.id,
              }))}
              isSearchable={false}
              isClearable={!isRequired}
              status={fieldState.error ? 'error' : 'default'}
              isRequired={isRequired}
              {...field}
            />
          );
        }}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <FormNote status="error">{message}</FormNote>}
      />
    </FormNode>
  );
};

export const CurrencyQuestion = ({ name, label, helpText, isRequired }) => {
  const { control, formState } = useFormContext();
  const { errors } = formState;

  return (
    <FormNode>
      <div>
        <FormLabel htmlFor={name} isRequired={isRequired}>
          {label}
        </FormLabel>
        {helpText && <FormNote>{helpText}</FormNote>}
      </div>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => (
          <FormCurrencyInput
            allowDecimals
            status={fieldState.error ? 'error' : 'default'}
            {...field}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <FormNote status="error">{message}</FormNote>}
      />
    </FormNode>
  );
};

export const DateQuestion = ({ name, label, helpText, isRequired }) => {
  const { control, formState } = useFormContext();
  const { errors } = formState;

  return (
    <FormNode>
      <div>
        <FormLabel htmlFor={name} isRequired={isRequired}>
          {label}
        </FormLabel>
        {helpText && <FormNote>{helpText}</FormNote>}
      </div>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => (
          <FormDateSelect status={fieldState.error ? 'error' : 'default'} {...field} />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <FormNote status="error">{message}</FormNote>}
      />
    </FormNode>
  );
};

export const GenderQuestion = ({ name, label, helpText, isRequired }) => {
  const { control, formState, watch } = useFormContext();
  const { errors } = formState;
  const currentValue = watch(`${name}.value`);

  return (
    <FormNode>
      <div>
        <FormLabel htmlFor={`${name}.value`} isRequired={isRequired}>
          {label}
        </FormLabel>
        {helpText && <FormNote>{helpText}</FormNote>}
      </div>
      <Controller
        control={control}
        name={`${name}.value`}
        render={({ field, fieldState }) => (
          <FormRadioGroup
            options={[
              { label: 'Male', value: 'M' },
              { label: 'Female', value: 'F' },
              { label: 'Other', value: 'other' },
            ]}
            status={fieldState.error ? 'error' : 'default'}
            {...field}
          />
        )}
      />
      {currentValue === 'other' && (
        <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }}>
          <Controller
            control={control}
            name={`${name}.other`}
            render={({ field, fieldState }) => (
              <FormInput
                type="text"
                placeholder="Specify"
                autoFocus
                status={fieldState.error ? 'error' : 'default'}
                {...field}
              />
            )}
          />
        </motion.div>
      )}
      <ErrorMessage
        errors={errors}
        name={`${name}.value`}
        render={({ message }) => <FormNote status="error">{message}</FormNote>}
      />
      <ErrorMessage
        errors={errors}
        name={`${name}.other`}
        render={({ message }) => <FormNote status="error">{message}</FormNote>}
      />
    </FormNode>
  );
};

export const LongTextQuestion = ({ name, label, helpText, isRequired }) => {
  const { control, formState } = useFormContext();
  const { errors } = formState;

  return (
    <FormNode>
      <div>
        <FormLabel htmlFor={name} isRequired={isRequired}>
          {label}
        </FormLabel>
        {helpText && <FormNote>{helpText}</FormNote>}
      </div>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => (
          <FormTextarea type="text" status={fieldState.error ? 'error' : 'default'} {...field} />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <FormNote status="error">{message}</FormNote>}
      />
    </FormNode>
  );
};

export const NumberQuestion = ({ name, label, helpText, isRequired }) => {
  const { control, formState } = useFormContext();
  const { errors } = formState;

  return (
    <FormNode>
      <div>
        <FormLabel htmlFor={name} isRequired={isRequired}>
          {label}
        </FormLabel>
        {helpText && <FormNote>{helpText}</FormNote>}
      </div>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => (
          <FormNumberInput
            decimalsLimit={2}
            allowDecimals
            status={fieldState.error ? 'error' : 'default'}
            {...field}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <FormNote status="error">{message}</FormNote>}
      />
    </FormNode>
  );
};

export const PhoneQuestion = ({ name, label, helpText, isRequired }) => {
  const { control, formState } = useFormContext();
  const { errors } = formState;

  return (
    <FormNode>
      <div>
        <FormLabel htmlFor={name} isRequired={isRequired}>
          {label}
        </FormLabel>
        {helpText && <FormNote>{helpText}</FormNote>}
      </div>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => (
          <FormPhoneInput status={fieldState.error ? 'error' : 'default'} {...field} />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <FormNote status="error">{message}</FormNote>}
      />
    </FormNode>
  );
};

export const ShortTextQuestion = ({ name, label, helpText, isRequired }) => {
  const { control, formState } = useFormContext();
  const { errors } = formState;

  return (
    <FormNode>
      <div>
        <FormLabel htmlFor={name} isRequired={isRequired}>
          {label}
        </FormLabel>
        {helpText && <FormNote>{helpText}</FormNote>}
      </div>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => (
          <FormInput type="text" status={fieldState.error ? 'error' : 'default'} {...field} />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <FormNote status="error">{message}</FormNote>}
      />
    </FormNode>
  );
};

export const WaiverQuestion = ({ name, label, helpText, isRequired, config }) => {
  const { control, formState } = useFormContext();
  const { errors } = formState;

  return (
    <FormNode>
      <div>
        <FormLabel htmlFor={name} isRequired={isRequired}>
          {label}
        </FormLabel>
        {helpText && <FormNote>{helpText}</FormNote>}
      </div>
      <div
        className="rounded border border-gray-400 py-2 px-3 overflow-y-scroll"
        style={{ maxHeight: 180 }}
      >
        <RichText content={config.description} />
      </div>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => (
          <FormCheckbox
            label="I agree"
            status={fieldState.error ? 'error' : 'default'}
            {...field}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <FormNote status="error">{message}</FormNote>}
      />
    </FormNode>
  );
};

export const YesNoQuestion = ({ name, label, helpText, isRequired }) => {
  const { control, formState } = useFormContext();
  const { errors } = formState;

  return (
    <FormNode>
      <div>
        <FormLabel htmlFor={name} isRequired={isRequired}>
          {label}
        </FormLabel>
        {helpText && <FormNote>{helpText}</FormNote>}
      </div>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => (
          <FormRadioGroup
            options={[
              { label: 'Yes', value: 'yes' },
              { label: 'No', value: 'no' },
            ]}
            status={fieldState.error ? 'error' : 'default'}
            {...field}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <FormNote status="error">{message}</FormNote>}
      />
    </FormNode>
  );
};
/* eslint-enable react/prop-types */

const QUESTION_COMPONENTS = {
  address: AddressQuestion,
  choice: ChoiceQuestion,
  currency: CurrencyQuestion,
  date: DateQuestion,
  gender: GenderQuestion,
  number: NumberQuestion,
  phone: PhoneQuestion,
  text_single_line: ShortTextQuestion,
  text_multiple_line: LongTextQuestion,
  waiver_simple: WaiverQuestion,
  yes_no: YesNoQuestion,
};

const FormCustomQuestionField = ({ type, id, ...props }) => {
  const Component = useMemo(() => QUESTION_COMPONENTS[type], [type]);
  return <Component {...props} name={`question:${id}`} />;
};

FormCustomQuestionField.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'address',
    'choice',
    'currency',
    'date',
    'gender',
    'number',
    'phone',
    'text_single_line',
    'text_multiple_line',
    'waiver_simple',
    'yes_no',
  ]).isRequired,
  label: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  isRequired: PropTypes.bool,
};

FormCustomQuestionField.defaultProps = {
  helpText: null,
  isRequired: false,
};

export default FormCustomQuestionField;
