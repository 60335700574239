import { ElementType, ReactNode } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { motion } from 'framer-motion';

import Link from 'components/common/Link';

type NavbarLinkProps<C extends ElementType> = PolymorphicComponentProp<
  C,
  {
    icon: IconProp;
    label: NonEmptyReactNode;
    activeIcon?: IconProp;
    isActive?: boolean;
    badge?: ReactNode;
    className?: string;
  }
>;

const NavbarLink = <C extends ElementType = typeof Link>({
  as,
  icon,
  label,
  badge = null,
  isActive = false,
  activeIcon = null,
  className = '',
  ...props
}: NavbarLinkProps<C>) => {
  const Component = as || Link;
  return (
    <Component
      className={cx(
        'text-base font-medium flex flex-col justify-between h-20 whitespace-nowrap group',
        className
      )}
      {...props}
    >
      <div className="invisible" style={{ height: 3 }} />
      <div
        className={cx('px-3 py-2 rounded-md transition-colors duration-200', {
          'group-hover:bg-gray-300': !isActive,
        })}
      >
        <FontAwesomeIcon icon={isActive ? (activeIcon ?? icon) : icon} size="1x" className="mr-2" />
        {label}
        {badge && <span className="ml-1">{badge}</span>}
      </div>
      <motion.div
        initial={false}
        animate={{ opacity: isActive ? 1 : 0, y: isActive ? 0 : 10 }}
        className="rounded-full bg-gray-800"
        style={{ height: 3 }}
      />
    </Component>
  );
};

export default NavbarLink;
