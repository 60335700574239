import { AnimatePresence, motion } from 'framer-motion';

import { useGlobal } from 'context/Global';
import Toast from 'components/common/Toast';

const ToastList = () => {
  const { toasts, removeToast } = useGlobal();

  return (
    <div className="pointer-events-none fixed z-50 inset-0 flex flex-col justify-end md:items-end p-4 md:p-6">
      <AnimatePresence>
        {toasts.map((toast) => (
          <motion.div
            key={toast.id}
            initial={{ opacity: 0, x: 10 }}
            animate={{ opacity: 1, x: 0, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            className="pointer-events-auto w-full sm:max-w-md relative z-0 mt-2"
            layout
          >
            <Toast onDismiss={() => removeToast(toast.id)} {...toast} />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

ToastList.propTypes = {};

ToastList.defaultProps = {};

export default ToastList;
