import { ElementType } from 'react';
import cx from 'classnames';

type SearchTitleProps<C extends ElementType> = PolymorphicComponentProp<
  C,
  {
    className?: string;
  }
>;

const SearchTitle = <C extends ElementType = 'h2'>({
  as,
  className = '',
  children = null,
}: SearchTitleProps<C>) => {
  const Component = as || 'h2';
  return <Component className={cx('font-medium text-lg', className)}>{children}</Component>;
};

export default SearchTitle;
