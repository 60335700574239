import { Children, ElementType, ReactNode } from 'react';
import cx from 'classnames';

import Tiles from './Tiles';

type SearchResultListProps<C extends ElementType> = PolymorphicComponentProp<
  C,
  {
    limit?: number;
    showMoreLabel?: ReactNode;
    showLessLabel?: ReactNode;
    onShowMore?: () => void;
    onShowLess?: () => void;
    className?: string;
  }
>;

const SearchResultList = <C extends ElementType = 'div'>({
  as,
  limit = null,
  showMoreLabel = null,
  showLessLabel = null,
  onShowMore = undefined,
  onShowLess = undefined,
  className = '',
  children = null,
}: SearchResultListProps<C>) => {
  const Component = as || 'div';
  const items = Children.toArray(children);
  const visibleChildren = limit != null ? items.slice(0, limit) : items;
  const canViewMore = showMoreLabel && visibleChildren.length < items.length;
  const canViewLess = showLessLabel && limit == null;

  return (
    <Tiles spacing="xs" columns={1}>
      <Component className={cx('-mx-4', className)}>{visibleChildren}</Component>
      {canViewMore && (
        <button
          type="button"
          // We use the mousedown event here to prevent changing browser
          // focus from the input to the button
          onMouseDown={(e) => {
            e.preventDefault();
            onShowMore();
          }}
          className="block my-1 text-teal-500 hover:text-teal-300 transition-color duration-200"
        >
          {showMoreLabel}
        </button>
      )}
      {canViewLess && (
        <button
          type="button"
          // We use the mousedown event here to prevent changing browser
          // focus from the input to the button
          onMouseDown={(e) => {
            e.preventDefault();
            onShowLess();
          }}
          className="block my-1 text-teal-500 hover:text-teal-300 transition-color duration-200"
        >
          {showLessLabel}
        </button>
      )}
    </Tiles>
  );
};

export default SearchResultList;
