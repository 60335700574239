import PropTypes from 'prop-types';
import cx from 'classnames';

const FormTextEditorMark = ({ attributes, children, leaf }) => {
  const { className, ...attrs } = attributes;
  return (
    <span
      className={cx(
        {
          'font-medium': leaf.bold,
          italic: leaf.italic,
          underline: leaf.underline,
        },
        className
      )}
      {...attrs}
    >
      {children}
    </span>
  );
};

FormTextEditorMark.propTypes = {
  children: PropTypes.node,
  attributes: PropTypes.shape({
    className: PropTypes.string,
  }),
  leaf: PropTypes.shape({
    bold: PropTypes.bool,
    italic: PropTypes.bool,
    code: PropTypes.bool,
    underline: PropTypes.bool,
  }),
};

FormTextEditorMark.defaultProps = {
  children: null,
  attributes: {},
  leaf: {},
};

export default FormTextEditorMark;
