import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { motion } from 'framer-motion';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';

import globalConfig from 'config';
import cx from 'lib/cx';
import { formatCurrency } from 'lib/formatters';
import { makeRequired, currency } from 'lib/validators';
import Button from 'components/common/Button';
import ToggleMenu from 'components/common/ToggleMenu';
import ToggleMenuItem from 'components/common/ToggleMenuItem';
import FormCurrencyInput from 'components/form/FormCurrencyInput';
import FormFieldGroup from 'components/form/FormFieldGroup';
import FormLabel from 'components/form/FormLabel';
import FormNode from 'components/form/FormNode';
import FormValidationError from 'components/form/FormValidationError';
import DonationFormStep from './DonationFormStep';
import { updateData, useData } from './useDonationFormData';
import { getNextStep, setStep } from './useDonationFormStep';
import { useDonationFormWidget } from './useDonationFormWidget';

const validationSchema = yup.object({
  type: makeRequired(yup.string()),
  amount: makeRequired(currency.min(1, 'The smallest donation allowed is $1')),
  recurringFrequency: yup.string().when(['type'], {
    is: 'recurring_flat',
    then: (schema) => makeRequired(schema),
    otherwise: (schema) => schema.strip(),
  }),
});

const DonationFormAmount = ({ isPreview }) => {
  const { campaign, config } = useDonationFormWidget();
  const data = useData();

  const frequencyOptions = useMemo(
    () =>
      [
        {
          label: 'Hourly',
          value: 'hourly',
          if:
            ['staging', 'development'].includes(globalConfig('/env')) &&
            campaign?.supportsHourlyRecurringDonations,
        },
        {
          label: 'Daily',
          value: 'daily',
          if:
            ['staging', 'development'].includes(globalConfig('/env')) &&
            campaign?.supportsDailyRecurringDonations,
        },
        {
          label: 'Weekly',
          value: 'weekly',
          if:
            ['staging', 'development'].includes(globalConfig('/env')) &&
            campaign?.supportsWeeklyRecurringDonations,
        },
        { label: 'Monthly', value: 'monthly', if: campaign.supportsMonthlyRecurringDonations },
        {
          label: 'Quarterly',
          value: 'quarterly',
          if: campaign.supportsQuarterlyRecurringDonations,
        },
        { label: 'Yearly', value: 'yearly', if: campaign.supportsYearlyRecurringDonations },
      ].filter((x) => x.if ?? true),
    [
      campaign?.supportsHourlyRecurringDonations,
      campaign?.supportsDailyRecurringDonations,
      campaign?.supportsWeeklyRecurringDonations,
      campaign.supportsMonthlyRecurringDonations,
      campaign.supportsQuarterlyRecurringDonations,
      campaign.supportsYearlyRecurringDonations,
    ]
  );

  const typeOptions = useMemo(
    () =>
      [
        { label: 'One-time', value: 'flat' },
        {
          label: frequencyOptions.length > 1 ? 'Recurring' : frequencyOptions[0].label,
          value: 'recurring_flat',
          if: campaign.isRecurringGivingEnabled,
        },
      ].filter((x) => x.if ?? true),
    [campaign.isRecurringGivingEnabled, frequencyOptions]
  );

  const getPresets = (type) =>
    type === 'flat' ? campaign.donationPresets : campaign.recurringDonationPresets;

  const { control, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      amount: data.amount ?? getPresets(data.type ?? 'flat')[1],
      type: data.type ?? 'flat',
      recurringFrequency: data.recurringFrequency ?? frequencyOptions[0].value,
    },
    resolver: yupResolver(validationSchema),
  });

  const type = watch('type');
  const presets = getPresets(type);

  const onSubmit = (values) => {
    if (isPreview) return;
    updateData(values);
    setStep(getNextStep());
  };

  return (
    <DonationFormStep
      action={
        <Button
          as="button"
          type="button"
          color="primary"
          padding="sm"
          className="text-xl font-medium w-full"
          style={{ color: config.formButtonLabelColor }}
          onClick={handleSubmit(onSubmit)}
        >
          {config.formButtonLabel || 'Donate'}
        </Button>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="submit" className="hidden" />
        <div className="mb-10 text-center empty:hidden">
          {config.formHeading && (
            <h1 className="text-2xl font-medium mb-1">{config.formHeading}</h1>
          )}
          {config.formSubHeading && (
            <p className="text-lg text-gray-600">{config.formSubHeading}</p>
          )}
        </div>
        <FormFieldGroup>
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <ToggleMenu className={cx({ hidden: !campaign.isRecurringGivingEnabled })}>
                {typeOptions.map(({ label, value }) => (
                  <ToggleMenuItem
                    key={value}
                    isActive={field.value === value}
                    onClick={() => {
                      field.onChange(value);
                      setValue('amount', getPresets(value)[1]);
                    }}
                  >
                    {label}
                  </ToggleMenuItem>
                ))}
              </ToggleMenu>
            )}
          />

          <Controller
            name="amount"
            control={control}
            render={({ field, fieldState }) => (
              <div className="grid grid-cols-2 gap-3">
                {presets.map((option) => (
                  <button
                    key={`${type}:${option}`}
                    type="button"
                    onClick={() => field.onChange(option)}
                    className={cx(
                      'rounded-lg bg-white p-2 w-full transition-all duration-150 text-center text-xl font-medium h-14',
                      {
                        'border ring-1 focus:ring-1 ring-theme-primary border-theme-primary shadow-xl':
                          field.value === option,
                        'border border-gray-400 hover:border-gray-600 active:ring-theme-primary':
                          field.value !== option,
                      }
                    )}
                  >
                    {formatCurrency(option)}
                  </button>
                ))}
                <div className="col-span-2">
                  <FormNode>
                    <FormCurrencyInput
                      placeholder="Other Amount"
                      value={!presets.includes(field.value) ? field.value : ''}
                      onChange={field.onChange}
                      status={fieldState.error ? 'error' : 'default'}
                      className="w-full h-14"
                      allowDecimals
                    />
                    {fieldState.error && <FormValidationError {...fieldState.error} />}
                  </FormNode>
                </div>
              </div>
            )}
          />

          {type === 'recurring_flat' && (
            <motion.div
              className={cx({ hidden: frequencyOptions.length < 2 })}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <Controller
                control={control}
                name="recurringFrequency"
                render={({ field }) => (
                  <FormNode>
                    <FormLabel htmlFor={field.name} className="font-medium">
                      Set frequency
                    </FormLabel>
                    <div className="rounded-lg border border-gray-400 divide-x divide-gray-400 flex items-stretch overflow-hidden">
                      {frequencyOptions.map(({ label, value }) => (
                        <button
                          key={value}
                          type="button"
                          className={cx(
                            'h-12 flex-1 text-sm font-medium focus:ring-0 transition-colors duration-150',
                            {
                              'bg-gray-800 text-white': value === field.value,
                              'hover:bg-gray-200': value !== field.value,
                            }
                          )}
                          onClick={() => field.onChange(value)}
                        >
                          {label}
                        </button>
                      ))}
                    </div>
                  </FormNode>
                )}
              />
            </motion.div>
          )}
        </FormFieldGroup>
      </form>
    </DonationFormStep>
  );
};

DonationFormAmount.propTypes = {
  isPreview: PropTypes.bool,
};

DonationFormAmount.defaultProps = {
  isPreview: false,
};

export default DonationFormAmount;
