import PropTypes from 'prop-types';
import { useMemo, useCallback } from 'react';
import { useSlateStatic } from 'slate-react';
import { Node } from 'slate';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { makeRequired, link } from 'lib/validators';
import Editor from 'lib/CustomSlateEditor';
import Button from 'components/common/Button';
import ModalPage from 'components/common/ModalPage';
import Navbar from 'components/common/Navbar';
import NavbarIconButton from 'components/common/NavbarIconButton';
import NavbarTitle from 'components/common/NavbarTitle';
import Tiles from 'components/common/Tiles';
import FormFieldGroup from 'components/form/FormFieldGroup';
import FormNode from 'components/form/FormNode';
import FormLabel from 'components/form/FormLabel';
import FormInput from 'components/form/FormInput';
import FormNote from 'components/form/FormNote';

const validationSchema = yup.object({
  text: makeRequired(yup.string()),
  url: makeRequired(link),
});

const FormTextEditorLinkModal = ({ onHide }) => {
  const editor = useSlateStatic();

  const existingLink = useMemo(() => {
    const [match] = Editor.nodes(editor, { match: (n) => n.type === 'link' });
    return match?.[0];
  }, [editor]);

  const defaultText = useMemo(() => {
    if (existingLink) return Node.string(existingLink);
    if (editor.selection) return Editor.string(editor, editor.selection);
    return '';
  }, [existingLink, editor]);

  const { handleSubmit, control, formState } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      text: defaultText,
      url: existingLink?.url ?? '',
    },
  });
  const { errors } = formState;

  const onSubmit = useCallback(
    ({ text, url }) => {
      Editor.insertLink(editor, { text, url });
      onHide();
    },
    [editor, onHide]
  );

  return (
    <ModalPage onHide={onHide} show className="max-w-lg">
      <form
        onSubmit={(e) => {
          e.stopPropagation();
          handleSubmit(onSubmit)(e);
        }}
      >
        <Navbar
          className="sticky z-40 top-0 inset-x-0 h-14 shrink-0 bg-white border-b border-gray-400"
          left={
            <NavbarIconButton
              as="button"
              type="button"
              onClick={onHide}
              icon={faTimes}
              className="-ml-4"
            />
          }
          center={<NavbarTitle title={existingLink ? 'Edit link' : 'Add link'} />}
        />

        <div className="p-6 md:p-8">
          <FormFieldGroup>
            <FormNode>
              <FormLabel htmlFor="text" isRequired>
                Link text
              </FormLabel>
              <Controller
                control={control}
                name="text"
                render={({ field, fieldState }) => (
                  <FormInput
                    type="text"
                    status={fieldState.error ? 'error' : 'default'}
                    {...field}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="text"
                render={({ message }) => <FormNote status="error">{message}</FormNote>}
              />
            </FormNode>

            <FormNode>
              <FormLabel htmlFor="url" isRequired>
                URL
              </FormLabel>
              <Controller
                control={control}
                name="url"
                render={({ field, fieldState }) => (
                  <FormInput
                    type="text"
                    status={fieldState.error ? 'error' : 'default'}
                    {...field}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="url"
                render={({ message }) => <FormNote status="error">{message}</FormNote>}
              />
            </FormNode>
          </FormFieldGroup>
        </div>

        <div className="border-t border-gray-400 p-6 md:px-8">
          <Tiles spacing="xs" align="right" columns={2}>
            <Button
              as="button"
              type="button"
              onClick={onHide}
              color="gray-800"
              className="font-medium w-full"
              outline
            >
              Cancel
            </Button>
            <Button as="button" type="submit" color="primary" className="font-medium w-full">
              Save
            </Button>
          </Tiles>
        </div>
      </form>
    </ModalPage>
  );
};

FormTextEditorLinkModal.propTypes = {
  onHide: PropTypes.func.isRequired,
};

FormTextEditorLinkModal.defaultProps = {};

export default FormTextEditorLinkModal;
