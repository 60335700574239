import { useState, useCallback, useMemo, createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';

export const CampaignPageContext = createContext();

export const CampaignPageProvider = ({
  pageType,
  campaignId,
  fundraiserId,
  teamId,
  theme,
  campaignFeatures,
  isSharing,
  setIsSharing,
  isEditing,
  setIsEditing,
  isFundraiserManager,
  userIsFundraiser,
  children,
}) => {
  const [isInviting, setIsInviting] = useState(false);

  const onJoinCampaign = useCallback(
    () => Router.push(`/c/${campaignId}/${campaignFeatures.event ? 'checkout' : 'join'}`),
    [campaignFeatures, campaignId]
  );

  const value = useMemo(
    () => ({
      pageType,
      campaignId,
      fundraiserId,
      teamId,
      theme,
      onJoinCampaign,
      isSharing,
      setIsSharing,
      isInviting,
      setIsInviting,
      isEditing,
      setIsEditing,
      isFundraiserManager,
      userIsFundraiser,
    }),
    [
      pageType,
      campaignId,
      fundraiserId,
      teamId,
      theme,
      onJoinCampaign,
      isSharing,
      setIsSharing,
      isInviting,
      setIsInviting,
      isEditing,
      setIsEditing,
      isFundraiserManager,
      userIsFundraiser,
    ]
  );

  return <CampaignPageContext.Provider value={value}>{children}</CampaignPageContext.Provider>;
};

CampaignPageProvider.propTypes = {
  pageType: PropTypes.oneOf(['campaign', 'team', 'fundraiser']).isRequired,
  children: PropTypes.node.isRequired,
  campaignId: PropTypes.string.isRequired,
  theme: PropTypes.shape({
    primary: PropTypes.string.isRequired,
    primaryLight: PropTypes.string.isRequired,
    secondary: PropTypes.string.isRequired,
    secondaryLight: PropTypes.string.isRequired,
  }).isRequired,
  campaignFeatures: PropTypes.shape({
    event: PropTypes.bool,
    activityTracking: PropTypes.bool,
    strava: PropTypes.bool,
    join: PropTypes.bool,
    teams: PropTypes.bool,
    giving: PropTypes.bool,
    matching: PropTypes.bool,
    tickets: PropTypes.bool,
    participantQuestions: PropTypes.bool,
  }).isRequired,
  fundraiserId: PropTypes.string,
  teamId: PropTypes.string,

  isSharing: PropTypes.bool.isRequired,
  setIsSharing: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  isFundraiserManager: PropTypes.bool.isRequired,
  userIsFundraiser: PropTypes.bool.isRequired,
};

CampaignPageProvider.defaultProps = {
  teamId: null,
  fundraiserId: null,
};

export const CampaignPageConsumer = CampaignPageContext.Consumer;

export const useCampaignPage = () => useContext(CampaignPageContext);
