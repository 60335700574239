import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import { IconType } from 'lib/prop-types';
import { formatCurrency } from 'lib/formatters';
import CampaignPageActions from './CampaignPageActions';
import CampaignPageProgressLine from './CampaignPageProgressLine';

const CampaignPageHeroContent = ({
  heroStyle,
  title,
  subtitle,
  amountRaised,
  showProgress,
  chips,
  actions,
}) => (
  <div className={cx('grid grid-cols-1 gap-8', { 'text-center': heroStyle === 'cover' })}>
    {chips?.length > 0 && (
      <div className={cx('flex items-center gap-x-1', { 'justify-center': heroStyle === 'cover' })}>
        {chips.map((chip, i) => (
          <div
            key={`chip${i}`}
            className={cx('font-medium  rounded-md py-1.5 px-3 flex items-center gap-2', {
              'text-gray-800 bg-gray-100': heroStyle !== 'cover',
              'text-white bg-gray-900/70': heroStyle === 'cover',
            })}
          >
            {chip.icon && <FontAwesomeIcon icon={chip.icon} size="1x" />}
            {chip.label}
          </div>
        ))}
      </div>
    )}

    <div className="grid grid-cols-1 gap-4">
      <h1
        className={cx('font-medium leading-tight', {
          'text-gray-800 text-3xl lg:text-4xl': heroStyle !== 'cover',
          'text-white text-5xl': heroStyle === 'cover',
        })}
      >
        {title}
      </h1>
      {subtitle && (
        <p
          className={cx('leading-normal', {
            'text-gray-700 text-lg lg:text-xl': heroStyle !== 'cover',
            'text-gray-100 text-2xl font-medium': heroStyle === 'cover',
          })}
        >
          {subtitle}
        </p>
      )}
      {amountRaised != null && (
        <p
          className={cx('text-2xl leading-normal', {
            'text-gray-800': heroStyle !== 'cover',
            'text-white': heroStyle === 'cover',
          })}
        >
          <strong className="font-medium">
            {formatCurrency(amountRaised, { cents: 'never' })}
          </strong>{' '}
          raised
        </p>
      )}
    </div>

    {showProgress && <CampaignPageProgressLine />}

    {actions?.length > 0 && (
      <CampaignPageActions
        className={cx('lg:max-w-md', { 'w-full mx-auto': heroStyle === 'cover' })}
        primaryLabel={actions[0].label}
        onPrimaryClick={actions[0].onClick}
        {...(actions[1] && {
          secondaryLabel: actions[1].label,
          onSecondaryClick: actions[1].onClick,
          secondaryColor: heroStyle === 'cover' ? 'white' : undefined,
        })}
      />
    )}
  </div>
);

CampaignPageHeroContent.propTypes = {
  heroStyle: PropTypes.oneOf(['fade', 'cover', 'basic']).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  amountRaised: PropTypes.string,
  showProgress: PropTypes.bool,
  chips: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      icon: IconType,
    })
  ),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
};

CampaignPageHeroContent.defaultProps = {
  subtitle: null,
  amountRaised: null,
  showProgress: false,
  chips: [],
  actions: [],
};

export default CampaignPageHeroContent;
