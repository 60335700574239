import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Text } from 'slate';
import uniqueId from 'lodash/uniqueId';
import escapeHtml from 'escape-html';
import cx from 'classnames';

import Link from './Link';
import Button from './Button';

const serialize = (node) => {
  if (Text.isText(node)) {
    return (
      <span
        key={uniqueId('node-')}
        className={cx({
          'font-medium': node.bold,
          italic: node.italic,
          underline: node.underline,
        })}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: node.text.replace(/\n/g, '<br />') }}
      />
    );
  }

  const children = (node.children ?? []).map((n) => serialize(n));

  switch (node.type) {
    case 'title':
      return <h2 key={uniqueId('node-')}>{children}</h2>;

    case 'subtitle':
      return <h3 key={uniqueId('node-')}>{children}</h3>;

    case 'block-quote':
      return <blockquote key={uniqueId('node-')}>{children}</blockquote>;

    case 'paragraph':
      return <p key={uniqueId('node-')}>{children}</p>;

    case 'bulleted-list':
      return <ul key={uniqueId('node-')}>{children}</ul>;

    case 'numbered-list':
      return <ol key={uniqueId('node-')}>{children}</ol>;

    case 'list-item':
      return <li key={uniqueId('node-')}>{children}</li>;

    case 'link':
      return (
        <Link key={uniqueId('node-')} href={encodeURI(node.url)} className="break-words">
          {children}
        </Link>
      );

    case 'button':
      return (
        <p>
          <Button
            className="font-medium"
            padding="sm"
            color="primary"
            href={escapeHtml(node.url)}
            external
          >
            {children}
          </Button>
        </p>
      );

    case 'divider':
      return <div key={uniqueId('node-')} className="divider opacity-25 bg-current" />;

    case 'video':
      return null;

    default:
      return children;
  }
};

/* Rich text serializer for SlateJS ASTs */
const RichText = forwardRef(({ content, as: Component, className }, ref) => (
  <Component ref={ref} className={cx('rich-text', className)}>
    {serialize({ children: content })}
  </Component>
));

RichText.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.shape({ render: PropTypes.func }),
  ]),
  className: PropTypes.string,
};

RichText.defaultProps = {
  as: 'div',
  className: '',
};

export default RichText;
