import { useEffect, useCallback, useReducer, createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import uniq from 'lodash/uniq';
import without from 'lodash/without';
import uniqueId from 'lodash/uniqueId';

import config from 'config';

/**
 * Global state module to hold any application state that needs to
 * be persisted across multiple pages
 */
export const GlobalContext = createContext();

const EMPTY_CONTRIBUTION = {
  donationId: null,
  type: null,
  amount: null,
  tip: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'menu': {
      return { ...state, menuOpen: action.payload };
    }

    case 'secondary-menu': {
      return { ...state, secondaryMenuOpen: action.payload };
    }

    case 'lock-scroll': {
      return { ...state, scrollLocks: uniq([...state.scrollLocks, action.payload]) };
    }

    case 'unlock-scroll': {
      return state.scrollLocks === 0
        ? state
        : { ...state, scrollLocks: without(state.scrollLocks, action.payload) };
    }

    case 'add-toast': {
      return {
        ...state,
        // If the id matches an existing toast, the existing toast should be removed
        toasts: [...state.toasts.filter((x) => x.id !== action.payload.id), action.payload],
      };
    }

    case 'remove-toast': {
      return {
        ...state,
        toasts: state.toasts.filter((x) => x.id !== action.payload),
      };
    }

    case 'contribution-reset': {
      return { ...state, contribution: action.payload ?? EMPTY_CONTRIBUTION };
    }

    case 'contribution': {
      return {
        ...state,
        contribution: {
          ...state.contribution,
          ...action.payload,
        },
      };
    }

    default: {
      throw new Error(`No handler for action: ${action.type}`);
    }
  }
};

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    menuOpen: false,
    secondaryMenuOpen: true,
    scrollLocks: [],
    toasts: [],

    contribution: EMPTY_CONTRIBUTION,
  });

  const updateContribution = useCallback(
    (data) => dispatch({ type: 'contribution', payload: data }),
    [dispatch]
  );
  const resetContribution = useCallback(
    (data) => dispatch({ type: 'contribution-reset', payload: data }),
    [dispatch]
  );

  const openMenu = useCallback(() => dispatch({ type: 'menu', payload: true }), [dispatch]);
  const closeMenu = useCallback(() => dispatch({ type: 'menu', payload: false }), [dispatch]);
  const toggleMenu = useCallback(
    () => dispatch({ type: 'menu', payload: !state.menuOpen }),
    [dispatch, state.menuOpen]
  );

  const openSecondaryMenu = useCallback(
    () => dispatch({ type: 'secondary-menu', payload: true }),
    [dispatch]
  );
  const closeSecondaryMenu = useCallback(
    () => dispatch({ type: 'secondary-menu', payload: false }),
    [dispatch]
  );
  const toggleSecondaryMenu = useCallback(
    () => dispatch({ type: 'secondary-menu', payload: !state.secondaryMenuOpen }),
    [dispatch, state.secondaryMenuOpen]
  );

  const lockScroll = useCallback(
    (id) => dispatch({ type: 'lock-scroll', payload: id }),
    [dispatch]
  );
  const unlockScroll = useCallback(
    (id) => dispatch({ type: 'unlock-scroll', payload: id }),
    [dispatch]
  );

  const addToast = useCallback(
    (toast) => {
      if (!toast.id && config('/debug')) {
        console.error('Each toast should have an ID, otherwise it is given a random one');
      }
      dispatch({
        type: 'add-toast',
        payload: { ...toast, id: toast.id ?? uniqueId('toast-') },
      });
    },
    [dispatch]
  );

  const removeToast = useCallback(
    (toastId) => dispatch({ type: 'remove-toast', payload: toastId }),
    [dispatch]
  );

  const ctx = useMemo(
    () => ({
      ...state,

      updateContribution,
      resetContribution,

      openMenu,
      closeMenu,
      toggleMenu,

      openSecondaryMenu,
      closeSecondaryMenu,
      toggleSecondaryMenu,

      lockScroll,
      unlockScroll,

      addToast,
      removeToast,
    }),
    [
      state,
      updateContribution,
      resetContribution,
      openMenu,
      closeMenu,
      toggleMenu,
      openSecondaryMenu,
      closeSecondaryMenu,
      toggleSecondaryMenu,
      lockScroll,
      unlockScroll,
      addToast,
      removeToast,
    ]
  );

  useEffect(() => {
    const fn = state.scrollLocks.length > 0 ? 'add' : 'remove';
    document.body.classList[fn]('overflow-hidden');
  }, [state.scrollLocks.length]);

  return <GlobalContext.Provider value={ctx}>{children}</GlobalContext.Provider>;
};

GlobalProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const GlobalConsumer = GlobalContext.Consumer;

export const useGlobal = () => useContext(GlobalContext);
