import Router from 'next/router';
import { NextSeo } from 'next-seo';

import DefaultLayout from '@/components/layouts/DefaultLayout';
import Button from '@/components/common/Button';

type NotFoundPageProps = {
  statusMessage?: string;
};

const NotFoundPage = ({ statusMessage = "That page doesn't seem to exist" }: NotFoundPageProps) => {
  return (
    <DefaultLayout className="flex flex-col justify-center items-center">
      <NextSeo title="Page Not Found" noindex nofollow />
      <div className="text-center">
        <h1 className="text-5xl md:text-6xl font-medium">404</h1>
        {statusMessage && <p>{statusMessage}</p>}
        <Button
          as="button"
          type="button"
          color="primary"
          onClick={() => Router.back()}
          className="font-medium mt-8"
        >
          Go back
        </Button>
      </div>
    </DefaultLayout>
  );
};

export default NotFoundPage;
