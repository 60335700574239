import PropTypes from 'prop-types';
import { useQuery, gql } from '@apollo/client';
import { Image } from 'cloudinary-react';
import cx from 'classnames';

import Link from 'components/common/Link';
import Tiles from 'components/common/Tiles';
import CampaignPageSectionHeader from './CampaignPageSectionHeader';

const GET_WIDGET = gql`
  query GetCampaignPageLogoGrid($id: String!) {
    findCampaignWidgets(id: $id) {
      id
      title
      config
    }
  }
`;

const CampaignPageLogoGrid = ({ id, className }) => {
  const { data } = useQuery(GET_WIDGET, { variables: { id } });
  const block = data?.findCampaignWidgets[0];
  const logos = block?.config.logos ?? block?.config.sponsors ?? [];

  if (!block) return null;
  return (
    <div className={cx('py-16 bg-white', className)}>
      <div className="container max-w-7xl">
        {block.title && (
          <CampaignPageSectionHeader title={block.title} titleClassName="text-gray-800" />
        )}
        <div className="mt-10">
          <Tiles
            columns={[2, 4, 5]}
            spacing="sm"
            align="center"
            className="gap-y-6 sm:gap-y-12"
            itemClassName="flex justify-center items-center"
          >
            {logos.map((logo) => {
              if (!logo.url) {
                return (
                  <Image
                    key={logo.image}
                    publicId={logo.image}
                    alt=""
                    width={400}
                    className="block max-w-full max-h-32 w-auto mx-auto"
                  />
                );
              }

              return (
                <Link key={logo.image} href={logo.url} external>
                  <Image
                    publicId={logo.image}
                    alt=""
                    width={400}
                    className="block max-w-full max-h-32 w-auto mx-auto"
                  />
                </Link>
              );
            })}
          </Tiles>
        </div>
      </div>
    </div>
  );
};

CampaignPageLogoGrid.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
};

CampaignPageLogoGrid.defaultProps = {
  className: '',
};

export default CampaignPageLogoGrid;
