import PropTypes from 'prop-types';
import { Children, cloneElement, useState } from 'react';
import { usePopper } from 'react-popper';
import { motion } from 'framer-motion';

import cx from 'lib/cx';

const Shepherd = ({ content, className, show, onHide, placement, hideLabel, children }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [arrowElement, setArrowElement] = useState(null);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }],
  });

  if (Children.count(children) > 1) throw new Error('Shepherd only accepts one child');

  return (
    <>
      {cloneElement(children, { ref: setReferenceElement })}
      {show && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className={cx(
            'z-50 bg-gray-800 rounded-lg text-gray-300 py-3 px-4 text-sm leading-snug whitespace-line-pre',
            {
              'mb-2.5': placement === 'top',
              'mt-2.5': placement === 'bottom',
            },
            className
          )}
        >
          <div
            ref={setArrowElement}
            style={styles.arrow}
            className={cx('z-sub', {
              '-bottom-1': placement === 'top',
              '-top-1': placement === 'bottom',
            })}
          >
            <div className="bg-gray-800 w-6 h-6 rotate-45 rounded-sm" />
          </div>
          {content}
          <p>
            <button
              type="button"
              onClick={onHide}
              className="mt-[0.5em] transition-opacity duration-200 hover:opacity-60 font-medium"
            >
              {hideLabel}
            </button>
          </p>
        </motion.div>
      )}
    </>
  );
};

Shepherd.propTypes = {
  content: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  placement: PropTypes.oneOf(['top', 'bottom']),
  hideLabel: PropTypes.string,
  className: PropTypes.string,
};

Shepherd.defaultProps = {
  className: '',
  placement: 'top',
  hideLabel: 'Got it!',
};

export default Shepherd;
