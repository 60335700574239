import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import { useTabBar } from './TabBar';
import Link from './Link';

const TabBarItem = ({
  icon,
  activeicon,
  label,
  isActive,
  isDisabled,
  as: Component,
  className,
  badge,
  ...props
}) => {
  const { inactiveColor, activeColor } = useTabBar();

  return (
    <Component
      className={cx('flex flex-col justify-center items-center', {
        'opacity-30 pointer-events-none': isDisabled,
      })}
      style={{ color: isActive ? activeColor : inactiveColor }}
      {...props}
    >
      <span className="relative">
        <FontAwesomeIcon
          icon={isDisabled ? faLock : isActive ? (activeicon ?? icon) : icon}
          size="lg"
          fixedWidth
        />
        {badge && <span className="absolute bottom-0 right-0 -mb-1 -mr-1">{badge}</span>}
      </span>
      <span className="mt-1 text-xs font-medium truncate">{label}</span>
    </Component>
  );
};

TabBarItem.propTypes = {
  label: PropTypes.node.isRequired,
  icon: PropTypes.shape({
    iconName: PropTypes.string.isRequired,
  }).isRequired,

  className: PropTypes.string,
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.shape({ render: PropTypes.func }),
  ]),
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  activeicon: PropTypes.shape({
    iconName: PropTypes.string.isRequired,
  }),
  badge: PropTypes.node,
};

TabBarItem.defaultProps = {
  className: '',
  as: Link,
  isActive: false,
  isDisabled: false,
  activeicon: null,
  badge: null,
};

export default TabBarItem;
