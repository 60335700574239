import PropTypes from 'prop-types';
import { useState, useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Image } from 'cloudinary-react';
import { motion } from 'framer-motion';

import useScreen from 'hooks/useScreen';
import AuthMenu from 'components/global/AuthMenu';
import CampaignPageFundraiserNav from 'components/campaign-page/CampaignPageFundraiserNav';
import CampaignSearch from '../common/CampaignSearch';
import Link from '../common/Link';
import Navbar from '../common/Navbar';
import NavbarIconButton from '../common/NavbarIconButton';
import NavbarRoundedButton from '../common/NavbarRoundedButton';

const GET_CAMPAIGN_NAVBAR = gql`
  query GetCampaignNavbar($id: String!) {
    findCampaigns(id: $id) {
      id
      campaignLogo
      ownerOrganization {
        id
        logo
        name
      }
    }
  }
`;

const CampaignPageNavbar = ({ campaignId, actionButton, showSearch }) => {
  const screen = useScreen();
  const [isSearching, setIsSearching] = useState(false);

  const NavbarButton = screen.xl ? NavbarRoundedButton : NavbarIconButton;

  const { data } = useQuery(GET_CAMPAIGN_NAVBAR, { variables: { id: campaignId } });
  const campaign = useMemo(() => data?.findCampaigns[0], [data]);
  const logo = campaign?.campaignLogo ?? campaign?.ownerOrganization.logo;

  if (!campaign) return null;
  return (
    <Navbar
      className="sticky top-0 z-40 h-20 bg-white border-b border-gray-200 shrink-0"
      left={
        <div className="flex items-center">
          <Link href={`/c/${campaignId}`}>
            {logo ? (
              <Image
                publicId={logo}
                alt=""
                width={260}
                height={120}
                crop="limit"
                quality="auto"
                fetchFormat="auto"
                className="block w-40 md:w-auto h-10 object-contain object-left"
              />
            ) : (
              <p className="text-lg font-medium text-gray-800">{campaign.ownerOrganization.name}</p>
            )}
          </Link>
        </div>
      }
      center={
        !screen.lg ? null : (
          <motion.div
            initial={false}
            animate={{ opacity: isSearching ? 0 : 1 }}
            transition={{ duration: isSearching ? 0 : 0.2 }}
          >
            <CampaignPageFundraiserNav campaignId={campaignId} />
          </motion.div>
        )
      }
      right={
        <div className="flex gap-x-1 sm:gap-x-4 items-center">
          {showSearch && (
            <CampaignSearch
              id={campaignId}
              onFocusChange={setIsSearching}
              largeScreenDefaultDisplay="button"
              buttonClassName="text-lg"
            />
          )}
          {actionButton && <NavbarButton {...actionButton} className="text-lg" />}
          <AuthMenu />
        </div>
      }
    />
  );
};

CampaignPageNavbar.propTypes = {
  campaignId: PropTypes.string.isRequired,
  actionButton: PropTypes.shape({}),
  showSearch: PropTypes.bool,
};

CampaignPageNavbar.defaultProps = {
  actionButton: null,
  showSearch: false,
};

export default CampaignPageNavbar;
