import { useMemo, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, gql, useApolloClient } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { motion } from 'framer-motion';

import { useAuth } from 'context/Auth';
import Modal from 'components/common/Modal';
import ModalPage from 'components/common/ModalPage';
import AppBar from 'components/common/AppBar';
import AppBarToolbar from 'components/common/AppBarToolbar';
import AppBarTitle from 'components/common/AppBarTitle';
import AppBarIcon from 'components/common/AppBarIcon';
import Tiles from 'components/common/Tiles';
import FundraiserAdminMilestoneAchievementModal from './FundraiserAdminMilestoneAchievementModal';
import FundraiserAdminConfirmEventPerformance from './FundraiserAdminConfirmEventPerformance';
import FundraiserAdminEditUpdateForm, {
  FUNDRAISING_UPDATE_EDIT_FIELDS,
} from './FundraiserAdminEditUpdateForm';

const GET_FUNDRAISER = gql`
  ${FUNDRAISING_UPDATE_EDIT_FIELDS}

  query GetFundraisingUpdateFundraiser($id: String!) {
    findFundraisers(id: $id) {
      id
      userId
      performanceEstimate
      performanceSettings {
        id
        metricLabelPlural
      }
      status
      stats {
        id
        actualAmountRaised
        aggregatedPerformanceResult
      }
      campaign {
        id
        activeFeatures {
          activityTracking
          event
        }
        performanceMode
        performanceStatus
        ownerOrganization {
          id
          name
        }
      }
      updates {
        ...FundraisingUpdateEditFields
      }
      awardedAchievements(achievementCode: "performanceGoal") {
        fundraiserAchievementId
        achievementCode
        achievedAt
        seenAt
        name
        value
        image
      }
    }
  }
`;

const FundraiserAdminEditUpdate = ({ initialValues, onHide, onComplete }) => {
  const { user } = useAuth();
  const client = useApolloClient();
  const [confirmPerformance, setConfirmPerformance] = useState(null);
  const awardedAchievement = useRef(null);

  const { data } = useQuery(GET_FUNDRAISER, { variables: { id: initialValues?.fundraiserId } });
  const fundraiser = useMemo(() => data?.findFundraisers?.[0], [data]);
  const campaign = useMemo(() => fundraiser?.campaign, [fundraiser]);
  const userIsFundraiser = user.id === fundraiser?.userId;

  const canShowMilestone = useMemo(
    () =>
      userIsFundraiser &&
      campaign?.activeFeatures.activityTracking &&
      campaign?.performanceStatus === 'performing' &&
      campaign?.performanceMode === 'interval',
    [userIsFundraiser, campaign]
  );

  const update = useMemo(
    () =>
      initialValues.id
        ? (fundraiser?.updates ?? []).find((x) => x.id === initialValues.id)
        : initialValues,
    [initialValues, fundraiser]
  );

  const hideModal = useCallback(() => {
    if (!awardedAchievement.current) onHide();
  }, [onHide]);

  useEffect(() => {
    if (!fundraiser || !canShowMilestone) return;
    const achievement = fundraiser.awardedAchievements[0];
    if (achievement?.value !== null && achievement?.seenAt === null) {
      awardedAchievement.current = achievement;
    }
  }, [fundraiser, canShowMilestone]);

  const isLoading = !fundraiser || !update;
  const isCreating = !initialValues.id;
  const title = `${!isCreating ? 'Edit' : 'Add'} update`;

  if (!fundraiser) return null;

  if (awardedAchievement.current) {
    return (
      <Modal show onHide={onHide} className="lg:max-w-sm">
        <div className="px-6 py-8 md:px-8 md:pt-10 md:pb-6 relative">
          <button type="button" className="absolute" onClick={onHide}>
            <FontAwesomeIcon icon={faTimes} size="2x" className="relative -top-4 -left-2" />
          </button>
          <FundraiserAdminMilestoneAchievementModal
            fundraiserId={fundraiser.id}
            achievementId={awardedAchievement.current?.fundraiserAchievementId}
            image={awardedAchievement.current?.image}
            milestone={awardedAchievement.current?.value}
            performanceEstimate={fundraiser?.performanceEstimate}
            aggregatedPerformance={fundraiser?.stats.aggregatedPerformanceResult}
            metricLabelPlural={fundraiser?.performanceSettings.metricLabelPlural}
            onHide={onHide}
          />
        </div>
      </Modal>
    );
  }

  if (confirmPerformance) {
    return (
      <FundraiserAdminConfirmEventPerformance
        fundraiserId={initialValues.fundraiserId}
        performed={confirmPerformance.performanceUnits}
        total={fundraiser.stats.aggregatedPerformanceResult}
        onHide={onHide}
      />
    );
  }

  return (
    <ModalPage onHide={onHide} hideOnOverlayClick={false} className="lg:max-w-md" show>
      <AppBar className="z-10">
        <AppBarToolbar>
          <Tiles spacing="sm">
            <button type="button" onClick={onHide}>
              <AppBarIcon icon={faTimes} />
            </button>
          </Tiles>
          <AppBarTitle title={title} className="md:font-medium" />
          <Tiles />
        </AppBarToolbar>
      </AppBar>
      <motion.div
        key={isLoading ? 'loaded' : 'loading'}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        {!isLoading ? (
          <FundraiserAdminEditUpdateForm
            initialValues={update}
            onComplete={(savedUpdate) => {
              onComplete(savedUpdate);
              client.refetchQueries({ include: ['GetParticipantProgress'] });

              if (
                campaign.performanceMode === 'event' &&
                savedUpdate.performanceUnits &&
                isCreating &&
                fundraiser.status === 'active'
              ) {
                setConfirmPerformance(savedUpdate);
                return;
              }
              hideModal();
            }}
            onDelete={() => {
              client.refetchQueries({ include: ['GetParticipantProgress'] });
              onHide();
            }}
          />
        ) : (
          <div className="h-24 flex justify-center items-center">
            <FontAwesomeIcon icon={faSpinner} size="lg" className="text-gray-400" spin />
          </div>
        )}
      </motion.div>
    </ModalPage>
  );
};

FundraiserAdminEditUpdate.propTypes = {
  initialValues: PropTypes.shape({
    fundraiserId: PropTypes.string.isRequired,
    id: PropTypes.string,
    embeddedMedia: PropTypes.shape({
      url: PropTypes.string,
      service: PropTypes.string,
    }),
    message: PropTypes.arrayOf(PropTypes.shape({})),
    performanceUnits: PropTypes.string,
    shouldSendToDonors: PropTypes.bool,
  }).isRequired,
  onHide: PropTypes.func.isRequired,
  onComplete: PropTypes.func,
};

FundraiserAdminEditUpdate.defaultProps = {
  onComplete: () => {},
};

export default FundraiserAdminEditUpdate;
