import { ElementType } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

type DataTableHeadingProps<C extends ElementType> = PolymorphicComponentProp<
  C,
  {
    align?: 'left' | 'center' | 'right';
    isSortable?: boolean;
    isSorted?: boolean;
    sortDirection?: 'asc' | 'desc';
    className?: string;
  }
>;

const DataTableHeading = <C extends ElementType = 'th'>({
  as,
  align = 'left',
  isSortable = false,
  isSorted = false,
  sortDirection = null,
  className = '',
  children = null,
  ...props
}: DataTableHeadingProps<C>) => {
  const Component = as || 'th';
  return (
    <Component
      className={cx(
        'uppercase text-sm text-gray-600 tracking-wide font-normal py-3 px-4 md:first:pl-8 md:last:pr-8 transition-colors duration-200',
        {
          'cursor-pointer hover:text-gray-800': isSortable,
          'text-gray-800 hover:text-gray-800': isSorted,
          'text-left': align === 'left',
          'text-center': align === 'center',
          'text-right': align === 'right',
          'font-medium': isSorted,
        },
        className
      )}
      {...props}
    >
      <div
        className={cx('inline-flex items-center overflow-hidden', {
          'justify-start': align === 'left',
          'justify-center': align === 'center',
          'justify-end': align === 'right',
          'text-gray-800 hover:text-gray-800': isSorted,
          'font-medium': isSorted,
        })}
      >
        <span className="truncate">{children}</span>
        {isSortable && (
          <span className="shrink-0">
            <FontAwesomeIcon
              icon={sortDirection === 'asc' ? faArrowUp : faArrowDown}
              size="1x"
              className={cx('ml-1', { invisible: !isSorted })}
              fixedWidth
            />
          </span>
        )}
      </div>
    </Component>
  );
};

export default DataTableHeading;
