import type { PropsWithChildren } from 'react';

import cx from 'lib/cx';

type FormFieldGroupProps = PropsWithChildren<{
  className?: string;
}>;

const FormFieldGroup = ({ children, className }: FormFieldGroupProps) => (
  <div className={cx('grid grid-cols-1 gap-y-6', className)}>{children}</div>
);

export default FormFieldGroup;
