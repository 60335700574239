import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { gql, useQuery } from '@apollo/client';
import {
  faMessageSmile,
  faUser,
  faUserGroup,
  faStopwatch,
  faDonate,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faMessageSmile as faMessageSmileSolid,
  faUser as faUserSolid,
  faUserGroup as faUserGroupSolid,
  faStopwatch as faStopwatchSolid,
  faDonate as faDonateSolid,
} from '@fortawesome/pro-solid-svg-icons';

import useBreakpoint from 'hooks/useBreakpoint';
import NavbarLink from 'components/common/NavbarLink';
import TabBar from 'components/common/TabBar';
import TabBarItem from 'components/common/TabBarItem';

const GET_MY_FUNDRAISER = gql`
  query GetMyFundraiser($where: SequelizeJSON!) {
    me {
      id
      fundraisers(where: $where) {
        id
        teamId
        status

        campaign {
          id
          isPerformanceEnabled
          performanceMode
          performanceTrackingLevel

          supportedFeatures {
            activityTracking
          }
        }
      }
    }
  }
`;

const CampaignPageFundraiserNav = ({ campaignId }) => {
  const router = useRouter();
  const mobile = !useBreakpoint('lg');

  const { data } = useQuery(GET_MY_FUNDRAISER, {
    variables: {
      where: { campaignId },
    },
  });

  const fundraiser = useMemo(() => data?.me?.fundraisers[0], [data]);
  const supportedFeatures = useMemo(() => fundraiser?.campaign.supportedFeatures, [fundraiser]);

  if (!fundraiser) return null;

  const links = [
    {
      label: 'Community',
      href: `/manage/f/${fundraiser.id}/community`,
      icon: faMessageSmile,
      activeicon: faMessageSmileSolid,
    },
    {
      label: 'My page',
      href: `/f/${fundraiser.id}`,
      icon: faUser,
      activeicon: faUserSolid,
    },
    ...(fundraiser.teamId
      ? [
          {
            label: 'Team',
            href: `/manage/t/${fundraiser.teamId}`,
            icon: faUserGroup,
            activeicon: faUserGroupSolid,
          },
        ]
      : []),
    ...(supportedFeatures?.activityTracking
      ? [
          {
            label: 'Activity',
            href: `/manage/f/${fundraiser.id}/updates`,
            icon: faStopwatch,
            activeicon: faStopwatchSolid,
          },
        ]
      : []),
    {
      label: 'Donors',
      href: `/manage/f/${fundraiser.id}/donors`,
      icon: faDonate,
      activeicon: faDonateSolid,
    },
  ];

  if (mobile) {
    return (
      <div className="shrink-0 sticky bottom-0 z-30 shadow-2xl">
        <TabBar theme="light">
          {links.map((link) => (
            <TabBarItem key={link.href} isActive={router.asPath.startsWith(link.href)} {...link} />
          ))}
        </TabBar>
      </div>
    );
  }

  return (
    <nav className="flex items-center gap-x-1 -mx-2">
      {links.map((link) => (
        <NavbarLink key={link.href} isActive={router.asPath.startsWith(link.href)} {...link} />
      ))}
    </nav>
  );
};

CampaignPageFundraiserNav.propTypes = {
  campaignId: PropTypes.string.isRequired,
};

CampaignPageFundraiserNav.defaultProps = {};

export default CampaignPageFundraiserNav;
