import { forwardRef } from 'react';

import cx from '@/lib/cx';
import Link from './Link';
import style from './Button.module.css';

type ButtonProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<
  C,
  {
    color?:
      | 'white'
      | 'primary'
      | 'secondary'
      | 'green'
      | 'teal'
      | 'teal-light'
      | 'gray-300'
      | 'gray-800'
      | 'red'
      | 'purple'
      | 'facebook'
      | 'twitter'
      | 'strava'
      | 'none';
    outline?: boolean;
    outlineStyle?: 'solid' | 'dashed';
    padding?: 'xs' | 'xs-even' | 'sm' | 'sm-even' | 'default' | 'default-even';
    radius?: 'full' | 'default';
    align?: 'left' | 'center' | 'right' | 'between';
    disabled?: boolean;
  }
>;

type ButtonComponent = (<C extends React.ElementType = typeof Link>(
  props: ButtonProps<C>
) => React.ReactElement | null) & { displayName?: string };

// @ts-expect-error -- this gets handled by strictly typing the component above
const Button: ButtonComponent = forwardRef(
  <C extends React.ElementType = typeof Link>(
    {
      as,
      color = 'primary',
      outline = false,
      outlineStyle = 'solid',
      padding = 'default',
      radius = 'default',
      align = 'center',
      className = undefined,
      children = null,
      disabled = false,
      ...props
    }: ButtonProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Component = as || Link;
    return (
      <Component
        ref={ref}
        className={cx(
          'inline-block border-2 transition-all duration-200 cursor-pointer',

          { 'opacity-25 pointer-events-none': disabled },

          { 'border-dashed': outlineStyle === 'dashed' },

          {
            'p-1': padding === 'xs-even',
            'py-1 px-3': padding === 'xs',
            'p-2': padding === 'sm-even',
            'py-2 px-4': padding === 'sm',

            'p-3': padding === 'default-even',
            'py-3 px-6': padding === 'default',
          },

          {
            'rounded-full': radius === 'full',
            'rounded-md': radius === 'default',
          },

          // Color schemes
          {
            // None
            [style.colorNone]: color === 'none',

            // White
            [style.colorWhiteDefault]: color === 'white' && !outline,
            [style.colorWhiteOutline]: color === 'white' && outline,

            // Primary (Customizable w/ theme)
            [style.colorPrimaryDefault]: color === 'primary' && !outline,
            [style.colorPrimaryOutline]: color === 'primary' && outline,

            // Secondary (Customizable w/ theme)
            [style.colorSecondaryDefault]: color === 'secondary' && !outline,
            [style.colorSecondaryOutline]: color === 'secondary' && outline,

            // Teal (Forced PledgeIt primary color)
            [style.colorTealDefault]: color === 'teal' && !outline,
            [style.colorTealOutline]: color === 'teal' && outline,

            // Green (Forced PledgeIt secondary color)
            [style.colorGreenDefault]: color === 'green' && !outline,
            [style.colorGreenOutline]: color === 'green' && outline,

            // Teal Light
            [style.colorTealLightDefault]: color === 'teal-light' && !outline,
            [style.colorTealLightOutline]: color === 'teal-light' && outline,

            // Gray-300
            [style.colorGray300Default]: color === 'gray-300' && !outline,
            [style.colorGray300Outline]: color === 'gray-300' && outline,

            // Gray-800
            [style.colorGray800Default]: color === 'gray-800' && !outline,
            [style.colorGray800Outline]: color === 'gray-800' && outline,

            // Red
            [style.colorRedDefault]: color === 'red' && !outline,
            [style.colorRedOutline]: color === 'red' && outline,

            // Purple
            [style.colorPurpleDefault]: color === 'purple' && !outline,
            [style.colorPurpleOutline]: color === 'purple' && outline,

            // Facebook
            [style.colorFacebookDefault]: color === 'facebook' && !outline,
            [style.colorFacebookOutline]: color === 'facebook' && outline,

            // Twitter
            [style.colorTwitterDefault]: color === 'twitter' && !outline,
            [style.colorTwitterOutline]: color === 'twitter' && outline,

            // Strava
            [style.colorStravaDefault]: color === 'strava' && !outline,
            [style.colorStravaOutline]: color === 'strava' && outline,
          },

          className
        )}
        {...props}
        data-button
      >
        <span
          className={cx('flex items-center', {
            'justify-start': align === 'left',
            'justify-center': align === 'center',
            'justify-end': align === 'right',
            'justify-between': align === 'between',
          })}
        >
          {children}
        </span>
      </Component>
    );
  }
);

Button.displayName = 'Button';

export default Button;
