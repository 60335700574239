import { ElementType, ReactNode } from 'react';
import { faArrowUp, faArrowDown } from '@fortawesome/pro-regular-svg-icons';

import PopMenu from 'components/common/PopMenu';
import DataTableHeading from 'components/common/DataTableHeading';
import ContextMenuLink from 'components/common/ContextMenuLink';

type DataTableColumn = {
  id: string;
  toggleHidden: (isHidden: boolean) => any;
  render: (label: string) => ReactNode;
  canSort: boolean;
  isSorted: boolean;
  isSortedDesc?: boolean;
  toggleSortBy: () => void;
};

type DataTableMobileSortProps<C extends ElementType> = PolymorphicComponentProp<
  C,
  {
    title: string;
    columns: DataTableColumn[];
    alwaysVisible?: string[];
    exclude?: string[];
  }
>;

const DataTableMobileSort = <C extends ElementType = 'th'>({
  as,
  title,
  columns,
  alwaysVisible = [],
  exclude = [],
}: DataTableMobileSortProps<C>) => {
  const Component = as || 'th';
  const sortableColumns = columns.filter((x) => x.canSort && !exclude.includes(x.id));
  const activeColumn = columns.find((x) => x.isSorted);

  const onChange = (id) => {
    // Only sort order needs to be flipped
    if (id === activeColumn.id) {
      activeColumn.toggleSortBy();
      return;
    }

    sortableColumns.forEach((column) => {
      const isSelectedColumn = column.id === id;

      if (!alwaysVisible.includes(id)) {
        column.toggleHidden(!(alwaysVisible.includes(column.id) || isSelectedColumn));
      }

      if (isSelectedColumn) column.toggleSortBy();
    });
  };

  return (
    <Component className="text-left" colSpan={Component === 'th' ? 2 : undefined}>
      <PopMenu
        title={title}
        trigger={
          <DataTableHeading
            as="div"
            align="left"
            sortDirection={activeColumn.isSortedDesc ? 'desc' : 'asc'}
            isSortable
            isSorted
          >
            {activeColumn.render('Header')}
          </DataTableHeading>
        }
      >
        <div className="px-3">
          {sortableColumns.map((column) => (
            <ContextMenuLink
              key={column.id}
              as="button"
              type="button"
              onClick={() => onChange(column.id)}
              label={column.render('Header')}
              isActive={column.isSorted}
              icon={column.isSorted ? (column.isSortedDesc ? faArrowDown : faArrowUp) : null}
              theme="rounded"
            />
          ))}
        </div>
      </PopMenu>
    </Component>
  );
};

export default DataTableMobileSort;
