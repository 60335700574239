import { motion } from 'framer-motion';

import config from 'config';

type ProgressBarProps = { percent: number; bgColor?: string; barColor?: string };

const ProgressBar = ({
  percent,
  bgColor = config('/tw/colors/gray/400'),
  barColor = config('/tw/colors/gray/400'),
}: ProgressBarProps) => {
  const cappedPercent = Math.min(percent, 100);
  return (
    <div className="w-full relative h-3 rounded-full" style={{ background: bgColor }}>
      <motion.div
        className="absolute left-0 inset-y-0 bg-theme-secondary rounded-full"
        initial={{ width: 0 }}
        animate={{ width: `${cappedPercent}%` }}
        transition={{ duration: 0.6, easing: 'easeOut' }}
        style={{ background: barColor }}
      />
    </div>
  );
};

export default ProgressBar;
