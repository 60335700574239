import { useMemo, ElementType } from 'react';
import cx from 'classnames';

import useBreakpoint from 'hooks/useBreakpoint';

type CardProps<C extends ElementType> = PolymorphicComponentProp<
  C,
  {
    at?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
    radius?: 'none' | 'sm' | 'md' | 'lg';
    depth?: 'none' | 'sm' | 'md' | 'lg';
    padding?: 'none' | 'min' | '2xs' | 'xs' | 'sm' | 'md' | 'lg';
    color?: 'white' | 'purple' | 'gray-100';
    border?: boolean;
    className?: string;
  }
>;

const Card = <C extends ElementType = 'div'>({
  as,
  at = null,
  radius = 'md',
  depth = 'md',
  padding = 'sm',
  color = 'white',
  border = false,
  className = '',
  children = null,
  ...props
}: CardProps<C>) => {
  const Component = as || 'div';
  const isBreakpoint = useBreakpoint(at ?? 'sm');
  const isActive = useMemo(() => at === null || isBreakpoint, [at, isBreakpoint]);

  return (
    <Component
      className={cx(
        {
          'bg-white': isActive && color === 'white',
          'bg-purple-100': isActive && color === 'purple',
          'bg-gray-100': isActive && color === 'gray-100',

          border,
          'border-gray-400': border && ['gray-100', 'white'].includes(color),
          'border-purple-200': border && color === 'purple',

          'rounded-none': isActive && radius === 'none',
          'rounded-md': isActive && radius === 'sm',
          'rounded-xl': isActive && radius === 'md',
          'rounded-2xl': isActive && radius === 'lg',

          shadow: isActive && depth === 'sm',
          'shadow-lg': isActive && depth === 'md',
          'shadow-xl': isActive && depth === 'lg',

          'p-0': isActive && padding === 'none',
          'p-px': isActive && padding === 'min',
          'p-4': isActive && padding === '2xs',
          'p-6': isActive && padding === 'xs',
          'p-8': isActive && padding === 'sm',
          'p-12': isActive && padding === 'md',
          'p-16': isActive && padding === 'lg',
        },
        'block',
        className
      )}
      {...props}
    >
      {children}
    </Component>
  );
};

export default Card;
