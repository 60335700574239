import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import states from 'data/states.json';
import provinces from 'data/provinces.json';
import countries from 'data/countries.json';
import FormFieldGroup from './FormFieldGroup';
import FormNode from './FormNode';
import FormLabel from './FormLabel';
import FormNote from './FormNote';
import FormInput from './FormInput';
import FormSelect from './FormSelect';

const COUNTRY_STATES = {
  US: states,
  CA: provinces,
};

const COUNTRY_STATE_LABELS = {
  US: 'State',
  CA: 'Province',
};

const FormAddressFields = ({ label, helpText, isRequired, getFieldName, hideLabels }) => {
  const { control, formState, watch, setValue } = useFormContext();
  const { errors } = formState;

  const [state, country] = watch([getFieldName('state'), getFieldName('country')]);

  const stateList = useMemo(() => {
    const predefined = COUNTRY_STATES[country];
    if (!predefined) return null;
    if (!state) return predefined;
    return predefined.some((x) => x.code === state)
      ? predefined
      : [...predefined, { label: state, code: state }];
  }, [state, country]);

  const countryList = useMemo(() => {
    if (!country) return countries;
    return countries.some((x) => x.code === country)
      ? countries
      : [...countries, { code: country, label: country }];
  }, [country]);

  return (
    <FormFieldGroup>
      <FormNode>
        <div>
          {!hideLabels && (
            <FormLabel htmlFor={getFieldName('address1')} isRequired={isRequired}>
              {label}
            </FormLabel>
          )}
          {helpText && <FormNote>{helpText}</FormNote>}
        </div>
        <Controller
          control={control}
          name={getFieldName('address1')}
          render={({ field, fieldState }) => (
            <FormInput
              type="text"
              placeholder="Street address, P.O. box"
              status={fieldState.error ? 'error' : 'default'}
              {...field}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name={getFieldName('address1')}
          render={({ message }) => <FormNote status="error">{message}</FormNote>}
        />
      </FormNode>
      <FormNode>
        {!hideLabels && <FormLabel htmlFor={getFieldName('address2')}>Address 2</FormLabel>}
        <Controller
          control={control}
          name={getFieldName('address2')}
          render={({ field, fieldState }) => (
            <FormInput
              type="text"
              placeholder="Apt., Suite, Bldg, etc."
              status={fieldState.error ? 'error' : 'default'}
              {...field}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name={getFieldName('address2')}
          render={({ message }) => <FormNote status="error">{message}</FormNote>}
        />
      </FormNode>
      <FormNode>
        {!hideLabels && (
          <FormLabel htmlFor={getFieldName('city')} isRequired={isRequired}>
            City
          </FormLabel>
        )}
        <Controller
          control={control}
          name={getFieldName('city')}
          render={({ field, fieldState }) => (
            <FormInput
              type="text"
              placeholder="Beverly Hills"
              status={fieldState.error ? 'error' : 'default'}
              {...field}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name={getFieldName('city')}
          render={({ message }) => <FormNote status="error">{message}</FormNote>}
        />
      </FormNode>
      <div className="grid grid-cols-2 gap-4 items-start">
        <FormNode>
          {!hideLabels && (
            <FormLabel
              id="stateProvLabel"
              htmlFor={getFieldName('stateProv')}
              isRequired={isRequired}
            >
              {COUNTRY_STATE_LABELS[country] ?? 'Region'}
            </FormLabel>
          )}
          <Controller
            control={control}
            name={getFieldName('stateProv')}
            render={({ field, fieldState }) =>
              stateList ? (
                <FormSelect
                  placeholder="-"
                  options={stateList.map((x) => ({ label: x.code, value: x.code }))}
                  status={fieldState.error ? 'error' : 'default'}
                  isSearchable={false}
                  isClearable={!isRequired}
                  aria-labelledby="stateProvLabel"
                  {...field}
                />
              ) : (
                <FormInput type="text" status={fieldState.error ? 'error' : 'default'} {...field} />
              )
            }
          />
          <ErrorMessage
            errors={errors}
            name={getFieldName('stateProv')}
            render={({ message }) => (
              <FormNote className="col-start-1" status="error">
                {message}
              </FormNote>
            )}
          />
        </FormNode>

        <FormNode>
          {!hideLabels && (
            <FormLabel htmlFor={getFieldName('postalCode')} isRequired={isRequired}>
              Zip
            </FormLabel>
          )}
          <Controller
            control={control}
            name={getFieldName('postalCode')}
            render={({ field, fieldState }) => (
              <FormInput
                type="text"
                placeholder="90210"
                status={fieldState.error ? 'error' : 'default'}
                c
                {...field}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name={getFieldName('postalCode')}
            render={({ message }) => (
              <FormNote className="col-start-1" status="error">
                {message}
              </FormNote>
            )}
          />
        </FormNode>
      </div>
      <FormNode>
        {!hideLabels && (
          <FormLabel id="countryLabel" htmlFor={getFieldName('country')} isRequired={isRequired}>
            Country
          </FormLabel>
        )}
        <Controller
          control={control}
          name={getFieldName('country')}
          render={({ field, fieldState }) => (
            <FormSelect
              options={countryList.map((x) => ({ label: x.label, value: x.code }))}
              onChange={(newValue) => {
                field.onChange(newValue);

                // Reset the stateProv field if country has changed
                if (newValue !== country && state) setValue(getFieldName('stateProv'), '');
              }}
              status={fieldState.error ? 'error' : 'default'}
              isClearable={false}
              aria-labelledby="countryLabel"
              {...field}
              value={field.value}
            />
          )}
        />
        <ErrorMessage
          errors={errors}
          name={getFieldName('country')}
          render={({ message }) => <FormNote status="error">{message}</FormNote>}
        />
      </FormNode>
    </FormFieldGroup>
  );
};

FormAddressFields.propTypes = {
  label: PropTypes.node,
  helpText: PropTypes.node,
  isRequired: PropTypes.bool,
  getFieldName: PropTypes.func,
  hideLabels: PropTypes.bool,
};

FormAddressFields.defaultProps = {
  label: 'Address',
  helpText: null,
  isRequired: false,
  getFieldName: (x) => x,
  hideLabels: false,
};

export default FormAddressFields;
