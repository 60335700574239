import PropTypes from 'prop-types';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Image } from 'cloudinary-react';

import Navbar from 'components/common/Navbar';
import NavbarIconButton from 'components/common/NavbarIconButton';
import NavbarTitle from 'components/common/NavbarTitle';
import { getNextStep, getPreviousStep, setStep, useStep } from './useDonationFormStep';
import { useDonationFormWidget } from './useDonationFormWidget';

const DonationFormHeader = ({ isPreview, onClose }) => {
  const step = useStep();
  const previousStep = getPreviousStep();
  const canGoBack = previousStep && step !== 'confirmation';
  const canClose = !!onClose && !getNextStep();

  const { campaign } = useDonationFormWidget();
  const { name, logo } = campaign.ownerOrganization;
  const headerLogo = campaign.campaignLogo ?? logo;

  return (
    <Navbar
      className="px-8 py-4 h-24 shrink-0 bg-white border-b border-gray-400"
      left={
        canGoBack && (
          <NavbarIconButton
            as="button"
            type="button"
            icon={faChevronLeft}
            onClick={() => {
              if (isPreview) return;
              setStep(previousStep);
            }}
            className="-ml-2"
            border
          />
        )
      }
      center={
        headerLogo ? (
          <Image
            publicId={headerLogo}
            alt=""
            width={260}
            height={120}
            crop="limit"
            quality="auto"
            fetchFormat="auto"
            className="block h-10"
          />
        ) : (
          <NavbarTitle title={name} />
        )
      }
      right={
        canClose && (
          <NavbarIconButton
            as="button"
            type="button"
            icon={faTimes}
            onClick={() => {
              if (isPreview) return;
              onClose();
            }}
            className="-mr-2"
            border
          />
        )
      }
    />
  );
};

DonationFormHeader.propTypes = {
  isPreview: PropTypes.bool,
  onClose: PropTypes.func,
};

DonationFormHeader.defaultProps = {
  isPreview: false,
  onClose: null,
};

export default DonationFormHeader;
