import { ElementType } from 'react';
import cx from 'classnames';

type DataTableCellProps<C extends ElementType> = PolymorphicComponentProp<
  C,
  {
    align?: 'left' | 'center' | 'right';
    valign?: 'top' | 'middle' | 'bottom';
    padding?: 'default' | 'sm';
    className?: string;
  }
>;

const DataTableCell = <C extends ElementType = 'td'>({
  as,
  align = 'left',
  valign = 'middle',
  padding = 'default',
  className = '',
  children = null,
  ...props
}: DataTableCellProps<C>) => {
  const Component = as || 'td';
  return (
    <Component
      className={cx(
        'py-3 px-4',
        {
          'md:first:pl-8 md:last:pl-8': padding === 'default',
          'md:first:pl-4 md:last:pl-4': padding === 'sm',
        },
        className
      )}
      {...props}
    >
      <div
        className={cx('h-full flex', {
          'justify-start text-left': align === 'left',
          'justify-center text-center': align === 'center',
          'justify-end text-right': align === 'right',
          'items-start': valign === 'top',
          'items-center': valign === 'middle',
          'items-end': valign === 'bottom',
        })}
      >
        <div className="overflow-hidden">{children}</div>
      </div>
    </Component>
  );
};

export default DataTableCell;
