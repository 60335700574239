import PropTypes from 'prop-types';
import { Children, useRef, useCallback } from 'react';
import Masonry from 'react-masonry-component';
import { useIsomorphicEffect } from 'rooks';
import findLastIndex from 'lodash/findLastIndex';
import last from 'lodash/last';
import cx from 'classnames';

import useScreen from 'hooks/useScreen';
import style from './Tiles.module.css';

const MasonryTiles = ({ spacing, columns, className, children }) => {
  const items = Children.toArray(children);
  const element = useRef(null);
  const screen = useScreen();

  const getResponsiveClassName = useCallback(
    (values, classMap) => {
      const screens = [true, screen.md, screen.lg, screen.xl];
      const valueArray = [].concat(values);

      const screenIndex = findLastIndex(screens, (x) => x);
      const matchingValue =
        valueArray.length > screenIndex ? valueArray[screenIndex] : last(valueArray);
      const matchingClass = classMap[matchingValue || 'false'];
      return style[matchingClass];
    },
    [screen]
  );

  useIsomorphicEffect(() => {
    const cards = element.current.masonry.getItemElements();
    const onIframelyRender = () => element.current.masonry.layout();

    cards.forEach((card) => card.addEventListener('iframely:render', onIframelyRender));

    return () => {
      cards.forEach((card) => card.removeEventListener('iframely:render', onIframelyRender));
    };
  }, [element]);

  return (
    <Masonry
      ref={element}
      className={cx([
        getResponsiveClassName(spacing, {
          '2xs': 'tiles_containerSpacing2xs',
          xs: 'tiles_containerSpacingXs',
          sm: 'tiles_containerSpacingSm',
          md: 'tiles_containerSpacingMd',
          lg: 'tiles_containerSpacingLg',
          xl: 'tiles_containerSpacingXl',
        }),
        className,
      ])}
    >
      {items.map((item, i) => (
        <div
          key={i}
          className={cx([
            getResponsiveClassName(spacing, {
              '2xs': 'tiles_tileSpacing2xs',
              xs: 'tiles_tileSpacingXs',
              sm: 'tiles_tileSpacingSm',
              md: 'tiles_tileSpacingMd',
              lg: 'tiles_tileSpacingLg',
              xl: 'tiles_tileSpacingXl',
            }),
            getResponsiveClassName(columns, {
              false: 'tiles_tileWidthAuto',
              1: 'tiles_tileWidth1',
              2: 'tiles_tileWidth2',
              3: 'tiles_tileWidth3',
              4: 'tiles_tileWidth4',
              5: 'tiles_tileWidth5',
            }),
          ])}
        >
          {item}
        </div>
      ))}
    </Masonry>
  );
};

MasonryTiles.propTypes = {
  spacing: PropTypes.oneOfType([
    PropTypes.oneOf(['none', '2xs', 'xs', 'sm', 'md', 'lg', 'xl']),
    PropTypes.arrayOf(PropTypes.oneOf(['none', '2xs', 'xs', 'sm', 'md', 'lg', 'xl'])),
  ]),
  columns: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

MasonryTiles.defaultProps = {
  spacing: 'none',
  columns: null,
  className: '',
  children: null,
};

export default MasonryTiles;
