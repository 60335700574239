import { ElementType } from 'react';
import cx from 'classnames';
import Highlight from 'react-highlight-words';
import { motion } from 'framer-motion';

import Avatar from './Avatar';
import Link from './Link';

type SearchResultProps<C extends ElementType> = PolymorphicComponentProp<
  C,
  {
    image: string;
    title: string;
    initials?: string;
    description?: string;
    highlightDescription?: boolean;
    highlight?: string;
    className?: string;
  }
>;

const SearchResult = <C extends ElementType = typeof Link>({
  as,
  image,
  title,
  initials = null,
  highlight = '',
  description = null,
  highlightDescription = false,
  className = '',
  ...props
}: SearchResultProps<C>) => {
  const Component = as || Link;
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <Component
        className={cx(
          'flex items-center py-2 px-4 hover:bg-gray-300 transition-colors duration-200',
          className
        )}
        {...props}
      >
        <Avatar image={image} initials={initials} size="sm" />
        <div className="pl-4 overflow-hidden">
          <p className="truncate leading-tight">
            <Highlight
              textToHighlight={title}
              searchWords={[highlight]}
              highlightClassName="font-medium"
              highlightTag="span"
              autoEscape
            />
          </p>
          {description && (
            <p className="truncate leading-tight text-sm text-gray-600">
              <Highlight
                textToHighlight={description}
                searchWords={highlightDescription ? [highlight] : []}
                highlightClassName="font-medium"
                highlightTag="span"
                autoEscape
              />
            </p>
          )}
        </div>
      </Component>
    </motion.div>
  );
};

export default SearchResult;
