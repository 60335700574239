// This component is to be used to wrap anything that you want to prevent click
// events bubbling from. For example: a context menu that's inside of a clickable
// table row or card.

import { ElementType } from 'react';

type ClickStopProps<C extends ElementType> = PolymorphicComponentProp<C, {}>;

// eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
const ClickStop = <C extends ElementType = 'div'>({ as, ...props }: ClickStopProps<C>) => {
  const Component = as || 'div';
  return <Component onClick={(e) => e.stopPropagation()} {...props} />;
};

export default ClickStop;
