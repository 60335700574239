import { Editor, Transforms, Node, Element } from 'slate';
import { ReactEditor } from 'slate-react';
import { HistoryEditor } from 'slate-history';
import isUrl from 'is-url';

const CustomSlateEditor = {
  ...Editor,
  ...ReactEditor,
  ...HistoryEditor,

  ensureSelection: (editor) => {
    if (!editor.selection) Transforms.select(editor, Editor.end(editor, []));
  },

  isMarkActive: (editor, mark) => {
    try {
      const marks = Editor.marks(editor);
      return marks ? marks[mark] === true : false;
    } catch {
      return false;
    }
  },

  toggleMark: (editor, mark) => {
    const isActive = CustomSlateEditor.isMarkActive(editor, mark);
    if (isActive) return Editor.removeMark(editor, mark);
    return Editor.addMark(editor, mark, true);
  },

  isUnorderedListActive: (editor) => {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.type === 'bulleted-list',
    });

    return !!match;
  },

  isElementActive: (editor, element) => {
    const [match] = Editor.nodes(editor, {
      match: (n) => n.type === element,
    });

    return !!match;
  },

  setElementType: (editor, element) => {
    Transforms.setNodes(editor, { type: element });
  },

  toggleList: (editor, listType) => {
    CustomSlateEditor.ensureSelection(editor);

    // Is already desired list: Reset children to paragraphs & remove list
    if (CustomSlateEditor.isElementActive(editor, listType)) {
      Transforms.unwrapNodes(editor, { match: (n) => n.type === listType, split: true });
      Transforms.setNodes(editor, { type: 'paragraph' });
      return;
    }

    // Is different list type: Convert to desired list
    const [otherMatch] = Editor.nodes(editor, {
      match: (n) => n.type?.endsWith('-list'),
    });

    // Is different list type: Convert to desired list
    if (otherMatch) {
      Transforms.setNodes(editor, { type: listType }, { match: (n) => n.type?.endsWith('-list') });
      return;
    }

    // Is non-list: Convert children to list-items and wrap in a list
    Transforms.wrapNodes(editor, { type: listType, children: [] });
    Transforms.setNodes(editor, { type: 'list-item' });
  },

  toggleBlockQuote: (editor) => {
    CustomSlateEditor.ensureSelection(editor);

    // Is already in a block-quote, unwrap
    if (CustomSlateEditor.isElementActive(editor, 'block-quote')) {
      Transforms.unwrapNodes(editor, { match: (n) => n.type === 'block-quote', split: true });
      return;
    }

    // Wrap selected nodes in a new block-quote
    Transforms.wrapNodes(editor, { type: 'block-quote', children: [] });
  },

  insertHorizontalRule: (editor) => {
    CustomSlateEditor.ensureSelection(editor);

    const [nonReplaceableNode] = Editor.nodes(editor, {
      match: (n) => !!Node.string(n) || editor.isVoid(n),
    });

    // Current node is not empty, need to insert below
    if (nonReplaceableNode) {
      Transforms.insertNodes(editor, { type: 'divider', children: [{ text: '' }] });
      return;
    }

    // Current node is empty, can just set type
    CustomSlateEditor.setElementType(editor, 'divider');
  },

  insertLink: (editor, { text, url }) => {
    const [match] = Editor.nodes(editor, { match: (n) => n.type === 'link' });
    const existingLink = match?.[0];

    if (existingLink) {
      Transforms.setNodes(editor, { url }, { match: (n) => n.type === 'link' });
      Transforms.insertText(editor, text, { at: ReactEditor.findPath(editor, existingLink) });
    } else {
      Transforms.insertNodes(editor, [{ type: 'link', url, children: [{ text }] }, { text: '' }], {
        split: true,
      });
    }
  },

  insertButton: (editor, { text, url }) => {
    const [match] = Editor.nodes(editor, { match: (n) => n.type === 'button' });
    const existingButton = match?.[0];

    if (existingButton) {
      Transforms.setNodes(editor, { url }, { match: (n) => n.type === 'button' });
      Transforms.insertText(editor, text, { at: ReactEditor.findPath(editor, existingButton) });
    } else {
      CustomSlateEditor.ensureSelection(editor);

      const [[parentNode, parentPath]] = Editor.nodes(editor, {
        match: (n, p) => p.length === 1,
      });
      const parentNodeText = Node.string(parentNode);

      if (parentNodeText === '' || parentNodeText === Editor.string(editor, editor.selection)) {
        // If whole element is selected, change to button, overwite children (somehow)
        Transforms.select(editor, parentPath);
        Transforms.setNodes(editor, { type: 'button', url });
        Transforms.insertText(editor, text);
      } else {
        // If partial element is selected, wrap with button, overwrite text
        Transforms.insertNodes(editor, { type: 'button', url, children: [{ text }] });
      }
    }
  },
};

export default CustomSlateEditor;

export const VOID_ELEMENTS = ['divider'];
export const INLINE_ELEMENTS = ['link'];

/* eslint-disable no-param-reassign */
export const withCustomSlateEditor = (editor) => {
  const { isVoid, isInline, insertText, insertData, normalizeNode } = editor;

  editor.isVoid = (element) => {
    if (VOID_ELEMENTS.includes(element.type)) return true;
    return isVoid(element);
  };

  editor.isInline = (element) => {
    if (INLINE_ELEMENTS.includes(element.type)) return true;
    return isInline(element);
  };

  editor.insertText = (text) => {
    // Auto-link urls that are entered
    if (text && isUrl(text)) return CustomSlateEditor.insertLink(editor, { text, url: text });
    return insertText(text);
  };

  editor.insertData = (data) => {
    // Auto-link urls that are pasted
    const text = data.getData('text/plain');
    if (text && isUrl(text)) return CustomSlateEditor.insertLink(editor, { text, url: text });
    return insertData(data);
  };

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    // Ensure the last element in the tree isn't a void
    if (Editor.isEditor(node)) {
      const [lastElement] = Editor.above(editor, {
        match: (n, p) => Element.isElement(n) && p.length === 1,
        at: Editor.end(editor, []),
        voids: true,
      });

      if (editor.isVoid(lastElement)) {
        Transforms.insertNodes(editor, [{ type: 'paragraph', children: [{ text: '' }] }]);
      }

      return;
    }

    if (
      node.type === 'paragraph' &&
      ['paragraph', 'button'].includes(Node.parent(editor, path).type)
    ) {
      Transforms.unwrapNodes(editor, { at: path });
      return;
    }

    // List-items must be wrapped in a list
    if (node.type === 'list-item' && !Node.parent(editor, path).type?.endsWith('-list')) {
      Transforms.setNodes(editor, { type: 'paragraph' }, { at: path });
    }

    // Only list-items can be direct children of lists
    if (
      Element.isElement(node) &&
      Node.parent(editor, path).type?.endsWith('-list') &&
      node.type !== 'list-item'
    ) {
      Transforms.setNodes(editor, { type: 'list-item' }, { at: path });
      return;
    }

    normalizeNode(entry);
  };

  return editor;
};
