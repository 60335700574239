import { ReactNode } from 'react';
import Illustration from '../../svg/empty-search.svg';

const SearchEmptyState = ({ message = null }: { message: ReactNode }) => (
  <div className="py-4">
    <Illustration className="w-64 max-w-full mx-auto" />
    {message && <p className="text-center mt-6 leading-relaxed break-words">{message}</p>}
  </div>
);

export default SearchEmptyState;
