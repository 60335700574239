import { useState, useMemo, useCallback } from 'react';
import orderBy from 'lodash/orderBy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/pro-solid-svg-icons';
import capitalize from 'lodash/capitalize';
import cx from 'classnames';

import useBreakpoint from 'hooks/useBreakpoint';
import { formatNumber, formatCurrency, parseNumber } from 'lib/formatters';
import Avatar from 'components/common/Avatar';
import DataTableCell from 'components/common/DataTableCell';
import DataTableHeading from 'components/common/DataTableHeading';
import DataTableMobileSort from 'components/common/DataTableMobileSort';
import Tiles from 'components/common/Tiles';
import FundraiserRaisedGoldBadge from '../../svg/badge-fundraiser-raised-gold.svg';
import FundraiserRaisedSilverBadge from '../../svg/badge-fundraiser-raised-silver.svg';
import FundraiserRaisedBronzeBadge from '../../svg/badge-fundraiser-raised-bronze.svg';
import FundraiserPerformanceGoldBadge from '../../svg/badge-fundraiser-performance-gold.svg';
import FundraiserPerformanceSilverBadge from '../../svg/badge-fundraiser-performance-silver.svg';
import FundraiserPerformanceBronzeBadge from '../../svg/badge-fundraiser-performance-bronze.svg';
import TeamRaisedGoldBadge from '../../svg/badge-team-raised-gold.svg';
import TeamRaisedSilverBadge from '../../svg/badge-team-raised-silver.svg';
import TeamRaisedBronzeBadge from '../../svg/badge-team-raised-bronze.svg';
import TeamPerformanceGoldBadge from '../../svg/badge-team-performance-gold.svg';
import TeamPerformanceSilverBadge from '../../svg/badge-team-performance-silver.svg';
import TeamPerformanceBronzeBadge from '../../svg/badge-team-performance-bronze.svg';
import style from './CampaignLeaderboard.module.css';

type DisplayType = 'raised' | 'performance';

type DataItem = {
  id: string;
  name: string;
  image?: string;
  description?: string;
  raised?: number;
  performance?: number;
  isFundraiser?: boolean;
};

type CampaignLeaderboardProps = {
  type: 'individual' | 'team';
  display: DisplayType | DisplayType[];
  data: DataItem[];
  showRank?: boolean;
  showBadges?: boolean;
  performanceLabel?: string;
  onClick?: ({ id, isFundraiser }: { id: string; isFundraiser?: boolean }) => void;
  isClickable?: ({ isFundraiser }: { isFundraiser?: boolean }) => boolean;
  limit?: number;
  initialSort?: string;
  sortable?: boolean;
};

const CampaignLeaderboard = ({
  type,
  display,
  data,
  showRank = false,
  showBadges = false,
  performanceLabel = 'Performance',
  onClick = null,
  isClickable = () => true,
  limit = null,
  initialSort = null,
  sortable = false,
}: CampaignLeaderboardProps) => {
  const mobile = !useBreakpoint('md');
  const columns = [].concat(display);
  const [sortBy, setSortBy] = useState(initialSort ?? columns[0]);
  const [sortDesc, setSortDesc] = useState((initialSort ?? columns[0]) !== 'name');

  const willHideColumns = mobile && sortable && columns.length > 1;

  const isColumnVisible = useCallback(
    (column) => {
      if (!willHideColumns) return true;
      if (column !== 'raised') return sortBy === column;
      return ['name', 'raised'].includes(sortBy);
    },
    [willHideColumns, sortBy]
  );

  const sortedData = useMemo(
    () => orderBy(data, sortBy, sortDesc ? 'desc' : 'asc'),
    [data, sortBy, sortDesc]
  );

  const displayData = useMemo(
    () => (limit ? sortedData.slice(0, limit) : sortedData),
    [limit, sortedData]
  );

  const badges = useMemo(() => {
    if (type === 'team') {
      return sortBy === 'performance'
        ? [TeamPerformanceGoldBadge, TeamPerformanceSilverBadge, TeamPerformanceBronzeBadge]
        : [TeamRaisedGoldBadge, TeamRaisedSilverBadge, TeamRaisedBronzeBadge];
    }

    return sortBy === 'performance'
      ? [
          FundraiserPerformanceGoldBadge,
          FundraiserPerformanceSilverBadge,
          FundraiserPerformanceBronzeBadge,
        ]
      : [FundraiserRaisedGoldBadge, FundraiserRaisedSilverBadge, FundraiserRaisedBronzeBadge];
  }, [type, sortBy]);

  return (
    <Tiles columns={1} spacing="xs">
      {sortable && mobile ? (
        <DataTableMobileSort
          as="div"
          title="Sort leaderboard by"
          columns={[
            {
              id: 'name',
              toggleSortBy: () => {
                if (sortBy === 'name') {
                  setSortDesc(!sortDesc);
                  return;
                }

                setSortBy('name');
                setSortDesc(false);
              },
              toggleHidden: () => {},
              canSort: true,
              isSorted: sortBy === 'name',
              isSortedDesc: sortDesc,
              render: () => (type === 'team' ? 'Team name' : 'Name'),
            },
            ...columns.map((x) => ({
              id: x,
              toggleSortBy: () => {
                if (sortBy === x) {
                  setSortDesc(!sortDesc);
                  return;
                }

                setSortBy(x);
                setSortDesc(true);
              },
              toggleHidden: () => {},
              canSort: true,
              isSorted: sortBy === x,
              isSortedDesc: sortDesc,
              render: () => (x === 'performance' ? capitalize(performanceLabel) : 'Raised'),
            })),
          ]}
          alwaysVisible={['name']}
          exclude={['to']}
        />
      ) : (
        <div className="flex items-end">
          {!mobile && showRank && (
            <DataTableHeading as="div" align="center" className={cx('-my-2', style.colRank)}>
              #
            </DataTableHeading>
          )}
          <DataTableHeading
            as="div"
            className={cx('-my-2', { 'cursor-pointer': sortable }, style.colName)}
            isSortable={sortable}
            isSorted={sortBy === 'name'}
            sortDirection={sortDesc ? 'desc' : 'asc'}
            onClick={() => {
              if (!sortable) return;

              if (sortBy === 'name') {
                setSortDesc(!sortDesc);
                return;
              }

              setSortBy('name');
              setSortDesc(false);
            }}
          >
            {type === 'team' ? 'Team name' : 'Name'}
          </DataTableHeading>
          {columns.map((x) => {
            if (!isColumnVisible(x)) return null;

            return (
              <DataTableHeading
                key={x}
                as="div"
                align="right"
                className={cx('-my-2', style.colStat)}
                isSortable={sortable}
                isSorted={sortBy === x}
                sortDirection={sortDesc ? 'desc' : 'asc'}
                onClick={() => {
                  if (!sortable) return;

                  if (sortBy === x) {
                    setSortDesc(!sortDesc);
                    return;
                  }

                  setSortBy(x);
                  setSortDesc(true);
                }}
              >
                {x === 'performance' ? performanceLabel : 'Raised'}
              </DataTableHeading>
            );
          })}
          {!mobile && onClick && (
            <DataTableHeading as="div" className={cx('-my-2', style.colAction)} />
          )}
        </div>
      )}
      {displayData.map((item, i) => (
        <div
          className={cx(
            'border border-gray-400 rounded-xl flex items-center transition-all duration-200 bg-white',
            {
              'group hover:border-gray-600 hover:shadow-lg cursor-pointer':
                onClick && isClickable(item),
            }
          )}
          key={item.id}
          role="presentation"
          onClick={onClick ? () => onClick(item) : null}
        >
          {!mobile && showRank && (
            <DataTableCell
              as="div"
              align="center"
              className={cx('font-medium text-xl text-gray-500', style.colRank)}
            >
              {i + 1}
            </DataTableCell>
          )}
          <DataTableCell as="div" className={cx('overflow-hidden', style.colName)} padding="sm">
            <div className="flex items-center">
              <div className="shrink-0">
                <Avatar image={item.image} size="md" />
              </div>
              <div className="pl-4 grow overflow-hidden">
                <p className="font-medium md:text-base leading-tight truncate">{item.name}</p>
                {item.description && (
                  <p className="text-sm md:text-base text-gray-600 truncate">{item.description}</p>
                )}
              </div>
            </div>
          </DataTableCell>
          {columns.map((x) => {
            const showBadge =
              !mobile &&
              showBadges &&
              x === sortBy &&
              sortDesc &&
              i < 3 &&
              parseNumber(item[x]) > 0;
            const Badge = badges[i];

            if (!isColumnVisible(x)) return null;

            const showBlankPerformance = type === 'individual' && !item.isFundraiser;
            return (
              <DataTableCell
                key={x}
                as="div"
                align="right"
                className={cx(
                  { 'text-gray-600': sortable && !['name', x].includes(sortBy) },
                  style.colStat
                )}
              >
                <div className="inline-flex items-center">
                  {showBadge && <Badge className="w-5 mr-3" />}
                  {x === 'performance' ? (
                    showBlankPerformance ? (
                      <code>&#8212;</code>
                    ) : (
                      formatNumber(item.performance, '0,0.[00]')
                    )
                  ) : (
                    formatCurrency(item.raised, { cents: 'never' })
                  )}
                </div>
              </DataTableCell>
            );
          })}
          {!mobile ? (
            onClick && isClickable(item) ? (
              <DataTableCell as="div" align="center" className={cx(style.colAction)}>
                <button
                  type="button"
                  className="text-theme-secondary group-hover:text-theme-secondary-light"
                >
                  <FontAwesomeIcon icon={faArrowCircleRight} className="text-2xl" />
                </button>
              </DataTableCell>
            ) : (
              <DataTableCell as="div" align="center" className={cx(style.colAction)} />
            )
          ) : null}
        </div>
      ))}
    </Tiles>
  );
};

export default CampaignLeaderboard;
