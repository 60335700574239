import { type ReactNode, useRef } from 'react';
import useSize from '@react-hook/size';

import cx from '@/lib/cx';

type NavbarProps = {
  left?: ReactNode;
  center?: ReactNode;
  right?: ReactNode;
  className?: string;
  leftClassName?: string;
  centerClassName?: string;
  rightClassName?: string;
};

const Navbar = ({
  left = null,
  center = null,
  right = null,
  className = '',
  leftClassName = 'shrink-0',
  centerClassName = '',
  rightClassName = 'shrink-0',
}: NavbarProps) => {
  const containerEl = useRef(null);
  const leftEl = useRef(null);
  const centerEl = useRef(null);
  const rightEl = useRef(null);

  const [containerWidth] = useSize(containerEl);
  const [leftWidth] = useSize(leftEl);
  const [centerWidth] = useSize(centerEl);
  const [rightWidth] = useSize(rightEl);
  const edgeWidth = Math.max(leftWidth, rightWidth) || 0;
  const canCenter = edgeWidth * 2 + centerWidth <= containerWidth;

  return (
    <header className={cx('px-6 sm:px-8 flex items-center', className)}>
      <div
        ref={containerEl}
        className="w-full flex justify-between items-center gap-x-6 lg:gap-x-8 relative"
      >
        <div className={cx('flex items-center justify-start', leftClassName)} ref={leftEl}>
          {left}
        </div>
        {center && (
          <div
            className={cx(
              'flex-1 flex items-center max-w-full',
              {
                'justify-start': !canCenter,
                'absolute inset-0 mx-auto justify-center': canCenter,
              },
              centerClassName
            )}
            style={{ maxWidth: canCenter ? containerWidth - edgeWidth * 2 : 'none' }}
          >
            <div ref={centerEl}>{center}</div>
          </div>
        )}
        <div className={cx('flex items-center justify-end', rightClassName)} ref={rightEl}>
          {right}
        </div>
      </div>
    </header>
  );
};

export default Navbar;
