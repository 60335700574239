import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

import FormNode from './FormNode';
import FormLabel from './FormLabel';
import FormInput from './FormInput';
import FormValidationError from './FormValidationError';

const FormControlledInputWithLabel = ({
  control,
  component: Component,
  label,
  name,
  isRequired,
  hideLabel,
  ...props
}) => (
  <Controller
    control={control}
    name={name}
    render={({ field, fieldState }) => (
      <FormNode>
        <FormLabel
          htmlFor={field.name}
          isRequired={isRequired}
          className={hideLabel ? 'sr-only' : ''}
        >
          {label}
        </FormLabel>
        <Component {...field} status={fieldState.error ? 'error' : 'default'} {...props} />
        {fieldState.error?.message && <FormValidationError message={fieldState.error.message} />}
      </FormNode>
    )}
  />
);

FormControlledInputWithLabel.propTypes = {
  control: PropTypes.shape({}).isRequired,
  component: PropTypes.elementType,
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  hideLabel: PropTypes.bool,
};

FormControlledInputWithLabel.defaultProps = {
  component: FormInput,
  isRequired: false,
  hideLabel: false,
};

export default FormControlledInputWithLabel;
