import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faKey } from '@fortawesome/pro-regular-svg-icons';
import { motion } from 'framer-motion';
import cx from 'classnames';

import FormNode from 'components/form/FormNode';
import FormLabel from 'components/form/FormLabel';

const CampaignAdminAPIKey = ({ apiKey, label }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) return;
    const timer = setTimeout(() => setCopied(false), 3000);
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timer);
  }, [copied, setCopied]);

  return (
    <FormNode>
      <FormLabel>{label}</FormLabel>
      <div className="bg-white rounded-lg">
        <CopyToClipboard text={apiKey} onCopy={() => setCopied(true)}>
          <button
            type="button"
            className="flex border rounded-md border-gray-400 w-full group transition-colors duration-200 hover:border-gray-600"
          >
            <motion.span
              key={copied ? 'copied' : 'not-copied'}
              className="py-3 w-12 shrink-0"
              initial={copied ? { scale: 1.4 } : { opacity: 0 }}
              animate={{ opacity: 1, scale: 1 }}
            >
              <FontAwesomeIcon
                icon={copied ? faCheckCircle : faKey}
                className={cx({ 'text-green-500': copied, 'text-gray-500': !copied })}
                size={copied ? 'lg' : '1x'}
                fixedWidth
              />
            </motion.span>
            <span className="border-l border-gray-400 group-hover:border-gray-500 py-3 px-4 transition-colors duration-200 truncate">
              {copied ? 'Copied!' : apiKey}
            </span>
          </button>
        </CopyToClipboard>
      </div>
    </FormNode>
  );
};

CampaignAdminAPIKey.propTypes = {
  apiKey: PropTypes.string.isRequired,
  label: PropTypes.string,
};

CampaignAdminAPIKey.defaultProps = {
  label: 'API Key',
};

export default CampaignAdminAPIKey;
