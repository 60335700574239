import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faArrowLeft,
  faAngleRight,
  faLock,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { faLock as faLockSolid, faUser as faUserSolid } from '@fortawesome/pro-solid-svg-icons';
import { useMutation, gql } from '@apollo/client';
import omitBy from 'lodash/omitBy';
import { motion } from 'framer-motion';
import cx from 'classnames';

import config from 'config';
import useBreakpoint from 'hooks/useBreakpoint';
import { useAuth } from 'context/Auth';
import ModalPage from 'components/common/ModalPage';
import AppBar from 'components/common/AppBar';
import AppBarToolbar from 'components/common/AppBarToolbar';
import AppBarIcon from 'components/common/AppBarIcon';
import AppBarTitle from 'components/common/AppBarTitle';
import Tiles from 'components/common/Tiles';
import VSubnav from 'components/common/VSubnav';
import Snackbar from 'components/common/Snackbar';
import AccountPasswordForm from './AccountPasswordForm';
import AccountSettingsForm from './AccountSettingsForm';

const UPDATE_USER = gql`
  mutation UpdateUser($user: UserInput!) {
    updateUser(User: $user) {
      id
      email
      firstName
      lastName
      avatarImage
    }
  }
`;

const CHANGE_PASSWORD = gql`
  mutation ChangePassword($userId: String!, $oldPassword: String!, $newPassword: String!) {
    changePassword(userId: $userId, oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;

const errorMessages = {
  DuplicateEmail: 'An user with that email already exists',
  IncorrectOldPassword: 'Your password does not match our records. Please try again.',
  default: 'We were unable to save your changes. Please try again.',
};

const AccountSettings = ({ show, onHide }) => {
  const { user } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [updateUser] = useMutation(UPDATE_USER);
  const [changePassword] = useMutation(CHANGE_PASSWORD);
  const mobile = !useBreakpoint('lg');

  const handleSaveSettings = useCallback(
    async (values) => {
      try {
        setErrorMessage(null);
        await updateUser({
          variables: {
            user: {
              id: user.id,
              ...omitBy(values, (v, k) => v === user[k]),
            },
          },
        });
        setSuccessMessage('Your changes have been saved.');
      } catch (err) {
        const errorType = err.message.replace('GraphQL error: ', '');
        const message = errorMessages[errorType];

        if (config('/debug') && !message) {
          console.error('No custom message for error:', errorType);
        }

        setErrorMessage(message || errorMessages.default);
      }
    },
    [user, updateUser]
  );

  const handleSavePassword = useCallback(
    async (values) => {
      try {
        setErrorMessage(null);
        await changePassword({
          variables: {
            userId: user.id,
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
          },
        });

        setSuccessMessage('Your password has been updated.');
        setShowPassword(false);
      } catch (err) {
        const errorType = err.message.replace('GraphQL error: ', '');
        const message = errorMessages[errorType];

        if (config('/debug') && !message) {
          console.error('No custom message for error:', errorType);
        }

        setErrorMessage(message || errorMessages.default);
      }
    },
    [user, changePassword, setErrorMessage, setSuccessMessage]
  );

  return (
    <>
      {successMessage && (
        <Snackbar
          key="userSettingsSuccess"
          type="success"
          message={successMessage}
          duration="short"
          onDismiss={() => setSuccessMessage(null)}
        />
      )}
      {errorMessage && (
        <Snackbar
          key="userSettingsError"
          type="danger"
          message={errorMessage}
          duration="long"
          onDismiss={() => setErrorMessage(null)}
        />
      )}
      <ModalPage
        show={show}
        onHide={onHide}
        className="max-w-5xl flex flex-col items-stretch"
        fixedHeight
      >
        <AppBar className="z-10 shrink-0">
          <AppBarToolbar>
            <Tiles spacing="sm">
              {showPassword ? (
                <button type="button" onClick={() => setShowPassword(false)}>
                  <AppBarIcon icon={faArrowLeft} />
                </button>
              ) : (
                <button type="button" onClick={onHide}>
                  <AppBarIcon icon={faTimes} />
                </button>
              )}
            </Tiles>
            <AppBarTitle title="My account" className="md:font-medium" />
            <Tiles />
          </AppBarToolbar>
        </AppBar>

        <div className="flex-1 lg:flex relative z-0">
          {!mobile && (
            <div className="shrink-0 w-84 sticky bg-gray-100">
              <VSubnav className="sticky py-4 top-18 bg-gray-100">
                <button
                  type="button"
                  onClick={() => setShowPassword(false)}
                  className={cx(
                    'w-11/12 mx-auto flex justify-between items-center py-3 px-4 lg:py-4 font-medium text-gray-700 transition-colors duration-200 rounded-xl lg:border border-transparent',
                    {
                      'lg:bg-white lg:border-gray-400 lg:shadow-lg text-theme-primary':
                        !showPassword,
                    }
                  )}
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={!showPassword ? faUserSolid : faUser}
                      className="mr-4 mb-1"
                    />
                    <p>Personal info</p>
                  </div>
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    size="1x"
                    className="ml-auto text-gray-500"
                  />
                </button>
                <button
                  type="button"
                  onClick={() => setShowPassword(true)}
                  className={cx(
                    'w-11/12 mx-auto flex justify-between items-center py-3 px-4 lg:py-4 font-medium text-gray-700 transition-colors duration-200 rounded-xl lg:border border-transparent',
                    {
                      'lg:bg-white lg:border-gray-400 lg:shadow-lg text-theme-primary':
                        showPassword,
                    }
                  )}
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      icon={showPassword ? faLockSolid : faLock}
                      className="mr-4 mb-1"
                    />
                    <p>Change password</p>
                  </div>
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    size="1x"
                    className="ml-auto text-gray-500"
                  />
                </button>
              </VSubnav>
            </div>
          )}
          <div className="sm:pb-16 sm:px-4 sm:pt-2 md:grow lg:border-l lg:border-gray-300">
            <div className="mx-auto md:ml-0">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.2 }}
                key={showPassword ? 'password' : 'settings'}
              >
                {showPassword ? (
                  <AccountPasswordForm onSubmit={handleSavePassword} />
                ) : (
                  <AccountSettingsForm
                    onClickPassword={() => setShowPassword(true)}
                    onSubmit={handleSaveSettings}
                  />
                )}
              </motion.div>
            </div>
          </div>
        </div>
      </ModalPage>
    </>
  );
};

AccountSettings.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default AccountSettings;
