import { createContext, useContext, useCallback, useMemo, useEffect } from 'react';
import { useQuery, useMutation, useApolloClient, gql } from '@apollo/client';
import { setCookie, destroyCookie } from 'nookies';
import * as Sentry from '@sentry/nextjs';
import pick from 'lodash/pick';

const AuthContext = createContext();

export const GET_USER = gql`
  query {
    me {
      id
      email
      firstName
      lastName
      fullName
      apiKey
      avatarImage
      clientStore
    }
  }
`;

export const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`;

export const LOGIN_WITH_SERVICE = gql`
  mutation LoginWithService($service: LoginService!, $accessToken: String!) {
    loginWithService(type: $service, accessToken: $accessToken) {
      token
    }
  }
`;

export const LOGOUT = gql`
  mutation Logout {
    logout
  }
`;

export const AuthProvider = (props) => {
  const { data } = useQuery(GET_USER);
  const client = useApolloClient();
  const user = useMemo(() => data?.me, [data]);

  const [loginMutation] = useMutation(LOGIN, {
    refetchQueries: [{ query: GET_USER }],
    awaitRefetchQueries: true,
    update: (_, response) => {
      setCookie(null, 'token', response.data.login.token, {
        maxAge: 60 * 60 * 24 * 30,
        path: '/',
      });
    },
  });

  const [loginWithServiceMutation] = useMutation(LOGIN_WITH_SERVICE, {
    refetchQueries: [{ query: GET_USER }],
    awaitRefetchQueries: true,
    update: (_, response) => {
      setCookie(null, 'token', response.data.loginWithService.token, {
        maxAge: 60 * 60 * 24 * 30,
        path: '/',
      });
    },
  });

  const [logoutMutation] = useMutation(LOGOUT, {
    update: () => destroyCookie(null, 'token', { path: '/' }),
  });

  const login = useCallback(
    async ({ email, password }) => {
      const result = await loginMutation({ variables: { email, password } });
      client.resetStore();
      return result;
    },
    [loginMutation, client]
  );

  const loginWithService = useCallback(
    async ({ service, accessToken }) => {
      const result = await loginWithServiceMutation({ variables: { service, accessToken } });
      client.resetStore();
      return result;
    },
    [loginWithServiceMutation, client]
  );

  const logout = useCallback(async () => {
    const result = await logoutMutation();
    client.resetStore();
    return result;
  }, [logoutMutation, client]);

  const ctx = useMemo(
    () => ({
      isLoggedIn: Boolean(user),
      user,

      login,
      loginWithService,
      logout,
    }),
    [user, login, loginWithService, logout]
  );

  // Identify user for Sentry error logging
  useEffect(() => Sentry.setUser(user ? pick(user, ['id', 'email', 'fullName']) : null), [user]);

  return <AuthContext.Provider value={ctx} {...props} />;
};

export const AuthConsumer = AuthContext.Consumer;

// React Hook for accessing auth data & methods
export const useAuth = () => useContext(AuthContext);
