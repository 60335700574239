import PropTypes from 'prop-types';
import cx from 'classnames';

const CampaignPageSectionHeader = ({
  title,
  subtitle,
  className,
  titleClassName,
  subtitleClassName,
  as: Component,
  style,
}) => (
  <Component className={cx('text-center', className)}>
    <h2
      className={cx('text-3xl lg:text-4xl font-medium leading-tight', titleClassName)}
      style={style}
    >
      {title}
    </h2>
    {subtitle && (
      <h3 className={cx('text-lg lg:text-xl  leading-snug mt-2', subtitleClassName)} style={style}>
        {subtitle}
      </h3>
    )}
  </Component>
);

CampaignPageSectionHeader.propTypes = {
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.node,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  subtitleClassName: PropTypes.string,
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.shape({ render: PropTypes.func }),
  ]),
  style: PropTypes.shape({}),
};

CampaignPageSectionHeader.defaultProps = {
  subtitle: null,
  className: '',
  titleClassName: 'text-theme-primary',
  subtitleClassName: 'text-gray-700',
  as: 'hgroup',
  style: {},
};

export default CampaignPageSectionHeader;
