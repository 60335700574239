import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import Portal from './Portal';
import Toast from './Toast';

const Snackbar = ({ type, ...props }) => (
  <Portal id="snackbars">
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 50, scale: 0.95 }}
        animate={{ opacity: 1, y: 0, scale: 1 }}
        exit={{ opacity: 0 }}
        className="mt-1 pointer-events-auto"
      >
        <Toast {...props} type={type === 'danger' ? 'error' : type} />
      </motion.div>
    </AnimatePresence>
  </Portal>
);

Snackbar.propTypes = {
  type: PropTypes.oneOf(['danger', 'warning', 'success', 'info']),
};

Snackbar.defaultProps = {
  type: 'info',
};

export default Snackbar;
