import Big from 'big.js';
import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

import { formatCurrency } from 'lib/formatters';
import { useCampaignPage } from 'context/CampaignPage';
import ProgressBar from 'components/common/ProgressBar';

const GET_CAMPAIGN = gql`
  query GetCampaign($id: String!) {
    findCampaigns(id: $id) {
      id
      monetaryGoal
      stats {
        estimatedAmountRaised
      }
    }
  }
`;

const CampaignPageProgressLine = () => {
  const { campaignId, theme } = useCampaignPage();
  const { data } = useQuery(GET_CAMPAIGN, { variables: { id: campaignId } });
  const campaign = useMemo(() => data?.findCampaigns[0], [data]);

  if (!campaign) return null;

  const goal = Big(campaign.monetaryGoal);
  const progress = Big(campaign.stats.estimatedAmountRaised);
  const percent = progress.div(goal).times(100);

  return (
    <div>
      <p className="mb-2">
        <span className="inline-block font-medium text-xl">
          {formatCurrency(progress, { cents: 'never', abbreviateAfter: 1000000 })}
        </span>
        <span className="inline-block ml-2 uppercase text-sm">
          Raised of {formatCurrency(goal, { cents: 'never', abbreviateAfter: 1000000 })}
        </span>
      </p>
      <ProgressBar
        percent={Number(percent.gt(100) ? Big(100) : percent)}
        barColor={theme.secondary}
      />
    </div>
  );
};

export default CampaignPageProgressLine;
