import { useRef, useEffect } from 'react';
import noop from 'lodash/noop';

import config from 'config';

const DEFAULT_UPLOADER_OPTIONS = {
  cloudName: config('/cloudinary/cloudName'),
  uploadPreset: 'user-images',
  sources: ['local'],
  resourceType: 'image',
  multiple: false,
  showPoweredBy: false,
  styles: {
    palette: {
      window: config('/tw/colors/white'),
      sourceBg: config('/tw/colors/gray/200'),
      windowBorder: config('/tw/colors/gray/500'),
      tabIcon: config('/tw/colors/gray/700'),
      menuIcons: config('/tw/config/gray/500'),
      textDark: config('/tw/config/gray/800'),
      textLight: config('/tw/config/gray/300'),
      link: config('/tw/colors/gray/800'),
      action: config('/tw/colors/gray/800'),
      inactiveTabIcon: config('/tw/colors/gray/500'),
      error: config('/tw/colors/red/600'),
      inProgress: config('/tw/colors/blue/500'),
      complete: config('/tw/colors/green/500'),
    },
    fonts: {
      'Rubik, sans-serif': 'https://fonts.googleapis.com/css2?family=Rubik&display=swap',
    },
  },
};

const useCloudinaryUploader = ({
  uploaderOptions = {},
  onError = noop,
  onUpload = noop,
  onAbort = noop,
}) => {
  const uploader = useRef(null);
  const wasSuccessful = useRef(false);

  // Close uploader (if open) on unmount
  useEffect(() => () => uploader.current?.close(), []);

  return () => {
    uploader.current = window.cloudinary?.openUploadWidget(
      {
        ...DEFAULT_UPLOADER_OPTIONS,
        ...uploaderOptions,
      },
      // eslint-disable-next-line consistent-return
      (error, result) => {
        if (error) return onError(error);

        switch (result?.event) {
          case 'queues-end': {
            wasSuccessful.current = true;
            break;
          }

          case 'success': {
            onUpload(result.info);
            break;
          }

          case 'abort': {
            // Abort gets called anytime the widget closes, but we only care
            // if the user bails out before successfully uploading their image(s)
            if (!wasSuccessful.current) onAbort();
            break;
          }

          default: {
            // if (config('/debug')) console.log(result);
          }
        }
      }
    );
  };
};

export default useCloudinaryUploader;
