import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { gql, useQuery } from '@apollo/client';
import { NextSeo } from 'next-seo';

import config from 'config';
import { formatCloudinaryUrl, formatSlateAsPlainText } from 'lib/formatters';
import { useCampaignPage } from 'context/CampaignPage';

const GET_CAMPAIGN = gql`
  query GetCampaignPageSeo(
    $campaignId: String!
    $fundraiserWhere: SequelizeJSON
    $teamWhere: SequelizeJSON
    $isFundraiser: Boolean!
    $isTeam: Boolean!
  ) {
    findCampaigns(id: $campaignId) {
      id
      campaignHeaderBackgroundImage
      campaignStory
      fundraiserDefaultHeaderBackgroundImage
      fundraiserDefaultStory
      fundraisers(where: $fundraiserWhere) @include(if: $isFundraiser) {
        id
        headerImage
        resolvedName
        story
      }
      name
      teamDefaultHeaderBackgroundImage
      teamDefaultStory
      teams(where: $teamWhere) @include(if: $isTeam) {
        id
        headerImage
        name
        story
      }
    }
  }
`;

const CampaignPageSeo = () => {
  const router = useRouter();
  const { campaignId, fundraiserId, teamId, pageType } = useCampaignPage();

  const { data } = useQuery(GET_CAMPAIGN, {
    variables: {
      campaignId,
      fundraiserWhere: { id: fundraiserId },
      teamWhere: { id: teamId },
      isFundraiser: pageType === 'fundraiser',
      isTeam: pageType === 'team',
    },
  });

  const campaign = useMemo(() => data?.findCampaigns[0], [data]);

  const fundraiser = useMemo(() => {
    if (pageType === 'team') return campaign?.teams[0];
    if (pageType === 'fundraiser') return campaign?.fundraisers[0];
    return null;
  }, [campaign, pageType]);

  const title = useMemo(() => {
    if (!fundraiser) return campaign?.name;
    const name = fundraiser.name ?? fundraiser.resolvedName;
    return `${name} - ${campaign.name}`;
  }, [campaign, fundraiser]);

  const description = useMemo(() => {
    if (!fundraiser) return formatSlateAsPlainText(campaign?.campaignStory ?? '');

    return formatSlateAsPlainText(
      fundraiser.story ??
        (pageType === 'team' ? campaign.teamDefaultStory : campaign.fundraiserDefaultStory)
    );
  }, [pageType, campaign, fundraiser]);

  const image = useMemo(() => {
    if (!fundraiser) {
      return campaign?.campaignHeaderBackgroundImage ?? config('/defaultCampaignHero');
    }

    if (pageType === 'team') {
      return (
        fundraiser.headerImage ||
        campaign.teamDefaultHeaderBackgroundImage ||
        campaign.campaignHeaderBackgroundImage ||
        config('/defaultTeamHeaderImage')
      );
    }

    return (
      fundraiser.headerImage ||
      campaign.fundraiserDefaultHeaderBackgroundImage ||
      campaign.campaignHeaderBackgroundImage ||
      config('/defaultFundraiserHeaderImage')
    );
  }, [pageType, fundraiser, campaign]);

  if (!campaign) return null;

  return (
    <NextSeo
      title={`Support ${title}`}
      description={description}
      openGraph={{
        // Duplicated here so it doesn't get the site name appended
        title: `Support ${title}`,
        url: `${config('/hostname')}${router.asPath}`,
        images: [
          {
            url: formatCloudinaryUrl(image, { transformation: 'facebook' }),
            width: 1200,
            height: 630,
          },
        ],
      }}
    />
  );
};

export default CampaignPageSeo;
