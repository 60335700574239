import { Children } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const VSubnav = ({ className, children }) => {
  const items = Children.toArray(children);
  return (
    <nav className={cx('bg-white', className)}>
      <ul>
        {items.map((item, i) => (
          <li key={i} className={cx({ 'border-t border-gray-200 lg:border-t-0': i > 0 })}>
            {item}
          </li>
        ))}
      </ul>
    </nav>
  );
};

VSubnav.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

VSubnav.defaultProps = {
  className: '',
};

export default VSubnav;
