import { ElementType, ReactNode } from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import cx from 'classnames';

import Link from './Link';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type NavbarRoundedButtonProps<C extends ElementType> = PolymorphicComponentProp<
  C,
  {
    as?: C;
    icon?: IconProp;
    label?: ReactNode;
    className?: string;
    muted?: boolean;
    theme?: 'light' | 'dark';
    iconProps?: Omit<FontAwesomeIconProps, 'icon'>;
  }
>;

const NavbarRoundedButton = <C extends ElementType = typeof Link>({
  as,
  icon = null,
  label = null,
  className = '',
  muted = false,
  iconProps = {},
  theme = 'light',
  ...props
}: NavbarRoundedButtonProps<C>) => {
  const Component = as || Link;
  return (
    <Component
      className={cx(
        'font-medium inline-flex items-center justify-center rounded-full transition-colors duration-200 h-10 px-4 border',
        {
          'border-gray-300 hover:bg-gray-300 text-gray-800': theme === 'light',
          'border-gray-700 hover:bg-gray-700 text-white': theme === 'dark',
          'bg-gray-300 hover:bg-gray-400': muted && theme === 'light',
          'bg-gray-700 hover:bg-gray-800': muted && theme === 'dark',
        }
      )}
      {...props}
    >
      {icon && <FontAwesomeIcon icon={icon} className="text-lg" {...iconProps} />}
      {label && <span className={cx('inline-block', { 'ml-3': icon })}>{label}</span>}
    </Component>
  );
};

export default NavbarRoundedButton;
