import { useCallback, useMemo, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import Highlight from 'react-highlight-words';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { motion } from 'framer-motion';
import cx from 'classnames';

import config from 'config';
import mergeRefs from 'lib/mergeRefs';
import useIsClient from 'hooks/useIsClient';
import style from './FormInput.module.css';

const tw = config('/tw');

export const renderOption = (option, { context, inputValue }) => {
  const hasSearchText = context === 'menu' && inputValue;

  return (
    <p className="truncate">
      <Highlight
        textToHighlight={option.label}
        searchWords={hasSearchText ? [inputValue] : []}
        highlightClassName="font-medium"
        highlightTag="span"
        autoEscape
      />
    </p>
  );
};

const FormSelect = forwardRef(
  (
    {
      status,
      disabled,
      readOnly,
      options,
      value,
      onChange,
      showSearchIcon,
      clearValue,
      isMulti,
      ...props
    },
    ref
  ) => {
    const selectRef = useRef(null);

    const state = useMemo(() => {
      if (disabled) return 'disabled';
      if (readOnly) return 'readonly';
      return status;
    }, [disabled, readOnly, status]);

    const selectableOptions = useMemo(
      () =>
        options.reduce(
          (arr, item) => (item.options ? [...arr, ...item.options] : [...arr, item]),
          []
        ),
      [options]
    );

    const getOptionFromValue = useCallback(
      (x) => {
        if (x == null) return undefined;
        return selectableOptions.find((option) => option.value === x);
      },
      [selectableOptions]
    );

    const selectValue = useMemo(
      () => (isMulti ? value.map((x) => getOptionFromValue(x)) : getOptionFromValue(value)),
      [isMulti, value, getOptionFromValue]
    );

    const selectOnChange = useCallback(
      (selected) => {
        if (isMulti) {
          onChange(selected?.length > 0 ? selected.map((opt) => opt.value) : clearValue);
          return;
        }

        onChange(selected ? selected.value : clearValue);
      },
      [isMulti, onChange, clearValue]
    );

    const selectComponents = useMemo(
      () => ({
        /* eslint-disable react/prop-types, no-shadow */
        Control: ({ innerRef, innerProps, children }) => (
          <div
            {...innerProps}
            ref={innerRef}
            className={cx('flex', style.base, style[state])}
            onClick={() => {
              if (!selectRef.current.state.isFocused) {
                selectRef.current.onInputFocus();
              }
            }}
            /* eslint-disable jsx-a11y/click-events-have-key-events */
            role="presentation"
          >
            {children}
          </div>
        ),

        ValueContainer: ({ innerRef, innerProps, children, hasValue, selectProps }) => (
          <>
            {!hasValue && showSearchIcon && selectProps.isSearchable && (
              <div className="flex-no-shrink flex justify-center items-center pl-4 -mr-2 pointer-events-none">
                <FontAwesomeIcon
                  icon={faSearch}
                  size="1x"
                  className={style.statusColor}
                  fixedWidth
                />
              </div>
            )}
            <div {...innerProps} ref={innerRef} className="py-2 pl-4 grow overflow-hidden">
              <div
                className={cx('relative', {
                  'flex flex-wrap items-center gap-x-1 gap-y-1': isMulti,
                })}
              >
                {children}
              </div>
            </div>
          </>
        ),

        SingleValue: isMulti
          ? undefined
          : ({ innerRef, innerProps, children }) => (
              <div
                {...innerProps}
                ref={innerRef}
                className="truncate absolute inset-x-0 top-1/2"
                style={{ transform: 'translateY(-50%)' }}
              >
                {children}
              </div>
            ),

        Placeholder: ({ children }) =>
          children ? (
            <p className="absolute left-0 top-0 text-gray-600 pointer-events-none">{children}</p>
          ) : null,

        Input: ({
          clearValue,
          getStyles,
          getValue,
          hasValue,
          isMulti: inputIsMulti,
          isRtl,
          options,
          selectOption,
          setValue,
          selectProps,
          cx: inputCx,
          isHidden,
          isDisabled,
          innerRef,
          theme,
          ...props
        }) => (
          <input
            {...props}
            ref={mergeRefs(innerRef, ref)}
            className={cx('-ml-px focus:outline-none bg-transparent leading-normal', {
              'w-full': !isMulti,
              'w-24': isMulti,
            })}
          />
        ),

        IndicatorsContainer: ({ innerRef, innerProps, children }) => (
          <div ref={innerRef} {...innerProps} className="shrink-0 flex items-center py-2">
            {children}
          </div>
        ),

        IndicatorSeparator: () => <div className="w-px h-full bg-gray-400" />,

        ClearIndicator: ({ innerProps }) => (
          <button
            {...innerProps}
            type="button"
            className="flex justify-center items-center px-3 hover:text-gray-700 transition-colors duration-100"
          >
            <FontAwesomeIcon icon={faTimes} size="1x" className={style.statusColor} fixedWidth />
          </button>
        ),

        DropdownIndicator: ({ innerProps }) => (
          <button
            {...innerProps}
            type="button"
            className="flex justify-center items-center px-2 hover:text-gray-700 transition-colors duration-100"
            data-testid="select-dropdown-trigger"
          >
            <FontAwesomeIcon
              icon={faCaretDown}
              className={cx('text-lg', style.statusColor)}
              fixedWidth
            />
          </button>
        ),

        GroupHeading: ({ innerProps, children }) => (
          <div
            {...innerProps}
            className="leading-relaxed bg-white w-full pb-1 px-4 font-medium text-teal-500 border-b border-gray-300 overflow-hidden"
          >
            <span className="grow overflow-hidden">{children}</span>
          </div>
        ),

        MultiValueContainer: ({ props, children }) => (
          <div className="flex items-center leading-snug px-2 rounded-md bg-gray-200" {...props}>
            {children}
          </div>
        ),

        MultiValueRemove: ({ innerProps }) => (
          <div {...innerProps} className="ml-1 cursor-pointer">
            <FontAwesomeIcon
              icon={faTimes}
              size="sm"
              className="text-gray-500 hover:text-gray-700"
            />
          </div>
        ),

        Option: ({ innerRef, innerProps, children, isDisabled, isSelected, isFocused }) => (
          <div
            ref={innerRef}
            {...innerProps}
            className={cx(
              'leading-relaxed transition-all duration-100 py-2 px-4 flex items-center w-full overflow-hidden',
              {
                'bg-gray-300': isFocused,
                'cursor-pointer': !isDisabled,
                'text-gray-600': isDisabled,
              }
            )}
          >
            {isSelected && <span className="shrink-0 w-2 h-2 bg-theme-primary mr-3 rounded-full" />}
            <span className="grow overflow-hidden">{children}</span>
          </div>
        ),

        // eslint-disable-next-line react/destructuring-assignment
        Menu: ({ placement, ...props }) => (
          <motion.div initial={{ opacity: 0, scale: 0.9 }} animate={{ opacity: 1, scale: 1 }}>
            <components.Menu placement={placement} {...props} />
          </motion.div>
        ),
      }),
      [state, showSearchIcon, isMulti]
    );

    // We can only render this in the client due to the dropdown
    // menu needing to be put in a Portal
    const isClient = useIsClient();
    if (!isClient) return null;

    return (
      <Select
        ref={selectRef}
        disabled={disabled}
        readOnly={readOnly}
        options={options}
        isDisabled={disabled || readOnly}
        menuPortalTarget={document.body}
        menuPlacement="auto"
        value={selectValue}
        onChange={selectOnChange}
        components={selectComponents}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 40 }),
          menu: (base) => ({
            ...base,
            border: `1px solid ${tw.colors.gray['400']}`,
            borderRadius: tw.borderRadius.md,
            boxShadow: tw.boxShadow.lg,
          }),
        }}
        className="truncate"
        isMulti={isMulti}
        openMenuOnFocus
        {...props}
      />
    );
  }
);

FormSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  status: PropTypes.oneOf(['default', 'error', 'warning', 'success', 'info']),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  isClearable: PropTypes.bool,
  clearValue: PropTypes.string,
  isSearchable: PropTypes.bool,
  showSearchIcon: PropTypes.bool,
  openMenuOnFocus: PropTypes.bool,
  isMulti: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  formatOptionLabel: PropTypes.func,
  'aria-labelledby': PropTypes.string.isRequired,
};

FormSelect.defaultProps = {
  status: 'default',
  disabled: false,
  readOnly: false,
  isClearable: true,
  clearValue: null,
  isSearchable: true,
  showSearchIcon: true,
  openMenuOnFocus: true,
  isMulti: false,
  value: null,
  formatOptionLabel: renderOption,
};

export default FormSelect;
