import { Children, ElementType } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

type ContextMenuProps<C extends ElementType> = PolymorphicComponentProp<
  C,
  {
    bordered?: boolean;
    className?: string;
    children: NonEmptyReactNode
  }
>;

const ContextMenu = <C extends ElementType = 'div'>({
  as,
  bordered = false,
  className = '',
  children,
}: ContextMenuProps<C>) => {
  const Component = as || 'div';
  const items = Children.toArray(children);

  return (
    <Component className={className}>
      {items.map((item, i) => (
        <div
          key={i}
          className={cx('py-px', {
            'border-t border-gray-300 lg:border-t-0': bordered,
          })}
        >
          {item}
        </div>
      ))}
    </Component>
  );
};

export default ContextMenu;
