import PropTypes from 'prop-types';
import { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { motion } from 'framer-motion';
import useSize from '@react-hook/size';
import { useIsomorphicEffect } from 'rooks';

import useIframely from 'hooks/useIframely';
import FormNote from 'components/form/FormNote';

type IframelyCardProps = {
  url: string;
  displayError?: boolean;
};

const IframelyCard = ({ url, displayError = false }: IframelyCardProps) => {
  const { loading, error, data } = useIframely(url);

  const element = useRef(null);
  const [, height] = useSize(element);

  useIsomorphicEffect(() => {
    element.current.dispatchEvent(new Event('iframely:render', { bubbles: true }));
  }, [height]);

  return (
    <div ref={element}>
      {loading ? (
        <motion.div key="loading" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <div className="h-24 flex justify-center items-center">
            <FontAwesomeIcon icon={faSpinner} size="lg" className="text-gray-400" spin />
          </div>
        </motion.div>
      ) : (
        <>
          {error && displayError && (
            <motion.div key="error" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              <div className="flex flex-col justify-center items-center text-center bg-gray-100 p-12 rounded-md">
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  size="lg"
                  className="text-gray-400 mb-4"
                />
                <FormNote>
                  We are unable to display your URL. Please verify that it has been entered
                  correctly.
                </FormNote>
              </div>
            </motion.div>
          )}
          {data?.html && (
            <motion.div key="embed" initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: data.html }} />
            </motion.div>
          )}
        </>
      )}
    </div>
  );
};

export default IframelyCard;
