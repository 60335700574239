import PropTypes from 'prop-types';

import Popover from './Popover';

export const reactionConfig = {
  like: '👍',
  love: '❤',
  clap: '👏',
  cry: '🥲',
  yay: '🥳',
  haha: '😆',
  wow: '😮',
};

const UserReactionTrigger = ({ onReact, children }) => (
  <Popover
    className="leading-none"
    trigger={children}
    positions={['top', 'bottom', 'right', 'left']}
    align="center"
    closeOnClick
  >
    <div className="bg-white rounded-full shadow-lg flex gap-x-3 py-1.5 px-3">
      {Object.entries(reactionConfig).map(([reaction, emoji]) => (
        <button
          key={reaction}
          type="button"
          className="text-3xl transition-all duration-200 hover:scale-125"
          onClick={() => onReact(reaction)}
        >
          <span role="img" aria-label={reaction}>
            {emoji}
          </span>
        </button>
      ))}
    </div>
  </Popover>
);

UserReactionTrigger.propTypes = {
  children: PropTypes.node.isRequired,
  onReact: PropTypes.func.isRequired,
};

UserReactionTrigger.defaultProps = {};

export default UserReactionTrigger;
