import { useState, useCallback, useEffect } from 'react';

const useHorizontalScroller = ({ resetOnMount = true, resetOnWindowResize = true } = {}) => {
  const [element, setElement] = useState(null);
  const [scrollPos, setScrollPos] = useState(0);

  const scrollWidth = element?.scrollWidth ?? 0;
  const clientWidth = element?.clientWidth ?? 0;
  const canScroll = scrollWidth > clientWidth;
  const canScrollLeft = canScroll && scrollPos > 0;
  const canScrollRight = canScroll && Math.ceil(scrollPos) < scrollWidth - clientWidth;

  const scrollLeft = useCallback(
    (x) => element?.scrollTo({ left: scrollPos - x, behavior: 'smooth' }),
    [element, scrollPos]
  );

  const scrollRight = useCallback(
    (x) => element?.scrollTo({ left: scrollPos + x, behavior: 'smooth' }),
    [element, scrollPos]
  );

  const resetScroll = useCallback(() => element?.scrollTo({ left: 0 }), [element]);

  // Scroll position tracking
  useEffect(() => {
    if (!element) return;

    const onScroll = () => setScrollPos(element.scrollLeft);
    element.addEventListener('scroll', onScroll);

    // eslint-disable-next-line consistent-return
    return () => element.removeEventListener('scroll', onScroll);
  }, [element, resetOnMount, resetOnWindowResize, resetScroll]);

  // Reset on mount
  useEffect(() => {
    if (element && resetOnMount) resetScroll();
  }, [element, resetOnMount, resetScroll]);

  // Reset on window resize
  useEffect(() => {
    if (!element || !resetOnWindowResize) return;

    window.addEventListener('resize', resetScroll);

    // eslint-disable-next-line consistent-return
    return () => window.removeEventListener('resize', resetScroll);
  }, [element, resetOnWindowResize, resetScroll]);

  return {
    ref: setElement,
    clientWidth,
    scrollWidth,
    canScroll,
    canScrollLeft,
    canScrollRight,
    scrollLeft,
    scrollRight,
    resetScroll,
  };
};

export default useHorizontalScroller;
