import { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  faExclamationTriangle,
  faInfoCircle,
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/pro-regular-svg-icons';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

import { renderAppendedInputNode } from './FormInput';
import style from './FormInput.module.css';

const FormPhoneInput = forwardRef(
  ({ onChange, status, disabled, readOnly, suffix, rounded, ...props }, ref) => {
    const state = useMemo(() => {
      if (disabled) return 'disabled';
      if (readOnly) return 'readonly';
      return status;
    }, [disabled, readOnly, status]);

    const statusIcon = useMemo(() => {
      if (state === 'error') return faExclamationCircle;
      if (state === 'warning') return faExclamationTriangle;
      if (state === 'success') return faCheckCircle;
      if (state === 'info') return faInfoCircle;
      return null;
    }, [state]);

    return (
      <div
        className={cx(
          'flex items-center',
          style.base,
          rounded ? style.rounded : undefined,
          style[state]
        )}
      >
        <PhoneInput
          className={cx('placeholder-gray-500 focus:outline-none w-full py-2 px-4 bg-transparent')}
          disabled={disabled}
          readOnly={readOnly}
          onChange={onChange}
          ref={ref}
          defaultCountry="US"
          initialValueFormat="national"
          numberInputProps={{ className: 'focus:outline-none' }}
          {...props}
        />

        {(statusIcon ?? suffix) && (
          <div className={cx('shrink-0 pr-3 flex items-center', style.statusColor)}>
            {renderAppendedInputNode(statusIcon ?? suffix)}
          </div>
        )}
      </div>
    );
  }
);

FormPhoneInput.propTypes = {
  status: PropTypes.oneOf(['default', 'error', 'warning', 'success', 'info']),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  suffix: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({ iconName: PropTypes.string })]),
  onChange: PropTypes.func.isRequired,
  rounded: PropTypes.bool,
};

FormPhoneInput.defaultProps = {
  status: 'default',
  disabled: false,
  readOnly: false,
  suffix: null,
  rounded: false,
};

export default FormPhoneInput;
