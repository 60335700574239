import PropTypes from 'prop-types';
import { useRef } from 'react';
import capitalize from 'lodash/capitalize';
import { motion } from 'framer-motion';
import cx from 'classnames';

import { formatNumber } from 'lib/formatters';
import UserReactionTrigger, { reactionConfig } from './UserReactionTrigger';
import ReactIcon from '../../svg/icon-react.svg';
import ReactActiveIcon from '../../svg/icon-react-active.svg';

const UserReaction = ({
  value,
  onChange,
  display,
  likeCounter,
  loveCounter,
  clapCounter,
  cryCounter,
  yayCounter,
  hahaCounter,
  wowCounter,
}) => {
  const isMounted = useRef(null);

  const countToReactionMap = {
    like: likeCounter,
    love: loveCounter,
    clap: clapCounter,
    cry: cryCounter,
    yay: yayCounter,
    haha: hahaCounter,
    wow: wowCounter,
  };

  const handleReaction = (reaction) => onChange(reaction === value ? null : reaction);

  if (display === 'counts') {
    return (
      <div ref={isMounted} className="flex flex-wrap items-center gap-2">
        {Object.keys(reactionConfig).map((reaction) => {
          if (!countToReactionMap[reaction]) return null;

          const isActive = reaction === value;

          return (
            <motion.button
              key={`${reaction}-${isActive ? 'on' : 'off'}`}
              initial={isMounted && isActive ? { scale: 1.15 } : false}
              animate={{ scale: 1 }}
              transition={{ duration: 0.15 }}
              type="button"
              className={cx(
                'h-8 px-2 rounded-full border font-medium inline-flex items-center gap-x-1 transition-colors duration-200',
                {
                  'bg-gray-200 border-gray-200 hover:border-gray-500': !isActive,
                  'bg-yellow-100 border-yellow-400': isActive && reaction !== 'love',
                  'bg-red-100 border-red-400': isActive && reaction === 'love',
                }
              )}
              onClick={() => handleReaction(reaction)}
            >
              <span className="text-lg">{reactionConfig[reaction]}</span>
              <span>{formatNumber(countToReactionMap[reaction])}</span>
            </motion.button>
          );
        })}
        {!value && (
          <UserReactionTrigger key={value} onReact={handleReaction}>
            <div className="h-8 px-2 rounded-full font-medium transition-colors duration-200 bg-gray-200 hover:bg-gray-400 text-gray-700 inline-flex items-center">
              <ReactIcon className="w-5 fill-current text-gray-800 block group-hover:hidden" />
              <ReactActiveIcon className="w-5 fill-current hidden group-hover:block" />
            </div>
          </UserReactionTrigger>
        )}
      </div>
    );
  }

  return (
    <UserReactionTrigger key={value} onReact={handleReaction}>
      <motion.div
        ref={isMounted}
        key={value}
        initial={isMounted && value ? { scale: 1.15 } : false}
        animate={{ scale: 1 }}
        transition={{ duration: 0.15 }}
      >
        <div
          className={cx(
            'inline-flex gap-x-2 items-center transition-all duration-200 text-sm group text-gray-700',
            {
              'h-12 px-4 rounded-full border border-gray-300 text-base': display === 'button-lg',
              'hover:bg-gray-200 group-hover:bg-gray-100 group-hover:border-gray-400':
                display === 'button-lg' && !value,
              'border-2 bg-yellow-100 border-yellow-400':
                display === 'button-lg' && value && value !== 'love',
              'border-2 bg-red-100 border-red-400':
                display === 'button-lg' && value && value === 'love',
            }
          )}
        >
          <div className="w-6 h-6 flex justify-center items-center">
            {value ? (
              <span role="img" aria-label={value} className="block text-xl leading-none">
                {reactionConfig[value]}
              </span>
            ) : (
              <>
                <ReactIcon className="w-5 fill-current text-gray-700 block group-hover:hidden" />
                <ReactActiveIcon className="w-5 fill-current hidden group-hover:block" />
              </>
            )}
          </div>
          {display === 'button-lg' && (
            <span className="font-medium">{value ? capitalize(value) : 'React'}</span>
          )}
        </div>
      </motion.div>
    </UserReactionTrigger>
  );
};

UserReaction.propTypes = {
  value: PropTypes.oneOf(Object.keys(reactionConfig)),
  onChange: PropTypes.func.isRequired,
  display: PropTypes.oneOf(['counts', 'button-sm', 'button-lg']),
  likeCounter: PropTypes.number,
  loveCounter: PropTypes.number,
  clapCounter: PropTypes.number,
  cryCounter: PropTypes.number,
  yayCounter: PropTypes.number,
  hahaCounter: PropTypes.number,
  wowCounter: PropTypes.number,
};

UserReaction.defaultProps = {
  value: null,
  display: 'button-lg',
  likeCounter: null,
  loveCounter: null,
  clapCounter: null,
  cryCounter: null,
  yayCounter: null,
  hahaCounter: null,
  wowCounter: null,
};

export default UserReaction;
