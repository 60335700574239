import { useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { motion } from 'framer-motion';

import cx from 'lib/cx';
import useHorizontalScroller from 'hooks/useHorizontalScroller';

type InviteRecipientListProps = {
  recipients: {
    email: string;
    fullName?: string;
  }[];
  onRemove: (item: { email: string; fullName?: string }) => void;
  className?: string;
};

const InviteRecipientList = ({
  recipients,
  onRemove,
  className = '',
}: InviteRecipientListProps) => {
  const scroller = useHorizontalScroller();
  const currentCount = useRef(recipients.length);

  // Reset the scroll whenever a recipient is added (but not removed)
  useEffect(() => {
    if (recipients.length > currentCount.current) {
      scroller.resetScroll();
    }

    currentCount.current = recipients.length;
  }, [scroller, recipients.length]);

  return (
    <div className={cx('relative', className)}>
      <div
        className={cx(
          'hidden lg:flex items-center absolute inset-y-0 left-0 bg-gradient-to-l from-transparent to-white via-white transition-opacity duration-200 opacity-0 pointer-events-none w-18',
          {
            'opacity-100 pointer-events-auto': scroller.canScrollLeft,
          }
        )}
      >
        <button
          type="button"
          onClick={() => scroller.scrollLeft(scroller.clientWidth * 0.8)}
          className="h-8 w-8 rounded-full border border-gray-400 bg-white shadow-sm flex items-center justify-center transition-all duration-200 hover:border-gray-800 hover:shadow"
        >
          <FontAwesomeIcon icon={faChevronLeft} size="1x" />
        </button>
      </div>
      <div
        className={cx(
          'hidden lg:flex justify-end items-center absolute inset-y-0 right-0 bg-gradient-to-r from-transparent via-white to-white transition-opacity duration-200 opacity-0 pointer-events-none w-18',
          {
            'opacity-100 pointer-events-auto': scroller.canScrollRight,
          }
        )}
      >
        <button
          type="button"
          onClick={() => scroller.scrollRight(scroller.clientWidth * 0.8)}
          className="h-8 w-8 rounded-full border border-gray-400 bg-white shadow-sm flex items-center justify-center transition-all duration-200 hover:border-gray-800 hover:shadow"
        >
          <FontAwesomeIcon icon={faChevronRight} size="1x" />
        </button>
      </div>
      <div
        ref={scroller.ref}
        className="snap-x overflow-x-auto flex gap-1.5 item-center hide-scroll px-6 lg:px-0"
      >
        {recipients.map((recipient) => (
          <motion.button
            key={recipient.email}
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            type="button"
            onClick={() => onRemove(recipient)}
            className="rounded-full bg-gray-200 border border-gray-400 px-3 leading-none flex items-center gap-1.5 text-sm group hover:bg-gray-300 hover:border-gray-800 transition-colors duration-200 snap-normal snap-center shrink-0 h-8"
          >
            {recipient.fullName || recipient.email}
            <FontAwesomeIcon
              icon={faTimes}
              size="1x"
              className="text-gray-600 group-hover:text-gray-800 transition-colors duration-200"
            />
          </motion.button>
        ))}
      </div>
    </div>
  );
};

export default InviteRecipientList;
