import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessage } from '@hookform/error-message';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import pick from 'lodash/pick';
import * as yup from 'yup';

import {
  makeNullable,
  makeRequired,
  email as validEmail,
  phone as validPhone,
} from 'lib/validators';
import getAddressFieldsConfig from 'lib/getAddressFieldsConfig';
import useCustomQuestionFields from 'hooks/useCustomQuestionFields';
import Button from 'components/common/Button';
import FormNode from 'components/form/FormNode';
import FormNote from 'components/form/FormNote';
import FormLabel from 'components/form/FormLabel';
import FormFieldGroup from 'components/form/FormFieldGroup';
import FormPhoneInput from 'components/form/FormPhoneInput';
import FormControlledInputWithLabel from 'components/form/FormControlledInputWithLabel';
import DonationFormStep from './DonationFormStep';
import { updateData, useData } from './useDonationFormData';
import { getNextStep, setStep } from './useDonationFormStep';
import { useDonationFormWidget } from './useDonationFormWidget';

const DonationFormContact = ({ isPreview }) => {
  const { campaign, config } = useDonationFormWidget();

  const data = useData();

  const questions = useMemo(() => {
    if (!campaign) return [];
    return campaign.donationQuestions;
  }, [campaign]);

  const questionKeys = questions.map((x) => `question:${x.id}`);
  const responses = pick(data, questionKeys);

  const {
    firstName = '',
    lastName = '',
    email = '',
    phone = '',
    address1 = '',
    address2 = '',
    city = '',
    stateProv = '',
    postalCode = '',
    country = 'US',
  } = useData();

  const donorAddressConfig = useMemo(
    () => getAddressFieldsConfig({ validator: campaign?.collectDonorAddress ?? 'off' }),
    [campaign]
  );

  const {
    validationRules: questionValidationRules,
    fieldNodes: questionFields,
    defaultValues: questionDefaultValues,
  } = useCustomQuestionFields({
    questions,
    values: responses,
  });

  const validationSchema = yup.object({
    firstName: makeRequired(yup.string().trim()),
    lastName: makeRequired(yup.string().trim()),
    email: makeRequired(validEmail),
    phone: validPhone.when(['$collectDonorPhone'], ([collectDonorPhone], schema) => {
      if (collectDonorPhone === 'off') return schema.strip();
      if (collectDonorPhone === 'required') return makeRequired(schema);
      return makeNullable(schema);
    }),
    ...donorAddressConfig.validationRules,
    ...questionValidationRules,
  });

  const formMethods = useForm({
    defaultValues: {
      firstName,
      lastName,
      email,
      ...(campaign?.collectDonorPhone !== 'off' && { phone }),
      ...(campaign?.collectDonorAddress !== 'off' && {
        address1,
        address2,
        city,
        stateProv,
        postalCode,
        country,
      }),
      ...questionDefaultValues,
    },
    resolver: yupResolver(validationSchema),
  });

  const { handleSubmit, control, formState } = formMethods;
  const { errors } = formState;

  const onSubmit = (x) => {
    if (isPreview) return;
    updateData(x);
    setStep(getNextStep());
  };

  return (
    <FormProvider {...formMethods}>
      <DonationFormStep
        action={
          <Button
            as="button"
            type="button"
            color="primary"
            padding="sm"
            className="text-lg font-medium w-full"
            style={{ color: config.formButtonLabelColor }}
            onClick={handleSubmit(onSubmit)}
          >
            Continue to Payment
            <FontAwesomeIcon icon={faChevronRight} size="1x" className="ml-3" />
          </Button>
        }
      >
        <h1 className="text-xl font-medium mb-5">Tell Us About Yourself</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input type="submit" className="hidden" />
          <FormFieldGroup className="gap-y-5">
            <div className="grid grid-cols-2 gap-5 items-start">
              <FormControlledInputWithLabel
                control={control}
                name="firstName"
                label="First Name"
                placeholder="First Name"
                isRequired
              />
              <FormControlledInputWithLabel
                control={control}
                name="lastName"
                label="Last Name"
                placeholder="Last Name"
                isRequired
              />
            </div>
            <FormControlledInputWithLabel
              control={control}
              name="email"
              label="Email"
              placeholder="Email"
              isRequired
            />
            {campaign.collectDonorPhone !== 'off' && (
              <FormNode>
                <FormLabel htmlFor="phone" isRequired={campaign.collectDonorPhone === 'required'}>
                  Phone number
                </FormLabel>
                <Controller
                  control={control}
                  name="phone"
                  render={({ field, fieldState }) => (
                    <FormPhoneInput status={fieldState.error ? 'error' : 'default'} {...field} />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="phone"
                  render={({ message }) => <FormNote status="error">{message}</FormNote>}
                />
              </FormNode>
            )}

            {donorAddressConfig.renderFields()}

            {questionFields}
          </FormFieldGroup>
        </form>
      </DonationFormStep>
    </FormProvider>
  );
};

DonationFormContact.propTypes = {
  isPreview: PropTypes.bool,
};

DonationFormContact.defaultProps = {
  isPreview: false,
};

export default DonationFormContact;
