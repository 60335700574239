import { ComponentPropsWithoutRef } from 'react';
import { useAppBar } from './AppBar';
import NavbarTitle from './NavbarTitle';

type AppBarTitleProps = Omit<ComponentPropsWithoutRef<typeof NavbarTitle>, 'theme'>;

const AppBarTitle = (props: AppBarTitleProps) => {
  const { theme } = useAppBar();
  return <NavbarTitle theme={theme} title={props.title} {...props} />;
};

export default AppBarTitle;
