import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import cx from 'classnames';

type AppBarIconProps = {
  icon: IconProp;
  className?: string;
};

const AppBarIcon = ({ icon, className = '' }: AppBarIconProps) => (
  <span
    className={cx(
      'text-gray-700 transition-colors duration-200 hover:text-gray-900 hover:bg-gray-200 w-10 h-10 inline-flex justify-center items-center rounded-full',
      className
    )}
  >
    <FontAwesomeIcon icon={icon} size="lg" fixedWidth />
  </span>
);

export default AppBarIcon;
