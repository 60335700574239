import { useCallback } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

const GET_GIVING_VOUCHER = gql`
  query GetGivingVoucher($id: String!, $validationToken: String) {
    findCampaigns(id: $id) {
      id
      givingVoucher(type: Donation, validationToken: $validationToken)
    }
  }
`;

const useGivingVoucher = (campaignId) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const client = useApolloClient();

  return useCallback(async () => {
    const captchaToken = await executeRecaptcha('givingVoucher');

    const { data } = await client.query({
      query: GET_GIVING_VOUCHER,
      variables: { id: campaignId, validationToken: captchaToken },
    });

    return data?.findCampaigns[0]?.givingVoucher;
  }, [executeRecaptcha, client, campaignId]);
};

export default useGivingVoucher;
