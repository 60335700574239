import { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faInfoCircle,
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import style from './FormInput.module.css';

export const renderAppendedInputNode = (node) => {
  if (!node) return null;
  if (!node.iconName) return node;
  return <FontAwesomeIcon icon={node} className="text-lg" fixedWidth />;
};

const FormInput = forwardRef(
  ({ onChange, value, status, disabled, readOnly, prefix, suffix, rounded, ...props }, ref) => {
    const state = useMemo(() => {
      if (disabled) return 'disabled';
      if (readOnly) return 'readonly';
      return status;
    }, [disabled, readOnly, status]);

    const statusIcon = useMemo(() => {
      if (state === 'error') return faExclamationCircle;
      if (state === 'warning') return faExclamationTriangle;
      if (state === 'success') return faCheckCircle;
      if (state === 'info') return faInfoCircle;
      return null;
    }, [state]);

    return (
      <div
        className={cx(
          'flex items-center',
          style.base,
          rounded ? style.rounded : undefined,
          style[state]
        )}
      >
        {prefix && (
          <div className={cx('shrink-0 pl-3 flex items-center', style.statusColor)}>
            {renderAppendedInputNode(prefix)}
          </div>
        )}
        <input
          value={value}
          className={cx('placeholder-gray-500 focus:outline-none w-full py-2 px-4 bg-transparent')}
          disabled={disabled}
          readOnly={readOnly}
          onChange={(e) => onChange(e.target.value)}
          ref={ref}
          {...props}
        />
        {(statusIcon ?? suffix) && (
          <div className={cx('shrink-0 pr-3 flex items-center', style.statusColor)}>
            {renderAppendedInputNode(statusIcon ?? suffix)}
          </div>
        )}
      </div>
    );
  }
);

FormInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  status: PropTypes.oneOf(['default', 'error', 'warning', 'success', 'info']),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  prefix: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({ iconName: PropTypes.string })]),
  suffix: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({ iconName: PropTypes.string })]),
  rounded: PropTypes.bool,
};

FormInput.defaultProps = {
  status: 'default',
  value: '',
  disabled: false,
  readOnly: false,
  prefix: null,
  suffix: null,
  rounded: false,
};

export default FormInput;
