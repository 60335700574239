import { useCallback, useMemo } from 'react';

import { useGlobal } from 'context/Global';

const useToasts = (toastKey) => {
  const { addToast } = useGlobal();

  const success = useCallback(
    (message) =>
      addToast({
        id: toastKey,
        type: 'success',
        duration: 'short',
        message,
      }),
    [toastKey, addToast]
  );

  const error = useCallback(
    (message) =>
      addToast({
        id: toastKey,
        type: 'error',
        duration: 'long',
        message,
      }),
    [toastKey, addToast]
  );

  return useMemo(
    () => ({
      success,
      error,
    }),
    [success, error]
  );
};

export default useToasts;
