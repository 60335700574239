import { useMemo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import CurrencyInput from 'react-currency-input-field';
import {
  faExclamationTriangle,
  faInfoCircle,
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import { renderAppendedInputNode } from './FormInput';
import style from './FormInput.module.css';

const FormNumberInput = forwardRef(
  (
    {
      status,
      disabled,
      readOnly,
      prefix,
      suffix,
      allowDecimals,
      decimalsLimit,
      onChange,
      ...props
    },
    ref
  ) => {
    const state = useMemo(() => {
      if (disabled) return 'disabled';
      if (readOnly) return 'readonly';
      return status;
    }, [disabled, readOnly, status]);

    const statusIcon = useMemo(() => {
      if (state === 'error') return faExclamationCircle;
      if (state === 'warning') return faExclamationTriangle;
      if (state === 'success') return faCheckCircle;
      if (state === 'info') return faInfoCircle;
      return null;
    }, [state]);

    return (
      <div className={cx('flex items-center', style.base, style[state])}>
        {prefix && (
          <div className={cx('shrink-0 pl-3', style.statusColor)}>
            {renderAppendedInputNode(prefix)}
          </div>
        )}
        <CurrencyInput
          className={cx(
            'placeholder-gray-500 focus:outline-none w-full py-2 px-4 mr-2 bg-transparent'
          )}
          disabled={disabled}
          readOnly={readOnly}
          allowDecimals={allowDecimals}
          decimalsLimit={decimalsLimit}
          onValueChange={(newValue) => onChange(newValue ?? null)}
          ref={ref}
          {...props}
        />
        {(statusIcon ?? suffix) && (
          <div className={cx('shrink-0 pr-3', style.statusColor)}>
            {renderAppendedInputNode(statusIcon ?? suffix)}
          </div>
        )}
      </div>
    );
  }
);

FormNumberInput.propTypes = {
  status: PropTypes.oneOf(['default', 'error', 'warning', 'success', 'info']),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  prefix: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({ iconName: PropTypes.string })]),
  suffix: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({ iconName: PropTypes.string })]),
  allowDecimals: PropTypes.bool,
  decimalsLimit: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

FormNumberInput.defaultProps = {
  status: 'default',
  disabled: false,
  readOnly: false,
  prefix: null,
  suffix: null,
  allowDecimals: false,
  decimalsLimit: 1,
};

export default FormNumberInput;
